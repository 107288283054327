import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import masks from '../src/masks'


export default class ClaimAttorneysController extends Controller {
    connect() {
        //need to bind for proper event subscribing
        this.handleConfirmComplete = this.handleConfirmComplete.bind(this);

        this.handleRealEstateRequest = this.handleRealEstateRequest.bind(this);
        document.addEventListener('requestRealEstate', this.handleRealEstateRequest);

        this.handleRealEstateRelease = this.handleRealEstateRelease.bind(this);
        document.addEventListener('releaseRealEstate', this.handleRealEstateRelease);
    }

    disconnect() {
        document.removeEventListener('requestRealEstate', this.handleRealEstateRequest);
        document.removeEventListener('releaseRealEstate', this.handleRealEstateRelease);
    }

    handleRealEstateRequest(event) {
        // this method should only handle realestate requests from others not self
        if(event.detail.target !== this.element) {
            const container = this.element.closest(event.detail.selector)
            // only handle this requests if belong to the requested element
            if(container) {
                this.element.classList.add('d-none')
            }
        }

    }

    handleRealEstateRelease(event) {
        if(event.detail.target !== this.element) {
            const container = this.element.closest(event.detail.selector)
            // only handle this requests if belong to the requested element
            if(container) {
                this.element.classList.remove('d-none')
            }
        }
    }

    prepareDelete(event) {
        // Ensure we clean up any previously attached listener to avoid duplicates
        document.removeEventListener('confirm:complete', this.handleConfirmComplete);

        // Attach a listener to the document for the `confirm:complete` Rails UJS event
        document.addEventListener('confirm:complete', this.handleConfirmComplete);
    }

    handleConfirmComplete(event) {
        //TODO: consider using
        // confirmationDialog(`Delete Attorney?`, self, this.delete)

        // Event detail contains whether the confirmation was accepted
        const [confirmed] = event.detail;
        // Clean up by removing the event listener
        document.removeEventListener('confirm:complete', this.handleConfirmComplete);

        if (confirmed) {
            this.delete(event);
        }
    }

    delete(event) {
        const url = event.target.dataset.deleteUrl

        Rails.ajax({
            url: url,
            type: 'DELETE',
            success: (data) => {
                const attorney = event.target.closest('.attorney-container')
                attorney.remove()
                //TODO: here we should dispatch the event so the message will be shown
            },
            error: (data) => {
                console.log(data)
            }
        })

    }

    update(event) {
        const url = event.target.dataset.updateUrl

        const value = event.target.value

        Rails.ajax({
            url: url,
            type: 'PATCH',
            data: `is_reporting=${value}`,
            success: (data) => {
                console.log(data)
            },
            error: (data) => {
                console.log(data)
            }
        });
    }
}
import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import masks from '../src/masks'


export default class AiCardController extends Controller {

    connect() {
        this.crosslinkSelectionChangedEventHandler = this.crosslinkSelectionChangedEventHandler.bind(this);
        this.crosslinkChangedEventHandler = this.crosslinkChangedEventHandler.bind(this);
        this.activeSuggestionCardSelectedEventHandler = this.activeSuggestionCardSelectedEventHandler.bind(this);
        this.activeSuggestionCardEditingEventHandler = this.activeSuggestionCardEditingEventHandler.bind(this);
        this.activeSuggestionCardUnlockEventHandler = this.activeSuggestionCardUnlockEventHandler.bind(this);

        this.initializeAsync()
    }

    async initializeAsync(){
        new masks(this.element)
        this.aiPanel = document.querySelector("#data-ai-attribute-suggestions-panel")
        // !!!!!!!!!!!!!!!!!!!!
        // we need to bind this listener with an instance of a controller so we can disconnect it later
        // and garbage collector will be able to cleanup if the card element gets removed from html
        // !!!!!!!!!!!!!!!!!!!!
        if(this.aiPanel){
            this.subscribe_to_ai_panel_events()

            // its unknown if this card needs to be disabled, this is especially true for ajax refreshed cards
            // so we need to check if active card is in edit mode
            const activeCard = this.aiPanel.querySelector('.suggestion-card-container.active-suggestion-card')
            if (activeCard && activeCard.id != this.element.id) {
                const editFooter = activeCard.querySelector(' .suggestion-card-footer-edit.d-block')
                if (editFooter) {
                    // looks like active card is in the edit mode, so we need to disable self
                    this.element.classList.add("disabled")
                }
            }

            this.element.classList.add('initialized');

            // Check if there's a pending event for this element
            // this is need for ai-controller to set first card to be active in case the regular events are yet initialized
            const pendingEventName = this.element.getAttribute('data-pending-event');
            if (pendingEventName) {
                if(pendingEventName === 'activeSuggestionCardSelected'){
                    let focusOnActivate = this.element.getAttribute('data-focus-on-activate') === 'true'
                    this._activateSuggestionCard(focusOnActivate)
                }
                // Clear the pending event attribute
                this.element.removeAttribute('data-pending-event');
                this.element.removeAttribute('data-focus-on-activate');
            }

            const card_event = new CustomEvent("suggestionCardInitialized", {
                detail: {card: this.element}
            })
            this.aiPanel.dispatchEvent(card_event)
        }

    }
    //!!!!! we need to cleanup event listeners when we remove this card from html, otherwise we will have memory leaks !!!!!
    disconnect() {
        this.cleanupAsync()
    }

    async cleanupAsync(){
        this.unsubscribe_from_ai_panel_events()
        this.unsubscribe_from_document_events()
    }

    subscribe_to_ai_panel_events() {
        //these are internal to ai panel events
        this.aiPanel.addEventListener('activeSuggestionCardSelected', this.activeSuggestionCardSelectedEventHandler);
        this.aiPanel.addEventListener('activeSuggestionCardEditing', this.activeSuggestionCardEditingEventHandler);
        this.aiPanel.addEventListener('activeSuggestionCardUnlock', this.activeSuggestionCardUnlockEventHandler);
    }

    subscribe_to_document_events() {
        //these events go across the document, perhaps main form listens to this event, perhaps not
        document.removeEventListener('crosslinkChanged', this.crosslinkChangedEventHandler);
        document.addEventListener('crosslinkChanged', this.crosslinkChangedEventHandler);

        // here I want to check if I have already subscribed to this event?
        document.removeEventListener('crosslinkSelectionChanged', this.crosslinkSelectionChangedEventHandler);
        document.addEventListener('crosslinkSelectionChanged', this.crosslinkSelectionChangedEventHandler);
    }

    unsubscribe_from_ai_panel_events() {
        // these are internal to ai panel events
        if(this.aiPanel) {
            this.aiPanel.removeEventListener('activeSuggestionCardSelected', this.activeSuggestionCardSelectedEventHandler);
            this.aiPanel.removeEventListener('activeSuggestionCardEditing', this.activeSuggestionCardEditingEventHandler);
            this.aiPanel.removeEventListener('activeSuggestionCardUnlock', this.activeSuggestionCardUnlockEventHandler);
        }
    }
    unsubscribe_from_document_events() {
        // this is document wide event
        if(document) {
          document.removeEventListener('crosslinkChanged', this.crosslinkChangedEventHandler);
          document.removeEventListener('crosslinkSelectionChanged', this.crosslinkSelectionChangedEventHandler);
        }
    }

    activeSuggestionCardSelectedEventHandler(event) {
        if (this.element == event.detail.card){
            this._activateSuggestionCard(event.detail.focus)
        }  else {
            this._deactivateSuggestionCard()
        }
    }

    activeSuggestionCardEditingEventHandler(event) {
        if (this.element.id != event.detail.card.id){
            // here we need to disable self
            // perhaps we should go into a show mode if was in edit mode somehow
            this.element.classList.add("disabled")
        }
    }

    activeSuggestionCardUnlockEventHandler(event) {
        if (event.detail.card && this.element == event.detail.card){
            this._activateSuggestionCard(true)
        }  else {
            // the event card is not me, so instead of activating myself I will either unlock myself or refresh
            if( this.element.dataset.crosslinked === "true"){
                //here we need to refresh the card

                const spinner = this.element.querySelector(".suggestion-card-loading-spinner")
                if (spinner) {
                    spinner.classList.remove("d-none")
                }

                const url = this.element.dataset.refreshUrl

                Rails.ajax({
                    type: "get",
                    url: url,
                    contentType: "'application/x-www-form-urlencoded'",
                    success: (data) => {

                        //card comes back inactive
                        //now we need to see if we need to enable or disable the card
                        //card comes back inactive, it will be a new html element which we need to find and activate
                        this.element.outerHTML = data.html_data


                    },
                    error: (error) => {
                        console.log(error)
                        if (spinner) {
                            spinner.classList.add("d-none")
                        }
                        this.element.innerHTML = "Refresh suggestion failed. Please try again later."
                    }
                })


            }else{
                //just unlock
                this.element.classList.remove("disabled")
            }
        }
    }

    crosslinkChangedEventHandler(event) {
        //only do it for current form
        const crosslinkForm = this.element.querySelector(".suggestion-card-edit-panel .suggestion-card-form")
        if(event.detail.crosslinkForm === crosslinkForm){
            let input = event.detail.input
            input.value = JSON.stringify(event.detail.crosslink)
        }
    }

    crosslinkSelectionChangedEventHandler(event) {
        const crosslinkEditForm = this.element.querySelector(".suggestion-card-edit-panel .suggestion-card-form")
        //we want to see a selected input, otherwise it means that we have unselecte, so no need to reenable the save button
        if(event.detail.crosslinkForm === crosslinkEditForm ){
            //here we should enable save buttons
            if (event.detail.input){
                const save_button = this.element.querySelector(".suggestion-card-footer-edit .save-crosslink-btn")
                if (save_button) {
                    save_button.disabled = false
                    if(event.detail.focus ){
                      save_button.focus();
                    }

                }
            }else{
                //we have unselected the crosslink
                //for now do nothing but perhaps we should do something here, in case perhaps we close document ???
            }

        }

        //here we should find show panel
        const crosslinkShowForm = this.element.querySelector(".suggestion-card-display-panel .suggestion-card-form")
        if(event.detail.crosslinkForm === crosslinkShowForm) {
            //here we should enable save buttons
            if (event.detail.input) {
                //here we should enable buttons
                const add_crosslink_btn = this.element.querySelector(".suggestion-card-footer-show .add-crosslink-btn")

                // when user operates from cards list he needs to stay in cards list especialy when user navigates
                // through cards with keyboard
                // so its important to keep focus within the card list "window"
                // even when we send events to other "window" components and those attempt to focus on their own elements
                // somehow we need to assign each "window" component to retain focus

                if (add_crosslink_btn) {
                    add_crosslink_btn.disabled = false
                    if(event.detail.focus && this.focusOnActivate){
                        add_crosslink_btn.focus();
                    }
                }

                const edit_crosslink_btn = this.element.querySelector(".suggestion-card-footer-show .edit-crosslink-btn")
                if (edit_crosslink_btn) {
                    edit_crosslink_btn.disabled = false
                    if(event.detail.focus && this.focusOnActivate) {
                        edit_crosslink_btn.focus()
                    }
                }

                const delete_crosslink_btn = this.element.querySelector(".suggestion-card-footer-show .delete-crosslink-btn")
                if (delete_crosslink_btn) {
                    delete_crosslink_btn.disabled = false
                }
            }else{
                //we have unselected the crosslink
                //for now do nothing but perhaps we should do something here, in case perhaps we close document ???
            }
        }
    }

    activateSuggestionCard = function(event) {
        //event.preventDefault()

        //do not do anything with disabled cards
        //its already active, why bother
        if(!(this.element.classList.contains("disabled") || this.element.classList.contains("active-suggestion-card"))) {
            const selected_card_event = new CustomEvent("activeSuggestionCardSelected", {
                detail: {card: this.element, focus: true}
            })
            this.aiPanel.dispatchEvent(selected_card_event)
        }
    }

    _activateSuggestionCard = function(focusOnActivate) {
        this.focusOnActivate = focusOnActivate
        if(this.element.classList.contains("disabled")) {
            return
        }

        this.element.classList.add("active-suggestion-card")
        this.element.classList.add("border-success")

        const tab_decoy_btn = this.element.querySelector(".tab-decoy")
        if (tab_decoy_btn) {
            tab_decoy_btn.classList.add("d-none")
        }

        const showFooter = this.element.querySelector(".suggestion-card-footer-show")
        if (showFooter) {
            showFooter.classList.remove("d-none")
            showFooter.classList.add("d-block")

            const confirm_button = showFooter.querySelector(".add-crosslink-btn")
            if (confirm_button) {
                confirm_button.disabled = true
            }

            const edit_crosslink_btn = showFooter.querySelector(".edit-crosslink-btn")
            if (edit_crosslink_btn) {
                edit_crosslink_btn.disabled = true
            }

            const delete_crosslink_btn = showFooter.querySelector(".delete-crosslink-btn")
            if (delete_crosslink_btn) {
                delete_crosslink_btn.disabled = true
            }
        }

        const editFooter = this.element.querySelector(".suggestion-card-footer-edit")
        if (editFooter) {
            editFooter.classList.add("d-none")
            editFooter.classList.remove("d-block")

            const save_button = editFooter.querySelector(".save-crosslink-btn")
            if (save_button) {
                save_button.disabled = true
            }
        }

        const cardFooter = this.element.querySelector(".card-footer")
        if(cardFooter) {
            cardFooter.classList.remove("d-none")
            cardFooter.classList.add("d-block")
        }

        // this logic to send an event to the document panel is consistent with crosslink-form controller

        const displayPanel = this.element.querySelector(".suggestion-card-display-panel")
        const crosslink_id = this.element.dataset.crosslinkId

        const crosslinkContainer = displayPanel.querySelector(".crosslink-container")
        const crosslink_input = crosslinkContainer.querySelector(`.crosslink[data-crosslink-id='${crosslink_id}']`)
        const crosslinkForm = displayPanel.querySelector(".suggestion-card-form")

        this.subscribe_to_document_events()

        const showCrosslinkEvent = new CustomEvent("showCrosslink",{
            detail: {
                crosslinkForm: crosslinkForm,
                crosslink_id: crosslink_id,
                input: crosslink_input
            }
        })
        document.dispatchEvent(showCrosslinkEvent)
    }

    _deactivateSuggestionCard = function() {
        // do nothing with disabled or inactive card
        if(this.element.classList.contains("disabled")) {
            return
        }

        this.unsubscribe_from_document_events()

        let editPanel = this.element.querySelector(".suggestion-card-edit-panel.d-block")
        let editWpdPanel = this.element.querySelector(".suggestion-card-cdf-edit-panel.d-block")
        let editFooter = this.element.querySelector(".suggestion-card-footer-edit.d-block")

        // deactivate only when in show mode
        // use cancel button if you want to get out from the edit mode
        if(editPanel || editFooter || editWpdPanel) {
            return
        }

        this.element.classList.remove("border-success")
        this.element.classList.remove("active-suggestion-card")

        const tab_decoy_btn = this.element.querySelector(".tab-decoy")
        if (tab_decoy_btn) {
            tab_decoy_btn.classList.remove("d-none")
        }

        const showFooter = this.element.querySelector(".suggestion-card-footer-show")
        if (showFooter) {
            showFooter.classList.add("d-none")
            showFooter.classList.remove("d-block")

            const confirmButton = showFooter.querySelector(".add-crosslink-btn")
            if (confirmButton) {
                confirmButton.disabled = true
            }

            const editCrosslinkBtn = showFooter.querySelector(".edit-crosslink-btn")
            if (editCrosslinkBtn) {
                editCrosslinkBtn.disabled = true
            }

            const deleteCrosslinkBtn = showFooter.querySelector(".delete-crosslink-btn")
            if (deleteCrosslinkBtn) {
                deleteCrosslinkBtn.disabled = true
            }

        }

        editFooter = this.element.querySelector(".suggestion-card-footer-edit")
        if (editFooter) {
            editFooter.classList.add("d-none")
            editFooter.classList.remove("d-block")

            const saveButton = editFooter.querySelector(".save-crosslink-btn")
            if (saveButton) {
                saveButton.disabled = true
            }
        }

        const cardFooter = this.element.querySelector(".card-footer")
        if (cardFooter) {
            cardFooter.classList.add("d-none")
            cardFooter.classList.remove("d-block")
        }

        //this is not strictly necessary, because we be already in the show mode so this will be already visible
        const displayPanel = this.element.querySelector(".suggestion-card-display-panel.d-none")
        if (displayPanel) {
            displayPanel.classList.remove("d-none")
            displayPanel.classList.add("d-block")
        }

    }

    showEditPanel = function(event) {
        // not really sure why we need this here ...
        //event.preventDefault()

        const showFooter = this.element.querySelector(".suggestion-card-footer-show")

        if (showFooter) {
            showFooter.classList.add("d-none")
            showFooter.classList.remove("d-block")
        }

        const editFooter = this.element.querySelector(".suggestion-card-footer-edit")
        if (editFooter) {
            editFooter.classList.remove("d-none")
            editFooter.classList.add("d-block")
        }

        const editPanel = this.element.querySelector(".suggestion-card-edit-panel")
        if (editPanel) {
            editPanel.classList.remove("d-none")
            editPanel.classList.add("d-block")
        }

        const editWpdPanel = this.element.querySelector(".suggestion-card-cdf-edit-panel")
        if (editWpdPanel) {
            editWpdPanel.classList.remove("d-none")
            editWpdPanel.classList.add("d-block")
        }

        const cardEvent = new CustomEvent("activeSuggestionCardEditing", {
            detail: {card: this.element}
        })

        this.aiPanel.dispatchEvent(cardEvent)

        const crosslink_id = this.element.dataset.crosslinkId

        const crosslinkContainer = editPanel.querySelector(".crosslink-container")
        const crosslink_input = crosslinkContainer.querySelector(`.crosslink[data-crosslink-id='${crosslink_id}']`)
        const crosslinkForm = editPanel.querySelector(".suggestion-card-form")

        const crosslinkEvent = new CustomEvent("showCrosslink",{
            detail: {
                crosslinkForm: crosslinkForm,
                crosslink_id: crosslink_id,
                input: crosslink_input
            }
        })
        document.dispatchEvent(crosslinkEvent)

    }

    cancelEditPanel = function(event) {
        //not really sure why we need this here ...
        //event.preventDefault()

        const showFooter = this.element.querySelector(".suggestion-card-footer-show")
        //card-footer
        if (showFooter) {
            showFooter.classList.remove("d-none")
            showFooter.classList.add("d-block")
        }

        const editFooter = this.element.querySelector(".suggestion-card-footer-edit")
        if (editFooter) {
            editFooter.classList.add("d-none")
            editFooter.classList.remove("d-block")

            const save_button = editFooter.querySelector(".save-crosslink-btn")
            if (save_button) {
                save_button.disabled = true
            }
        }

        const editPanel = this.element.querySelector(".suggestion-card-edit-panel")
        if (editPanel) {
            editPanel.classList.remove("d-block")
            editPanel.classList.add("d-none")
        }

        const editWpdPanel = this.element.querySelector(".suggestion-card-cdf-edit-panel")
        if (editWpdPanel) {
            editWpdPanel.classList.remove("d-block")
            editWpdPanel.classList.add("d-none")
        }

        const cardEvent = new CustomEvent("activeSuggestionCardUnlock", {
            detail: {card: this.element}
        })

        this.aiPanel.dispatchEvent(cardEvent)
    }

    saveCrosslink = function(event) {

        const spinner = this.element.querySelector(".suggestion-card-loading-spinner")
        if (spinner) {
            spinner.classList.remove("d-none")
        }

        //TODO: here we should harvest work product data and save it into a crosslink_input hidden element json value

        const crosslink_id = this.element.dataset.crosslinkId
        const editPanel = this.element.querySelector(".suggestion-card-edit-panel")
        const crosslinkContainer = editPanel.querySelector(".crosslink-container")
        const crosslink_input = crosslinkContainer.querySelector(`.crosslink[data-crosslink-id='${crosslink_id}']`)

        const editWpdPanel = this.element.querySelector(".suggestion-card-cdf-edit-panel ")
        let includeInWorkProduct, startPage, endPage;

        if (editWpdPanel) {
            includeInWorkProduct = editWpdPanel.querySelector(".include-in-work-product-document")
            startPage = editWpdPanel.querySelector(".start-page")
            endPage = editWpdPanel.querySelector(".end-page")
        }

        let crosslinkData = JSON.parse(crosslink_input.value) || {}

        if(!crosslinkData.data){
            crosslinkData.data = {}
        }

        // here I need to initialize the work product information field if not already initialized
        if(!crosslinkData.data.work_product_information){
            crosslinkData.data.work_product_information = {}
            crosslinkData.data.work_product_information.start_page = null
            crosslinkData.data.work_product_information.end_page = null
            crosslinkData.data.work_product_information.include_in_work_product_document = 'No'
        }

        if (editWpdPanel){
            crosslinkData.data.work_product_information.include_in_work_product_document = includeInWorkProduct.checked ? 'Yes' : 'No'
            if (includeInWorkProduct.checked ){
                crosslinkData.data.work_product_information.start_page = startPage.value
                crosslinkData.data.work_product_information.end_page = endPage.value
            } else {
                crosslinkData.data.work_product_information.start_page = null
                crosslinkData.data.work_product_information.end_page = null
            }
        }
        crosslink_input.value = JSON.stringify(crosslinkData)

        let formElement =  editPanel.querySelector(".suggestion-card-form")
        let suggestionForm = $(formElement)
        //suggestion data will be already there along with bounding box inside the hidden input
        let suggestionData = suggestionForm.serialize()

        const url = this.element.dataset.saveUrl

        Rails.ajax({
            type: "post",
            url: url,
            data: suggestionData,
            contentType: "'application/x-www-form-urlencoded'",
            success: (data) => {

                const oldId = this.element.id
                //card comes back inactive, it will be a new html element which we need to find and activate
                this.element.outerHTML = data.html_data

                //perhaps we should search within narrower scope
                let newCard = document.getElementById(oldId);

                //instance of this controller is hanging on this thread and we need to release all other disabled cards here
                const cardEvent = new CustomEvent("activeSuggestionCardUnlock", {
                    detail: {card: newCard, saved: true}
                })
                this.aiPanel.dispatchEvent(cardEvent)

                if (newCard) {
                    // stimulus may not be initialized yet, so we need to check if it is initialized
                    if (newCard.getAttribute('initialized') === 'true') {

                        // If initialized, dispatch the event immediately
                        this.aiPanel.dispatchEvent(new CustomEvent("activeSuggestionCardSelected", {
                            detail: { card: newCard, focus: true }
                        }));
                    } else {
                        // If not initialized, set an attribute to indicate a pending event
                        newCard.setAttribute('data-pending-event', 'activeSuggestionCardSelected');
                        newCard.setAttribute('data-focus-on-activate', 'true');
                    }
                }else{
                    const crosslinkFormActiveEvent = new CustomEvent("crosslinkFormActive", {
                        detail:{crosslinkForm: null}
                    })
                    document.dispatchEvent(crosslinkFormActiveEvent)
                }

            },
            error: (error) => {
                console.log(error)
                if (spinner) {
                    spinner.classList.add("d-none")
                }
                this.element.innerHTML = "Save suggestion failed. Please try again later."
            }
        })
    }

    deleteCrosslink = function(event) {
        //TODO: when highlight will be deleted on the document panel we should be able to hook up to that event and
        // do something here
        // may we should not allow to delete a highlight for a suggestion card on document panel????
        const spinner = this.element.querySelector(".suggestion-card-loading-spinner")

        if (spinner) {
            spinner.classList.remove("d-none")
        }

        const url = this.element.dataset.deleteUrl

        Rails.ajax({
            type: "delete",
            url: url,
            success: (data) => {
                const oldId = this.element.id
                //card comes back inactive, it will be a new html element which we need to find and activate
                this.element.outerHTML = data.html_data

                let newCard = document.getElementById(oldId);

                if (newCard) {
                    // stimulus may not be initialized yet, so we need to check if it is initialized
                    if (newCard.getAttribute('initialized') === 'true') {
                        // If initialized, dispatch the event immediately
                        this.aiPanel.dispatchEvent(new CustomEvent("activeSuggestionCardSelected", {
                            detail: { card: newCard, focus: true }
                        }));
                    } else {
                        // If not initialized, set an attribute to indicate a pending event
                        newCard.setAttribute('data-pending-event', 'activeSuggestionCardSelected');
                        newCard.setAttribute('data-focus-on-activate', 'true');
                    }
                }else{
                    const crosslinkFormActiveEvent = new CustomEvent("crosslinkFormActive", {
                        detail:{crosslinkForm: null}
                    })
                    document.dispatchEvent(crosslinkFormActiveEvent)
                }

            },
            error: (error) => {
                console.log(error)
                if (spinner) {
                    spinner.classList.add("d-none")
                }
                this.element.innerHTML = "Operation failed. Please try again later."
            }
        })

    }

    //TODO: this WPD methods deserve its own controller perhaps to aid with the crosslink json data collection at the card and at the classic crosslink
    changedCrosslinkDataFields = (event) => {
        this._asyncCrosslinkDataFieldsChanged(event)
    }

    _asyncCrosslinkDataFieldsChanged = async (event) => {

        const crosslinkDataFieldsPanel = this.element.querySelector(".suggestion-card-cdf-edit-panel ")
        const includeInWorkProduct = crosslinkDataFieldsPanel.querySelector(".include-in-work-product-document")
        const startPage = crosslinkDataFieldsPanel.querySelector(".start-page")
        const endPage = crosslinkDataFieldsPanel.querySelector(".end-page")


        const crosslinkDataFields = crosslinkDataFieldsPanel.querySelectorAll('.crosslink-data-field')

        if(includeInWorkProduct.checked) {

            // here I want to check if value of startPage is blank
            if(startPage.value === "" || isNaN(startPage.value) || endPage.value === "" || isNaN(endPage.value)){
                const crosslink_id = this.element.dataset.crosslinkId
                const editPanel = this.element.querySelector(".suggestion-card-edit-panel")
                const crosslinkContainer = editPanel.querySelector(".crosslink-container")
                const crosslink_input = crosslinkContainer.querySelector(`.crosslink[data-crosslink-id='${crosslink_id}']`)

                let crosslinkData = JSON.parse(crosslink_input.value)
                if(crosslinkData.document_highlight){
                    if(startPage.value === "" || isNaN(startPage.value)){
                        startPage.value = crosslinkData.document_highlight.page
                    }

                    if(endPage.value === "" || isNaN(endPage.value)){
                        endPage.value = crosslinkData.document_highlight.page
                    }
                }


            }




            crosslinkDataFields.forEach((field) =>{
                field.classList.remove("d-none")
            })
        }
        else{
            crosslinkDataFields.forEach((field) =>{
                field.classList.add("d-none")
            })
        }

    }

}
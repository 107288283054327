import { Controller } from "@hotwired/stimulus"

import * as XLSX from 'xlsx';

export default class extends Controller {

  export = (event) => {
    event.preventDefault()
    const tableSelector = this.data.get('table-selector')
    const tables = document.querySelectorAll(tableSelector)
    const filename = this.data.get('filename')
    var workbook = null;
    tables.forEach((table)=>{
      const sheetName = table.dataset.excelName
      if (!workbook) {
        workbook = XLSX.utils.table_to_book(table, {sheet: sheetName})
      }
      else {
        const worksheet = XLSX.utils.table_to_sheet(table)
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
      }
    })
    XLSX.writeFile(workbook, filename + '.xlsx')
    // var opts = { bookType: 'xlsx', bookSST: true, type: 'buffer' }
    // var wb_out = XLSX.write(workbook, opts);
    // saveAs(new Blob([wb_out], {type:"application/vnd.ms-excel"}), filename + '.xlsx');
  }

}

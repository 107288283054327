import emitFormChangedEvent from './emitFormChangedEvent'
import showUnsavedChangesMessage from './showUnsavedChangesMessage'

export default class disableOnChange {
  constructor(selector = '[data-disable-on-change]') {
    this.selector = selector
  }
  init = () => {
    const targets = document.querySelectorAll(this.selector)
    this.setupTargets(targets)
  }
  setupTargets = (targets) => {
    if (targets.length === 0) return
    let formTargets = {}
    targets.forEach((target)=>{
      const form_id = target.dataset.disableOnChange
      if (!formTargets[form_id]) {
        formTargets[form_id] = [target]
      }
      else {
        formTargets[form_id].push(target)
      }
    })
    Object.keys(formTargets).forEach((form_id) => {
      this.disableTargetsOnChange(form_id, formTargets[form_id])
    })
  }
  disableTargetsOnChange = (form_id, targets) => {
    const form = document.getElementById(form_id)
    const inputs = Array.from(document.querySelectorAll('input,select,textarea')).filter(input => (input.form && input.form.id == form_id && input.className != 'uppy-Dashboard-input'))
    form.addEventListener('formChanged', (event) => {
      event.preventDefault()
      if (form_id != 'claim_form') {
        targets.forEach((target) => {
          target.disabled = 'true'
          target.classList.add('disabled')
        })
      }
      showUnsavedChangesMessage()
    })
    inputs.forEach((input)=>{
      input.addEventListener("change", this.fireChangeEvent)
    })
  }
  fireChangeEvent = (event) => {
    let form = event.target.form
    emitFormChangedEvent(form, event)
  }
}


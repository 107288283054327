import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['widget', 'action'];

  initialize() {
    this.switchButtonFields();
  }

  switchButtonFields() {
    const target = this.actionTarget;

    if (!target || this.widgetTarget.value != 'button') {
      return;
    }

    const buttonFields = this.element.querySelectorAll('[data-widget-flags]');
    buttonFields.forEach((enclosure) => {
      const field = $(enclosure.querySelector('.form-control:not(div)'));
      if (JSON.parse(enclosure.dataset.widgetFlags).includes(target.value)) {
        enclosure.classList.remove('d-none');
        field.prop('disabled', false);
      }
      else {
        enclosure.classList.add('d-none');
        field.prop('disabled', true);
      }
      
      if (field.hasClass('selectpicker')) {
        field.selectpicker('refresh');
      }
    })
  }
}
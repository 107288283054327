import Core from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Dashboard from '@uppy/dashboard'
import showUnsavedChangesMessage from './showUnsavedChangesMessage'

// https://github.com/randlabs/myalgo-connect/issues/27
import buffer from 'buffer'
const {Buffer} = buffer
if (!window.Buffer) window.Buffer = Buffer

const randomstring = require('randomstring')

//TODO: this need to be refactored into upload_controller
export default class fileUpload {
  constructor() { }

  init = (fileInput, autosave) => {
    this.fileUploadDashboard(fileInput, autosave)
  }

  fileUploadDashboard = (fileInput, autosave) => {
    const formGroup = fileInput.parentNode
    const form =  fileInput.closest("form.documents-form")

    let triggerSelector = fileInput.dataset.trigger || '#btn-upload'

    const trigger = form.querySelector(triggerSelector)
    if (trigger === null) {
      triggerSelector = null
    }

    let model = form.dataset.model
    let documentContainer = form.dataset.documentContainer
    let documentsAttributes = form.dataset.documentsAttributes || 'documents_attributes'
    let documentTypeId = formGroup.dataset.documentTypeId
    if (formGroup.dataset.model) {
      model = formGroup.dataset.model
      documentContainer = formGroup.dataset.documentContainer
      documentsAttributes = formGroup.dataset.documentsAttributes || 'documents_attributes'
    }
    const uppy = this.uppyFileUpload(fileInput)
    const error = formGroup.querySelector('.invalid-feedback')
    const inline = fileInput.dataset.inline == 'true'
    const useStandard = fileInput.dataset.useStandard == 'true'
    const pendingTarget = fileInput.dataset.pendingTarget || '.pending-uploads'
    const height = fileInput.dataset.height || "500px"
    const width = fileInput.dataset.width || "750px"
    const id = fileInput.dataset.id || 'Dashboard'
    const note = fileInput.dataset.note || ''
    const hideSelectedFiles = fileInput.dataset.showSelectedFiles == 'false'

    uppy
      .use(Dashboard, {
        id: id,
        target: formGroup,
        inline: inline,
        note: note,
        trigger: triggerSelector,
        replaceTargetContent: true,
        height: height,
        width: width,
        browserBackButtonClose: true,
        animateOpenClose: true,
        proudlyDisplayPoweredByUppy: false,
        showSelectedFiles: !hideSelectedFiles,
        disableStatusBar: hideSelectedFiles
        })

    if (error) {
      formGroup.insertAdjacentHTML('afterbegin', `<div class='text-error'><small>${error.innerText}</small></div>`)
    }
    formGroup.classList.remove("d-none")
    uppy.on('upload-success', (file, response) => {
      //TODO: this show unsaved messages business needs to be moved out of here
      // we need to have some kind of message handling controller to deal with it
      // instead we should just fire an event that we have unsaved changes

      //display unsaved changes message
      showUnsavedChangesMessage()

      //TODO: this save button hiding business needs to be moved out of here
      // instead we should fire an event and let the actions controller handle button hiding

      //disable complete button
      document.getElementById("claim-task-complete-btn")?.classList.add("disabled")
      //enable save button on claim form
      let claimSaveBtn = document.getElementById("save-btn")
      if (claimSaveBtn) { 
        claimSaveBtn.disabled = false
        claimSaveBtn.classList.remove('disabled')
      }

      const url = response.uploadURL
      const fileName = file.name
      const hiddenInput = form.querySelector('#' + fileInput.dataset.uploadResultElement)
      if ((inline && hiddenInput) || useStandard) {
        const fileData = this.uploadedFileData(file, response, fileInput)
        hiddenInput.value = fileData
      }
      else {
        const hiddenField = document.createElement('input')
        const randomId = randomstring.generate()

        const formPrefix = model + (documentContainer ? `[${documentContainer}]` : '')
        // Add hidden fields for rails controller params
        hiddenField.type = 'hidden'
        hiddenField.name = `${formPrefix}[${documentsAttributes}][${randomId}][file]`
        hiddenField.value = this.uploadedFileData(file, response, fileInput)

        const titleField = document.createElement('input')

        titleField.type = 'hidden'
        titleField.name = `${formPrefix}[${documentsAttributes}][${randomId}][title]`
        titleField.value = file.meta.title || fileInput.dataset.existingTitle || ""

        form.appendChild(hiddenField)
        // Dispatch the event from the provided element
        // Allows the event to bubble up through the DOM
        // this will trigger form input event which is used to monitor form changes
        hiddenField.dispatchEvent( new Event('input', {bubbles: true,cancelable: true}));

        form.appendChild(titleField)
        titleField.dispatchEvent( new Event('input', {bubbles: true,cancelable: true}));

        const existingId = fileInput.dataset.existingId
        if (existingId) {
          const existingIdField = document.createElement('input')
          existingIdField.type = 'hidden'
          existingIdField.name = `${formPrefix}[${documentsAttributes}][${randomId}][id]`
          existingIdField.value = existingId

          form.appendChild(existingIdField)
          existingIdField.dispatchEvent( new Event('input', {bubbles: true,cancelable: true}));
        }

        if (documentTypeId && documentTypeId.length > 0) {
          const documentTypeField = document.createElement('input')
          documentTypeField.type = 'hidden'
          documentTypeField.name = `${formPrefix}[${documentsAttributes}][${randomId}][document_type_id]`
          documentTypeField.value = documentTypeId

          form.appendChild(documentTypeField)
          documentTypeField.dispatchEvent( new Event('input', {bubbles: true,cancelable: true}));
        }
      }

      // And display uploaded files
      const pending = form.querySelector(pendingTarget)

      if (pending) {
        const ul = pending.querySelector('ul')
        if (!fileInput.multiple) ul.innerHTML = ""
        ul.innerHTML += this.pendingFileTemplate(url, fileName)
        pending.classList.remove("d-none")
        pending.classList.add("d-inline-block")
        // if (!fileInput.multiple && trigger) trigger.classList.add('d-none')
      }
      if (hideSelectedFiles) {
        uppy.reset()
      }

    })

    uppy.on('dashboard:modal-open', () => {
      console.log('Modal is open');
    });

    uppy.on('dashboard:modal-open', () => {
      if(autosave) {
        // we better boradcast that we are about to start modifying an auto savable document field
        // and whoever listens and care should worry about saving themselves
        document.dispatchEvent(new CustomEvent('formAboutToChangeBeforeAutosave', { detail: { form: form}}));
      }
    });


    uppy.on('dashboard:modal-closed', (event) => {
      if(autosave) {
        //form.submit()
        //may be we should dispatch it for whole document ?
        form.dispatchEvent(new CustomEvent('autosave'));
      }
    });


    return uppy
  }

  uppyFileUpload = (fileInput) => {
    let options = {
      id: fileInput.id,
      name: fileInput.name,
      autoProceed: true,
      restrictions: {
        allowedFileTypes: fileInput.accept.split(','),
      }
    }
    if (!fileInput.multiple) {
      options.allowMultipleUploads = false
      options.restrictions.maxNumberOfFiles = 1
    }
    const uppy = new Core(options)
    uppy.use(AwsS3Multipart, {
      companionUrl: '/' // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    })

    return uppy
  }

  uploadedFileData = (file, response, fileInput) => {
    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1]; // object key without prefix
    let fileData = this.fileData(file, id)
    return JSON.stringify(fileData)
  }

  // constructs uploaded file data in the format that Shrine expects
  fileData = (file, id) => ({
    id: id,
    storage: 'cache',
    metadata: {
      size:      file.size,
      filename:  file.name,
      mime_type: file.type
    }
  })

  pendingFileTemplate = (url, title) => {
    return `<li><div class="media-body form-row align-items-center mt-0">
    <div class="col-12">
      <div class="input-group input-group-sm mb-0 pb-0">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <a href='${url}' target='_blank'><i class='fa fa-file-upload'></i></a>
          </div>
        </div>
        <input type='text' class='form-control' readonly value='${title}'>
      </div>
    </div></div></li>`
  }
}



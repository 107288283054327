import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pagination"
export default class extends Controller {
  static targets = [ "pageNumber" ]
  static values = { paramName: String, totalPages: Number }

  gotoPage() {
    let pageNumber = parseInt(this.pageNumberTarget.value)
    if (pageNumber > this.totalPagesValue) {
      pageNumber = this.totalPagesValue
    }

    let newURL = new URL(window.location.href)
    newURL.searchParams.set(this.paramNameValue, pageNumber)
    window.location.assign(newURL)
  }
}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import clipboard from "../src/clipboard";
import dependentDropdown from "../src/dependentDropdown";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class FormStateManagerController extends Controller {
    // README:
    //  perhaps crosslink_from_controller needs to be merged with this, perhaps not

    connect() {
        //here we need to find closest element with data-controller="tabs"
        this.tabsContainer = this.element.closest('[data-controller="tabs"]')

        // !!!!!!!!!!!!!!!!!!!!
        // we need to bind this listeners with an instance of a controller so we can disconnect it later
        // and garbage collector will be able to cleanup if the form element gets removed from html
        // !!!!!!!!!!!!!!!!!!!!

        this.formAboutToChangeBeforeAutosaveEventHandler = this.formAboutToChangeBeforeAutosaveEventHandler.bind(this);
        document.addEventListener('formAboutToChangeBeforeAutosave', this.formAboutToChangeBeforeAutosaveEventHandler);

        this.requestMultiStateEventHandler = this.requestMultiStateEventHandler.bind(this);
        this.element.addEventListener('requestMultiState', this.requestMultiStateEventHandler);
        this.multiState = {}

        this.multiStateChangedEventHandler = this.multiStateChangedEventHandler.bind(this);
        this.element.addEventListener('multiStateChanged', this.multiStateChangedEventHandler);


        if(this.tabsContainer && this.element.dataset.tabName) {
            this.tabDeactivatedEventHandler = this.tabDeactivatedEventHandler.bind(this);
            this.tabsContainer.addEventListener(this.element.dataset.tabName + 'TabDeactivatedEvent', this.tabDeactivatedEventHandler);

            this.tabActivatedEventHandler = this.tabActivatedEventHandler.bind(this);
            this.tabsContainer.addEventListener(this.element.dataset.tabName + 'TabActivatedEvent', this.tabActivatedEventHandler);
        }

        this.formAutosaveEventHandler = this.formAutosaveEventHandler.bind(this);
        this.hookupFormEventsAndActions()
    }

    //!!!!! we need to cleanup event listeners when we remove this form from html, otherwise we will have memory leaks !!!!!
    disconnect() {
        document.removeEventListener('formAboutToChangeBeforeAutosave', this.formAboutToChangeBeforeAutosaveEventHandler);
        if(this.tabsContainer) {
            this.tabsContainer.removeEventListener(this.element.dataset.tabName + 'TabDeactivatedEvent', this.tabDeactivatedEventHandler);
            this.tabsContainer.removeEventListener(this.element.dataset.tabName + 'TabActivatedEvent', this.tabActivatedEventHandler);
        }
        this.element.removeEventListener('requestMultiState', this.requestMultiStateEventHandler);
        this.element.removeEventListener('multiStateChanged', this.multiStateChangedEventHandler);

        this.deactivateFormEvents();
    }
    //perhaps there should be a document wide autosave event, but for now lets handle it specificaly here
    deactivateFormEvents(){
        if(this.form){
            this.form.removeEventListener('autosave', this.formAutosaveEventHandler);
        }
    }

    tabDeactivatedEventHandler(event) {
        this.save()
    }

    tabActivatedEventHandler(event) {
        this.lazyLoadContent(event)
    }

    // this method is used when we hookup to our own form and autosave when asked
    // for now it only used on the documents panel, perhaps it is an overkill to have it here
    // the idea was to implement lazy loading and action cable notifications
    // but for now just submit the form
    formAutosaveEventHandler(event) {
        if (this.formChanged && !this.formIsSaving && this.form){
            // this is a hack to turn of validation we will keep it like this until further refactoring
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'disable_validation');
            hiddenInput.setAttribute('value', 'true'); // Set this to 'true' or 'false' based on your logic
            this.form.appendChild(hiddenInput);
            this.form.submit();
        }
    }

    formAboutToChangeBeforeAutosaveEventHandler(event) {
        //only if some different form is about to change we want to save ourselves
        if (this.form && this.form !== event.detail.form){
            this.save()
        }
    }

    lazyLoadContent(event) {
        const url = this.element.dataset.tabUrl
        this.element.classList.add('loading')



        Rails.ajax({
            type: "get",
            url: url,
            success: (data) => {
                this.deactivateFormEvents();
                this.element.innerHTML = data.tab_html

                let url = new URL(location)
                url.searchParams.set('tab', this.element.dataset.tabName)
                window.history.pushState('data', '', url)

                this.element.classList.remove('loading')
                this.element.classList.add('loaded')
                document.body.dispatchEvent(new CustomEvent('tooltips:refresh'))

                //TODO: these methods may overinitialize elements that have already been initialized
                // we need to refactor those into stimulus and let the stimulus deal with initialization
                // until we refactor to stimulus we need to pass only the element where content just loaded

                new clipboard().init()
                new dependentDropdown().init()

                this.hookupFormEventsAndActions()

                //update spinner on the tab
                this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadedEvent', { detail: { tab: this.element, keep_scroll_position: true } }))

                //TODO: here we may need to expand/collapse what has been already expanded
                // perhaps scroll into view where we left off before going away from this tab
            },
            error: (data) => {
                console.log(data)
                this.notify('Failed to Load', 'error')
            }
        })
    }

    hookupFormEventsAndActions(){
        // in case we have a residual form lets deactivate it
        this.deactivateFormEvents();
        let changed = false
        const form = this.element.querySelector('form'); // here is form that is the problem
        if (form) {
            const actionsToAdd = [
                "input->form-state-manager#changed",
                "formChanged->form-state-manager#changed" // this one is emitted by multies controller and elsewhere perhaps opportunity to consolidate
            ];

            // Get the current data-action value
            let currentActions = form.getAttribute('data-action') || '';
            let actions = currentActions.split(" ").filter(Boolean); // Split by space and remove any empty strings

            let pushedAction = false;
            //iterate over all actions we want to add
            actionsToAdd.forEach((actionToAdd) => {
                if (!actions.includes(actionToAdd)) {
                    actions.push(actionToAdd);
                    pushedAction = true;
                }
            });

            // Check if the actionToAdd is already included
            if (pushedAction) {
                // Update the data-action attribute
                form.setAttribute('data-action', actions.join(" "));
            }

            //hookup form and autosave listener
            this.form = form
            this.form.addEventListener('autosave', this.formAutosaveEventHandler);

            changed = form.dataset.hasUnsavedChanges == 'true'
            form.removeAttribute('data-has-unsaved-changes')
            form.classList.add('initialized')
        }

        this.dispatchFormChangedEvent(changed)
    }

    //this handler is hooked up to form input event
    changed(event) {
        this.dispatchFormChangedEvent(true)
    }

    dispatchFormChangedEvent(changed) {
        this.formChanged = changed
        const changedEvent = new CustomEvent('formChangedEvent', { detail: { formContainer: this.element, changed: this.formChanged } });
        document.dispatchEvent(changedEvent);
    }

    save(){
        // only save when needed and already in the process of saving
        if (this.formChanged && !this.formIsSaving ) {
            const form = $(this.element.querySelector('form'));
            let data = form.serialize()
            const url = form.data('save-url')

            //need this event so buttons could disable themselves
            this.formIsSaving = true
            document.dispatchEvent(new CustomEvent('formBeforeSaving', { detail: { formContainer: this.element} }));

            Rails.ajax({
                type: "put",
                url: url,
                data: data,
                contentType: "application/json",
                success: (data) => {
                    document.dispatchEvent(new CustomEvent('formSaveSuccess', { detail: { formContainer: this.element} }));

                    // let action buttons know that we have no form no more
                    this.dispatchFormChangedEvent(false)
                    this.notify(data.notice, 'success')
                    this.formIsSaving = false
                },
                error: (data) => {
                    document.dispatchEvent(new CustomEvent('formSaveError', { detail: { formContainer: this.element} }));
                    this.formIsSaving = false
                    console.log(data)
                    this.notify(data.alert, 'error')
                }
            })
        }
    }

    notify = (msg, type) => {
        Swal.fire({
            text: msg,
            icon: type,
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3500,
            customClass: {
                icon: 'toast-icon'
            }})
    }

    requestMultiStateEventHandler(event) {
        const multi = event.detail.multi
        const multiId = multi.id
        const respondEvent = new CustomEvent('setMultiState', {detail: {multiId: multiId, state: this.multiState[multiId]}})
        this.element.dispatchEvent(respondEvent)
    }

    multiStateChangedEventHandler(event) {
      this.multiState[event.detail.multiId] = event.detail.state
    }

}
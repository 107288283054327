import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {debounce} from 'lodash';
import clipboard from "../src/clipboard";
import dependentDropdown from "../src/dependentDropdown";

import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class ClipboardController extends Controller {
    copy(event) {
        const button = event.target.closest('.btn-copy-content');
        const data = button.dataset.content;
        const message = button.dataset.copyMessage;

        navigator.clipboard.writeText(data)
            .then(() => {
                this.showCopyMessage(message);
            })
            .catch((err) => console.error(err.name, err.message));
    }

    showCopyMessage(message) {
        if (message) {
            Swal.fire({
                title: message,
                icon: '',
                timer: 1500,
                padding: '1em',
                toast: true,
                showConfirmButton: false,
                showCloseButton: false
            });
        }
    }
}

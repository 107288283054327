import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {debounce} from 'lodash';
import clipboard from "../src/clipboard";
import dependentDropdown from "../src/dependentDropdown";

export default class ActionsController extends Controller {
    connect() {
        // !!!!!!!!!!!!!!!!!!!!
        // we need to bind this listeners with an instance of a controller so we can disconnect it later
        // and garbage collector will be able to cleanup if the form element gets removed from html
        // !!!!!!!!!!!!!!!!!!!!

        this.formChangedEventHandler = this.formChangedEventHandler.bind(this);
        document.addEventListener('formChangedEvent', this.formChangedEventHandler);

        this.formBeforeSavingHandler = this.formBeforeSavingHandler.bind(this);
        document.addEventListener('formBeforeSaving', this.formBeforeSavingHandler);

        this.formSaveSuccessHandler = this.formSaveSuccessHandler.bind(this);
        document.addEventListener('formSaveSuccess', this.formSaveSuccessHandler);

        this.observedElementsDictionary = {}
    }

    disconnect() {
        document.removeEventListener('formChangedEvent', this.formChangedEventHandler);
        document.removeEventListener('formBeforeSaving', this.formBeforeSavingHandler);
        document.removeEventListener('formSaveSuccess', this.formSaveSuccessHandler);
    }

    formChangedEventHandler(event) {
        this.observedElementsDictionary[event.detail.formContainer.id] = event.detail.changed
        this.enableDisableButtons(event.detail.formContainer.id, event.detail.changed)
    }

    formBeforeSavingHandler(event) {
        const formContainerId = event.detail.formContainer.id
        const buttons = this.element.querySelectorAll(`button`)
        buttons.forEach((button) => {
            if(button.dataset.forFormContainer === formContainerId){
                //here we need to prevent buttons from being clicked
                //first need to save original text
                button.disabled = true; // disable button here
                button.dataset.originalHtml = button.innerHTML; // Store the original HTML
                const disableWith = button.dataset.disableWith || 'Processing...';
                button.innerHTML = disableWith;
            }
        })
    }

    formSaveSuccessHandler(event) {
        const formContainerId = event.detail.formContainer.id
        const buttons = this.element.querySelectorAll(`button`)
        buttons.forEach((button) => {
            if(button.dataset.forFormContainer === formContainerId){
                //here we need to enable buttons
                button.innerHTML = button.dataset.originalHtml; // Restore the original HTML
                button.disabled = false; // enable button here
            }
        })

        // make sure buttons are properly disabled
        this.observedElementsDictionary[formContainerId] = false
        this.enableDisableButtons(formContainerId, false)
    }

    enableDisableButtons(formContainerId, changed) {
        const formContainer = document.getElementById(formContainerId)
        const form = formContainer.querySelector('form')
        const buttons = this.element.querySelectorAll(`button`)
        buttons.forEach((button) => {
            if(button.dataset.forFormContainer === formContainerId){
                if(form){
                    button.setAttribute('form', form.id);
                    button.disabled = false
                }else{
                    button.setAttribute('form', '');
                    button.disabled = true
                }
            }
        })

        const unsavedMessage = this.element.querySelector(`#unsaved-changes-message`)
        if(unsavedMessage){
            if(changed){
                unsavedMessage.classList.remove('d-none')
            }else{
                unsavedMessage.classList.add('d-none')
            }
        }
    }
}

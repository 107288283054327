import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  goto = function(event) {
    const href = event.currentTarget.dataset.href
    if (href) {
      window.location.href = href
    }
  }
}

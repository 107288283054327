import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {debounce} from 'lodash';
import scrollIntoView from "scroll-into-view-if-needed";


export default class CrosslinkFormController extends Controller {

    static targets = ["spinner"]

    // README:
    //  At this point this controller is hooked up by the form element
    //  but if we ever create another controller for a form it may be competing with this one
    //  this controller really meant for manipulating crosslinks and not for saving the form
    //  it could theoretically be hooked up to a div or any other element that has crosslink data

    connect() {
        // !!!!!!!!!!!!!!!!!!!!
        // we need to bind this listeners with an instance of a controller so we can disconnect it later
        // and garbage collector will be able to cleanup if the form element gets removed from html
        // !!!!!!!!!!!!!!!!!!!!

        this.documentPanelStatusEventHandler = this.documentPanelStatusEventHandler.bind(this);
        document.addEventListener('documentPanelStatus', this.documentPanelStatusEventHandler);

        this.crosslinkChangedEventHandler = this.crosslinkChangedEventHandler.bind(this);
        document.addEventListener('crosslinkChanged', this.crosslinkChangedEventHandler);

        this.crosslinkSelectionChangedEventHandler = this.crosslinkSelectionChangedEventHandler.bind(this);
        document.addEventListener('crosslinkSelectionChanged', this.crosslinkSelectionChangedEventHandler);

        this.crosslinkDeletedEventHandler = this.crosslinkDeletedEventHandler.bind(this);
        document.addEventListener('crosslinkDeleted', this.crosslinkDeletedEventHandler);

        //form controller does not need to know how to find out if document is open on the panel or not
        //so it will just request the panel status and it will tell us if the document is open or not
        //this will ping the panel status when the controller get connected and the pong will come into a handler
        const event = new CustomEvent("requestDocumentPanelStatus", {
            detail:{crosslinkForm: this.element}
        })
        document.dispatchEvent(event)

        // only replace spinner when found
        if (this.hasSpinnerTarget) {
            this.replaceSpinner()
        }

        //TODO: somehow we should be telling the panel that this instance of a form controller is active form
        // how do we know that we are active?
    }

    //!!!!! we need to cleanup event listeners when we remove this form from html, otherwise we will have memory leaks !!!!!
    disconnect() {
        document.removeEventListener('documentPanelStatus', this.documentPanelStatusEventHandler);
        document.removeEventListener('crosslinkChanged', this.crosslinkChangedEventHandler);
        document.removeEventListener('crosslinkSelectionChanged', this.crosslinkSelectionChangedEventHandler);
        document.removeEventListener('crosslinkDeleted', this.crosslinkDeletedEventHandler);
    }

    //when document is closed or opened the panel crosslink-editor-controller will dispatch event
    //which we will handle here and we know what to do here
    documentPanelStatusEventHandler(event) {
        //possibly we could check which form is active on th panel and do something about to prevent extra work
        if(event.detail.crosslinkForm === this.element && event.detail.documentIsOpen){
            this.enableAddCrosslinkButtons()
        }else{
            this.disableAddCrosslinkButtons()
            this.clearAllHalos()
        }

        //TODO: when form is different we need to clearAllHalos
    }

    crosslinkChangedEventHandler(event) {
        //only do it for current form
        if(event.detail.crosslinkForm === this.element){
            let input = event.detail.input
            input.value = JSON.stringify(event.detail.crosslink)
        }
    }

    crosslinkSelectionChangedEventHandler(event) {
        //only do it for current form
        if(event.detail.crosslinkForm === this.element){
            let crosslink_input = event.detail.input
            //remove halo from all other crosslink containers
            this.clearAllHalos()

            if (crosslink_input) {
                let crosslink_container = crosslink_input.closest('.crosslink-container')
                if (crosslink_container) {
                    // only add halo to the container in question
                    crosslink_container.classList.add('crosslink_halo')

                    //only scroll into view when directed
                    if(event.detail.scroll_into_view) {
                        scrollIntoView(crosslink_container, {
                            behavior: 'smooth',
                            scrollMode: 'always',
                            block: 'center',
                            inline: 'center',
                        })
                    }
                }
            }
        }
    }
    //this method may be called when we think that this particular form became active

    crosslinkDeletedEventHandler(event) {
        //only do it for current form
        if(event.detail.crosslinkForm === this.element){
            let input = event.detail.input
            let crosslink_container = input.closest('.crosslink-container')
            input.remove()

            //now cleanup menu
            const menu = crosslink_container.querySelector('.crosslink-menu')
            if (menu){
                //will add a menu link to new crosslink
                const dropdown_list = menu.querySelector('.dropdown-menu .list')
                // hare I want to get number of dropdown-item  element inside  dropdown_list

                const divider = menu.querySelector('.dropdown-divider')
                const menuBtn = menu.querySelector('.dropdown-toggle i')

                if(dropdown_list){
                    const menu_link = dropdown_list.querySelector(`.dropdown-item[data-crosslink-id='${event.detail.crosslink.crosslink_id}']`)
                    menu_link.remove()

                    const menus_length = dropdown_list.querySelectorAll('.dropdown-item').length

                    if (menus_length === 0) {
                        menuBtn.classList.remove("fas")
                        menuBtn.classList.add("far")
                        if (divider) {
                            divider.classList.add("d-none")
                        }
                    }
                }
            }

            this.clearAllHalos()
        }
    }

    // not sure about this looks like not used
    activate() {
      //this will trigger event on crosslink-editor-controller controller
      const crosslinkFormActiveEvent = new CustomEvent("crosslinkFormActive", {
          detail:{crosslinkForm: this.element}
      })
      document.dispatchEvent(crosslinkFormActiveEvent)
    }

    showCrosslink(event) {
        event.preventDefault()

        //here we need to recover crosslink container from the event
        //use currentTarget because that is where the action is hooked up not where we clicked
        let showButton = event.currentTarget
        const crosslink_id = showButton.dataset.crosslinkId

        //somehow we should be able to find hidden crosslink input here
        //at this point crosslink-container may have multiple higlight inputs
        //so we could find a container or we could just look within the form itself
        //where we should have only one crosslink input per crosslink id
        const crosslink_input = this.element.querySelector(`.crosslink[data-crosslink-id='${crosslink_id}']`)

        // clear halos, we expect the selection crosslink event to come back when the selection actually happened
        this.clearAllHalos()

        // here we need to pass an element that has all the detail about crosslink, so it can be drawn on the canvas
        const showCrosslinkEvent = new CustomEvent("showCrosslink",{
            detail: {
                crosslinkForm: this.element,
                crosslink_id: crosslink_id,
                input: crosslink_input,
                scroll_into_view: false // we don't want to scroll into view here, because we are already in the view
            }
        })
        document.dispatchEvent(showCrosslinkEvent)
    }

    addCrosslink(event) {
        event.preventDefault()

        //here we need to recover crosslink container from the event
        //use currentTarget because that is where the action is hooked up not where we clicked
        let crosslink_container = event.currentTarget.closest('.crosslink-container')
        const index = Date.now()

        let input_id = crosslink_container.id + `_${index}`
        //input_id = input_id.replace(/(.*)_0$/, "$1")

        let input_name = crosslink_container.getAttribute('name') + `[${index}]`
        //input_name = input_name.replace(/(.*)\[\](.*)$/, "$1$2")

        // make sure this is consistent with the dynamic formable suggestion context function
        let crosslink = {
            crosslink_id: `temp_${index}`,
            form_attribute_type: 'attribute',
            // TODO: form_attribute_path: '', need to implement here function that extract form_attribute_path from .crosslink-container
            document_highlight: {
                // page and document id and document type will be populated by crosslink-editor-controller
                left: 0.25,
                top: 0.1,
                width: 0.2,
                height: 0.1
            },
            data:  {
                comments: '',
                work_product_information: {}
            }
        }

        let html = `<input type="hidden" class="crosslink" data-crosslink-id="${crosslink.crosslink_id}" id="${input_id}" name="${input_name}">`
        crosslink_container.insertAdjacentHTML('beforeend', html)

        let crosslink_input = crosslink_container.lastChild
        // this will be reset anyway by crosslink-editor-controller, but just in case we want to keep something there, may be not needed
        crosslink_input.value = JSON.stringify(crosslink)

        const menu = event.currentTarget.closest('.crosslink-menu')

        if (menu){
            //will add a menu link to new crosslink
            const dropdown_list = menu.querySelector('.dropdown-menu .list')
            // hare I want to get number of dropdown-item  element inside  dropdown_list
            const next_link_index = dropdown_list.querySelectorAll('.dropdown-item').length + 1
            const divider = menu.querySelector('.dropdown-divider')
            const menuBtn = menu.querySelector('.dropdown-toggle i')

            if(dropdown_list){
                //here we are adding new crosslink so there will be crosslinks in the menu
                //if we would be removing last crosslink we would do opposite
                menuBtn.classList.add("fas")
                menuBtn.classList.remove("far")
                if (divider) {
                    divider.classList.remove("d-none")
                }
                html = `<button type="button" class="dropdown-item crosslink-entry btn-show-crosslink" data-crosslink-id="${crosslink.crosslink_id}" data-index="${next_link_index}" data-action="click->crosslink-form#showCrosslink"><i class="fa fa-eye"></i> Link #${next_link_index}`
                dropdown_list.insertAdjacentHTML('beforeend', html)
            }
        }

        //here we will add new crosslink
        let eventPayload = {
            crosslink: crosslink,
            crosslinkForm: this.element,
            input: crosslink_input,
            scroll_into_view: false // we don't want to scroll into view here, because we are already in the view
        }

        // here we need to pass an element that has all the detail about crosslink, so it can be drawn on the canvas
        const crosslinkEvent = new CustomEvent("addCrosslink", {
            detail: eventPayload
        })

        document.dispatchEvent(crosslinkEvent)

    }

    enableAddCrosslinkButtons() {
        this.element.querySelectorAll('.btn-add-crosslink').forEach((el) => {el.classList.remove('disabled')})
    }

    disableAddCrosslinkButtons() {
        this.element.querySelectorAll('.btn-add-crosslink').forEach((el) => {el.classList.add('disabled')})
    }

    clearAllHalos() {
        this.element.querySelectorAll('.crosslink_halo').forEach((el) => {
            el.classList.remove('crosslink_halo')
        })
    }

    searchInDocuments(event) {
        let suggestionButton = event.currentTarget
        const attributeId = suggestionButton.dataset.attributeId

        // let them know that this form no longer cares about crosslinks
        const crosslinkFormActiveEvent = new CustomEvent("crosslinkFormActive", {
            detail:{crosslinkForm: null}
        })
        document.dispatchEvent(crosslinkFormActiveEvent)


        let eventDetail = {
            crosslinkForm: this.element,
            attributeId: attributeId
        }
        let crosslink_container = event.currentTarget.closest('.crosslink-container')
        if (crosslink_container) {
            let crosslink_target_input = crosslink_container.querySelector('input.crosslink-target')
            if (crosslink_target_input) {
                let search_string = crosslink_target_input.value.trim();
                if (search_string !== "") {
                    eventDetail.search_string = search_string
                }
            }
        }


        const searchInDocumentsEvent = new CustomEvent("searchInDocumentsEvent",{
            detail: eventDetail
        })

        document.dispatchEvent(searchInDocumentsEvent)
    }

    replaceSpinner = function () {
        this.spinnerTargets.forEach((spinner) => {
            const url = spinner.dataset.url
            Rails.ajax({
                type: "get",
                url: url,
                success: (data) => {
                    spinner.outerHTML = data.html_data
                },
                error: (error) => {
                    console.log(error)
                    spinner.outerHTML = null // remove the spinner
                }
            })
        });

    }
}
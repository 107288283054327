import { Controller } from "@hotwired/stimulus"
import Split from 'split.js'


export default class SplitViewController extends Controller {
  static targets = ["column"]
  connect() {

    const attributes_to_observe = ["class", "data-size"]
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === "attributes" && attributes_to_observe.includes(mutation.attributeName)) {
          //const target = mutation.target;

          this.applySplit();
        }
      });
    });

    this.columnTargets.forEach(column => {
      this.observer.observe(column, {
        attributes: true, // Watch for attribute changes
        attributeFilter: attributes_to_observe // Specifically for changes in the class attribute
      });

    });

    this.applySplit()
  }

  disconnect() {
    // Don't forget to disconnect the observer when the controller is disconnected to prevent memory leaks
    this.observer.disconnect();
  }

  applySplit() {
    if (this.columnTargets.length > 0) {
      let columns = [];
      let sizes = []
      this.columnTargets.forEach(column => {
        if (!column.classList.contains("d-none")){
          columns.push("#" + column.id);
          //we have opportunity here to assign sizes smarter
          sizes.push(parseInt(column.dataset.size )|| 50);
        }
      });

      if (this.split) {
        this.split.destroy()
      }

      this.split = Split(columns, {
        gutterSize: 7,
        sizes: sizes,
        elementStyle: (dimension, size, gutterSize) => ({
          'flex-basis': `calc(${size}% - ${gutterSize}px)`,
        }),
        gutterStyle: (dimension, gutterSize) => ({
          'flex-basis':  `${gutterSize}px`,
        })
      })
    }
  }



}

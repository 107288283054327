import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {debounce} from 'lodash';
import clipboard from "../src/clipboard";
import dependentDropdown from "../src/dependentDropdown";

export default class AiCardFilterController extends Controller {

    connect() {
        this.aiPanel = document.querySelector("#data-ai-attribute-suggestions-panel")
        if(this.aiPanel){
            this.activeSuggestionCardUnlockEventHandler = this.activeSuggestionCardUnlockEventHandler.bind(this);
            this.aiPanel.addEventListener('activeSuggestionCardUnlock', this.activeSuggestionCardUnlockEventHandler);
        }
        this.estimatedCardsResultEventHandler = this.estimatedCardsResultEventHandler.bind(this);
        document.addEventListener('estimatedCardsResultEvent', this.estimatedCardsResultEventHandler);
        const date_filters = this.element.querySelectorAll('.attr-date-filter');
        date_filters.forEach(date_filter => {
            this.hideShowOptionsForDateRange(date_filter);
        });
    }

    disconnect() {
        document.removeEventListener('estimatedCardsResultEvent', this.estimatedCardsResultEventHandler);
        if(this.activeSuggestionCardUnlockEventHandler && this.aiPanel) {
            this.aiPanel.removeEventListener('activeSuggestionCardUnlock', this.activeSuggestionCardUnlockEventHandler);
        }
    }

    activeSuggestionCardUnlockEventHandler(event) {
        if( event.detail.card.dataset.crosslinked === "true" && event.detail.saved === true){
            this.estimateCardCount(event);
        }
    }

    selectUnselectAllCheckboxes(event) {
        const modal_body = event.target.closest('.modal-body');

        const section = event.target.closest('.card-body');
        const checkboxes = section.querySelectorAll('input[type="checkbox"].select-one');
        checkboxes.forEach(checkbox => {
            checkbox.checked = event.target.checked;

            //here we will make other same named entities click in other sections
            const other_checkboxes = modal_body.querySelectorAll(`.entities-section input.select-one[data-value="${checkbox.dataset.value}"]:not(#${checkbox.id})`);
            other_checkboxes.forEach(other_checkbox => {
                other_checkbox.checked = checkbox.checked;
                const other_section = checkbox.closest('.card-body')
                if(other_section !== section) {
                    this.setSelectAllCheckbox(other_section);
                }
            });
        });
        this.estimateCardCount(event);
    }

    checkboxChange(event) {
        const section_body = event.target.closest('.card-body');
        this.setSelectAllCheckbox(section_body);
        const modal_body = event.target.closest('.modal-body');
        const checkboxes = modal_body.querySelectorAll(`.entities-section input.select-one[data-value="${event.target.dataset.value}"]:not(#${event.target.id})`);
        checkboxes.forEach(checkbox => {
            checkbox.checked = event.target.checked;
            this.setSelectAllCheckbox(checkbox.closest('.card-body'));
        });

        this.estimateCardCount(event);
    }

    dateRangeChange(event) {
      const attr_date_filter = event.currentTarget.closest('.attr-date-filter');
      this.hideShowOptionsForDateRange(attr_date_filter);
      this.estimateCardCount(event);
    }

    hideShowOptionsForDateRange(attr_date_filter) {
        const min_date = attr_date_filter.querySelector('.attr-min');
        const max_date = attr_date_filter.querySelector('.attr-max');
        const min_options = min_date.querySelectorAll('option');
        min_options.forEach(option => {
            if(max_date.value === 'no max') {
                option.classList.remove('hidden');
            } else {
                if(option.value !== 'no min') {
                    if (option.value > max_date.value) {
                        option.classList.add('hidden');
                    } else {
                        option.classList.remove('hidden');
                    }
                }
            }
        })
        const max_options = max_date.querySelectorAll('option');
        max_options.forEach(option => {
            if(min_date.value === 'no min') {
                option.classList.remove('hidden');
            } else {
                if(option.value !== 'no max') {
                    if (option.value < min_date.value) {
                        option.classList.add('hidden');
                    } else {
                        option.classList.remove('hidden');
                    }
                }
            }
        });
    }

    setSelectAllCheckbox(section_body){
        const checkboxes = section_body.querySelectorAll('input[type="checkbox"].select-one');
        const selectAllCheckbox = section_body.querySelector('input[type="checkbox"].select-all');

        if(checkboxes.length !== 0 && selectAllCheckbox) {
            selectAllCheckbox.checked = Array.from(checkboxes).every(checkbox => checkbox.checked);
        }
    }

    resetFilters(event) {
        event.preventDefault();
        const form = event.target.closest('form');

        //entity sections reset
        const entity_sections= this.element.querySelectorAll('.filter-section.entities-section')
        entity_sections.forEach(entity_section => {
            const section_body = entity_section.querySelector('.card-body');
            let checkboxes = section_body.querySelectorAll('input[type="checkbox"].select-one');
            checkboxes.forEach(checkbox => {
                checkbox.checked = false;
            });
            this.setSelectAllCheckbox(section_body);
        });

        //trait section reset
        const trait_section= this.element.querySelector('.filter-section.traits-section')
        if (trait_section) {
            const trait_section_body = trait_section.querySelector('.card-body');
            const trait_exclude_radios = trait_section_body.querySelectorAll('input[type="radio"].trait-filter-exclude:checked');
            const trait_include_radios = trait_section_body.querySelectorAll('input[type="radio"].trait-filter-include:checked');
            const trait_any_radios = trait_section_body.querySelectorAll('input[type="radio"].trait-filter-any');

            trait_exclude_radios.forEach(radio => {
                radio.checked = false;
                //here I want to find a clossest label element with class btn-contrasted
                const label = radio.closest('label.btn-contrasted');
                label.classList.remove('active');
            });

            trait_include_radios.forEach(radio => {
                radio.checked = false;
                const label = radio.closest('label.btn-contrasted');
                label.classList.remove('active');

            });

            trait_any_radios.forEach(radio => {
                radio.checked = true;
                const label = radio.closest('label.btn-contrasted');
                label.classList.add('active');
            });
        }

        //misc section reset
        const misc_section_body= this.element.querySelector('.filter-section.misc-section .card-body')
        const show_only_crosslinks_checkbox = misc_section_body.querySelector('input[type="checkbox"].show-only-crosslinks');
        show_only_crosslinks_checkbox.checked = false;

        //documents sections reset
        const document_section= this.element.querySelector('.filter-section.documents-section')

        //dates section reset
        const attr_dates_section = this.element.querySelector('.filter-section.attr-dates-section');
        if (attr_dates_section){
            const attr_dates_section_body = attr_dates_section.querySelector('.card-body');
            const min_dates = attr_dates_section_body.querySelectorAll('.attr-min');
            const max_dates = attr_dates_section_body.querySelectorAll('.attr-max');

            min_dates.forEach(min_date => {
                const date_range_name = min_date.dataset.name;
                min_date.value = 'no min';
            });

            max_dates.forEach(max_date => {
                max_date.value = 'no max';
                const attr_date_filter = max_date.closest('.attr-date-filter');
                this.hideShowOptionsForDateRange(attr_date_filter);
            });


        }



        const section_body = document_section.querySelector('.card-body');
        let checkboxes = section_body.querySelectorAll('input[type="checkbox"].select-one');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
        this.setSelectAllCheckbox(section_body);

        this.estimateCardCount(event)
    }

    applyFilters(event) {
        const filters = this.generateFilters()
        const filterEvent = new CustomEvent('filterCardsEvent', { detail: {
            filters:filters,
            focus_element_selector: '#ai-cards-filter-btn'
        } });
        document.dispatchEvent(filterEvent);
    }

    estimatedCardsResultEventHandler(event) {
        const count = event.detail.available_filters.filtered_doc_count;
        const applyBtn = this.element.querySelector('.modal-footer .apply-btn');
        applyBtn.innerText = `Show ${count} Matches`;

        const crosslinks_doc_count_element= this.element.querySelector('.filter-section.misc-section .card-body label.show-only-crosslinks .doc-count')

        //crosslinks
        const crosslinks_buckets = event.detail.available_filters.buckets.misc.crosslinks;
        const crosslinked_bucket = crosslinks_buckets.find(b => b.key === 'crosslinked');
        crosslinks_doc_count_element.innerText = crosslinked_bucket.filtered_doc_count;

        //entities
        const entity_sections= this.element.querySelectorAll('.filter-section.entities-section')
        const collections = event.detail.available_filters.buckets.collections;

        for (const entity_section of entity_sections) {
            const section_body = entity_section.querySelector('.card-body');
            const entity_labels = section_body.querySelectorAll('label.select-one');
            const collection_key = entity_section.dataset.collection;
            const collection = collections.find(c => c.key === collection_key);
            const entities = collection.entities;
            for (const entity_label of entity_labels) {
                const entity_key = entity_label.dataset.key;
                const entity = entities.find(e => e.key === entity_key);
                if(!entity) {
                    doc_count_element.innerText = 0;
                    continue;
                }
                const doc_count_element = entity_label.querySelector('.doc-count');
                doc_count_element.innerText = entity.filtered_doc_count;
            }
        }

        //documents
        const documents_section = this.element.querySelector('.filter-section.documents-section');
        const documents_section_body = documents_section.querySelector('.card-body');
        const documents_labels = documents_section_body.querySelectorAll('label.select-one');
        const documents = event.detail.available_filters.buckets.documents;
        for (const document_label of documents_labels) {
            const document_key = document_label.dataset.key;
            const document = documents.find(d => String(d.key) === document_key);
            const document_doc_count_element = document_label.querySelector('.doc-count');
            if(!document) {
                document_doc_count_element.innerText = 0;
                continue;
            }
            document_doc_count_element.innerText = document.filtered_doc_count;
        }

        //traits
        const trait_section= this.element.querySelector('.filter-section.traits-section')
        if(trait_section) {
            const trait_section_body = trait_section.querySelector('.card-body');
            const traits_include_labels = trait_section_body.querySelectorAll('label.trait-filter-include');
            const traits = event.detail.available_filters.buckets.traits;
            for (const trait_label of traits_include_labels) {
                const trait_key = trait_label.dataset.key;
                const trait = traits.find(t => t.key === trait_key);
                const doc_count_element = trait_label.querySelector('.doc-count');
                if(!trait) {
                    doc_count_element.innerText = 0;
                    continue;
                }
                doc_count_element.innerText = trait.filtered_doc_count;
            }
        }

    }

    estimateCardCount(event) {
        const filters = this.generateFilters()
        const filterEvent = new CustomEvent('estimateCardsEvent', { detail: {filters:filters} });
        document.dispatchEvent(filterEvent);
    }

    generateFilters() {
        let filters = {};

        // misc section
        const misc_section_body= this.element.querySelector('.filter-section.misc-section .card-body')
        const show_only_crosslinks_checkbox = misc_section_body.querySelector('input[type="checkbox"].show-only-crosslinks');
        let misc_include_filters = [];
        if (show_only_crosslinks_checkbox.checked) {
            misc_include_filters.push('crosslinks');
        }
        if(misc_include_filters.length > 0) {
            filters['misc'] = {
                'include': misc_include_filters
            }
        }

        // traits section
        const traits_section_body = this.element.querySelector('.filter-section.traits-section .card-body');
        if(traits_section_body){
            const trait_exclude_radios = traits_section_body.querySelectorAll('input[type="radio"].trait-filter-exclude:checked');
            const trait_include_radios = traits_section_body.querySelectorAll('input[type="radio"].trait-filter-include:checked');

            let trait_include_filters = [];
            let trait_exclude_filters = [];

            trait_include_radios.forEach(radio => {
                trait_include_filters.push(radio.dataset.value);
            });

            trait_exclude_radios.forEach(radio => {
                trait_exclude_filters.push(radio.dataset.value);
            });

            let trait_filter = {
                'include': trait_include_filters,
                'exclude': trait_exclude_filters,
            }
            filters['traits'] = trait_filter;
        }

        //entities section
        let entity_filter = {
            'include': [],
            'exclude': [] //for now there is no excludes
        }
        const entity_sections= this.element.querySelectorAll('.filter-section.entities-section')
        let at_least_one_select_all_is_unchecked = false;
        for (const entity_section of entity_sections) {
            const section_body = entity_section.querySelector('.card-body');
            const select_all = section_body.querySelector('input[type="checkbox"].select-all');
            if (!select_all.checked) {
                at_least_one_select_all_is_unchecked = true;
                break;
            }
        }
        entity_sections.forEach(entity_section => {
            const section_body = entity_section.querySelector('.card-body');
            if(at_least_one_select_all_is_unchecked) {
                const entity_checkboxes = section_body.querySelectorAll('input[type="checkbox"].select-one');
                let entity_include_filters = [];
                entity_checkboxes.forEach(checkbox => {
                    if (checkbox.checked) {
                        //here I want to check that entity_include_filters does not include checkbox.dataset.value
                        if(!entity_include_filters.includes(checkbox.dataset.value)) {
                            entity_include_filters.push(checkbox.dataset.value);
                        }
                    }
                });
                // here I want to add to entity_filter only if entity_include_filters is not empty
                if(entity_include_filters.length > 0) {
                    entity_filter['include'].push(...entity_include_filters);
                    //here I want to make all values in entity_filter['include'] array unique
                    entity_filter['include'] = [...new Set(entity_filter['include'])];
                }
            }

        });
        filters['entities'] = entity_filter;

        //documents section
        let documents_filter = {
            'include': [],
            'exclude': [] //for now there is no excludes
        }
        const document_section= this.element.querySelector('.filter-section.documents-section')
        at_least_one_select_all_is_unchecked = false;

        const documents_section_body = document_section.querySelector('.card-body');
        const select_all = documents_section_body.querySelector('input[type="checkbox"].select-all');
        if(!select_all.checked) {
            const documents_checkboxes = documents_section_body.querySelectorAll('input[type="checkbox"].select-one');
            documents_checkboxes.forEach(checkbox => {
                if (checkbox.checked) {
                    documents_filter['include'].push(checkbox.dataset.value);
                }
            });

        }
        filters['documents'] = documents_filter;

        const attr_dates_section = this.element.querySelector('.filter-section.attr-dates-section');
        if (attr_dates_section){
            const attr_dates_section_body = attr_dates_section.querySelector('.card-body');
            const min_dates = attr_dates_section_body.querySelectorAll('.attr-min');
            const max_dates = attr_dates_section_body.querySelectorAll('.attr-max');
            const date_ranges_filter = {}

            min_dates.forEach(min_date => {
                const date_range_name = min_date.dataset.name;
                const value = min_date.value;
                if(value && value !== 'no min') {
                    if(!date_ranges_filter[date_range_name]) {
                        date_ranges_filter[date_range_name] = {};
                    }
                    date_ranges_filter[date_range_name]['min'] = value;
                }
            });

            max_dates.forEach(max_date => {
                const date_range_name = max_date.dataset.name;
                const value = max_date.value;
                if(value && value !== 'no max') {
                    if(!date_ranges_filter[date_range_name]) {
                        date_ranges_filter[date_range_name] = {};
                    }
                    date_ranges_filter[date_range_name]['max'] = value;
                }
            });

            if(Object.keys(date_ranges_filter).length > 0) {
                filters['attributes'] = {'date_time': date_ranges_filter};
            }
        }


        return filters;
    }
}

$(() => {

  $('#sidebar-toggle').on('click', function () {
    $('#sidebar').toggleClass('closed')
  })
  
  function refreshTooltips() {
    $('[data-toggle="tooltip"]').tooltip({boundary: "window"})
  }
  $('body').bind('tooltips:refresh', function(){
    refreshTooltips()
  })
  refreshTooltips()

})

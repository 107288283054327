import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

    logout = (e) => {

        const url = e.target.dataset.sisenseurl + '/authentication/logout';

        Rails.ajax({
            url: url,
            type: 'GET',
            contentType: "text/plain",
            async: true,
            withCredentials: true,
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            success: () => {
                console.log('Success logging out from Sisense');
            },
            error: (error) => {
                console.error('Error logging out from Sisense:', error);
            }
        });

    }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element.querySelector("input[name='authenticity_token']") == null) {
      let form = this.element.form
      if (!form) {
        form = this.element.closest("form")
      }
      form.appendChild(this.authenticityToken)
    }
  }

  get authenticityToken() {
    const input = document.createElement("input")

    input.type = "hidden"
    input.name = "authenticity_token"
    input.autocomplete = "off"
    const authToken = document.querySelector('meta[name="csrf-token"]').content
    input.value = authToken

    return input
  }
}

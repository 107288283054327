export default class retainTabId {
    init = () => {
        const links = document.querySelectorAll('.nav-link')
        const hidden_input =  document.querySelectorAll('#tab')
        if (hidden_input.length > 0) {
            this.addTabLinksButtonHandlers(links, hidden_input[0])
        }
    }

    addTabLinksButtonHandlers = (buttons, hidden_input) => {
        if (buttons.length === 0) return;
        // Loop over each link on the page. A page could have multiple nested forms.
        buttons.forEach((link)=>{
            link.addEventListener('click', (e) => {
                this.handleTabClick(link,hidden_input, e)
            })
        })
    }

    handleTabClick = (link,hidden_input, e) => {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e || e.ctrlKey) return

        if(e.button === 0 && !(e.ctrlKey || e.metaKey) ) {
            // Prevent the browser from following the URL.
            // e.preventDefault()
            hidden_input.value = link.getAttribute('aria-controls')
        }
    }


}
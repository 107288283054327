import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import confirmationDialog from "../src/confirmationDialog"

export default class extends Controller {
  static targets = [ "indicatorCode", "indicatorName", "note" ]
  apply = () => {
    this.applyIndicator()
  }
  remove = () => {
    confirmationDialog(`Remove '${this.indicatorNameTarget.value}' Indication?`, self, this.removeIndicator)
  }
  applyIndicator = () => {
    Rails.ajax({
      type: "post",
      url: this.applyUrl(),
      data: `code=${this.indicatorCodeTarget.value}&note=${this.noteTarget.value}`,
      success: (data) => {
        this.applyResult(data)
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  removeIndicator = () => {
    Rails.ajax({
      type: "post",
      url: this.removeUrl(),
      data: `code=${this.indicatorCodeTarget.value}`,
      success: (data) => {
        this.applyResult(data)
      },
      error: (error) => {
        console.log(error)
      }
    })  
  }
  applyResult = (data) => {
    var use_action_cable = $("#claim").data('use-action-cable')
    var is_action_cable_connected = $("#claim").data('claim-channel-connected')

    if('Yes' == use_action_cable && 'Yes' == is_action_cable_connected){
      return;
    }

    if (data.indicator_html) {
      document.getElementById(this.container()).innerHTML = data.indicator_html
      document.body.dispatchEvent(new CustomEvent('tooltips:refresh'))
    }
    if (data.status && !!document.querySelector('#status')) { // keep this to support simple status text flip
      const status = document.querySelector('#status')
      status.innerText = data.status
      if (data.status_type) {
        status.classList.remove('badge-success');
        status.classList.remove('badge-warning');
        status.classList.remove('badge-danger');
        status.classList.add(`badge-${data.status_type}`)
      }
    }
    if (data.status_html && !!document.getElementById('status')) {
      document.getElementById('status').innerHTML = data.status_html
    }
    if (data.tasks_html && !!document.getElementById('tasks-tab-content')) {
      document.getElementById('tasks-tab-content').innerHTML = data.tasks_html
    }
    if (data.release_packages_html && !!document.getElementById('packages-tab-content')) {
      document.getElementById('packages-tab-content').innerHTML = data.release_packages_html
    }
    if (data.dynamic_form_html && !!document.getElementById('claim-data-panel')) {
      document.getElementById('claim-data-panel').innerHTML = data.dynamic_form_html
    }
  }
  applyUrl = () => {
    var use_action_cable = $("#claim").data('use-action-cable')
    var is_action_cable_connected = $("#claim").data('claim-channel-connected')
    var u = this.data.get('applyUrl')
    if('Yes' == use_action_cable && 'Yes' == is_action_cable_connected){
      u = u + "?render=false"
    }
    return u
  }
  removeUrl = () => {
    var use_action_cable = $("#claim").data('use-action-cable')
    var is_action_cable_connected = $("#claim").data('claim-channel-connected')
    var u = this.data.get('removeUrl')
    if('Yes' == use_action_cable && 'Yes' == is_action_cable_connected){
      u = u + "?render=false"
    }
    return u
  }
  container = () => {
    return this.data.get('container')
  }
}

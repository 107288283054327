import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "role", "resourceId", "resourceName", "modalBtn", "addBtn", "searchBtn" ]

  initialize() {
   this.setModal()
  }

  setModal() {
    this.addBtn = null
    const preserveForm = this.data.get('preserveForm') == 'true'
    if (this.hasAddBtnTarget) { this.addBtn = this.addBtnTarget }
    const modalBtn = this.modalBtnTarget
    const roleSelector = this.roleTarget
    const partial = roleSelector.selectedOptions[0].dataset.partial
    this.data.set('currentPartial', partial)
    if (partial) {
      modalBtn.setAttribute("data-target", `#${partial}`)
      modalBtn.disabled = false
      if (this.addBtn) { this.addBtn.disabled = true }
      if (!preserveForm) {
        this.resourceNameTarget.value = ""
        this.resourceIdTarget.value = "|"
      }
      if (this.hasSearchBtnTarget && (!this.resourceNameTarget.value || !this.resourceIdTarget.value)) { 
          this.searchBtnTarget.disabled = true 
      }
    }
    else {
      modalBtn.disabled = true
      if (this.hasSearchBtnTarget) { this.searchBtnTarget.disabled = false }
      if (this.addBtn) { this.addBtn.disabled = false }
      this.resourceNameTarget.value = "N/A"
      this.resourceIdTarget.value = ""
    }
  }
  openModal() {
    const modalBtn = this.modalBtnTarget
    modalBtn.click()
  }
  keypress(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.search()
    }
  }
  search() {
    const form = this.element
    const partial = this.data.get('currentPartial')
    // Only pull the form elements inside the current partial/modal
    // Since some modals have the same form attributes, this prevents collisions
    const resource_search_fields = form.querySelectorAll(`#${partial} input.resource-search, #${partial} select.resource-search`)
    let data = {}
    resource_search_fields.forEach((resource_search_field) => {
      data[resource_search_field.id] = resource_search_field.value
    })
    Rails.ajax({
      type: "post",
      url: this.data.get('url'),
      data: new URLSearchParams(data).toString(),
      success: (data) => {
        this.applyResults(data)
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  applyResults(data) {
    const partial = this.data.get('currentPartial')
    document.querySelector(`#${partial} .resource_results_wrapper`).outerHTML = data.resource_results_html
  }

  select(el) {
    const row = el.target.closest('tr')
    const polyMap = row.dataset.resourcePolymap
    const name = row.dataset.resourceName
    this.resourceIdTarget.value = polyMap
    this.resourceNameTarget.value = name
    if (this.addBtn) { this.addBtnTarget.disabled = false }
    if (this.hasSearchBtnTarget) { this.searchBtnTarget.disabled = false }
  }
}

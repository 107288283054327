export default class modifyLinkHrefOnSelect {
  constructor(selector = 'a[data-url-pattern') {
    this.selector = selector
  }
  init = () => { 
    const links = document.querySelectorAll(this.selector)
    this.setupLinks(links)
  }
  setupLinks = (links) => {
    links.forEach((link)=>{
      const urlPattern = link.dataset.urlPattern
      const urlComponents = urlPattern.split("/")
      urlComponents.forEach((urlComponent) => {
        if (urlComponent[0] == ":") {
          const select = document.getElementById(urlComponent.replace(":", ""))
          if (select && select.nodeName === 'SELECT') {
            this.setupInput(link, select, urlPattern)
          }
        }
      })
    })
  }
  setupInput = (link, select, urlPattern) => {
    select.addEventListener('change', (e) => {
      link.href = urlPattern.replace(":" + select.id, select.value)
    })
  }
}

export default class inplaceEditForm {
    init = () => {
        const edits = document.querySelectorAll('.btn-inplace-edit')
        this.addEditButtonHandlers(edits)

        const cancels = document.querySelectorAll('.btn-inplace-cancel')
        this.addCancelButtonHandlers(cancels)

        const submits = document.querySelectorAll('.btn-inplace-submit')
        const inputs = document.querySelectorAll('.inplace-input')

        this.addInputsEventHandlers(inputs)

        this.addInplaceControllsEventHandlers(inputs)
        this.addInplaceControllsEventHandlers(submits)
        this.addInplaceControllsEventHandlers(cancels)

    }

    addEditButtonHandlers = (buttons) => {
        if (buttons.length === 0) return;
        // Loop over each link on the page. A page could have multiple nested forms.
        buttons.forEach((link)=>{
            link.addEventListener('click', (e) => {
                this.handleEditClick(link, e)
            })
        })
    }

    handleEditClick = (link, e) => {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e || e.ctrlKey) return

        if(e.button === 0 && !(e.ctrlKey || e.metaKey) ) {
            // Prevent the browser from following the URL.
            e.preventDefault()

            let owner = Math.random()
            let cancel = link.closest('.inplace-container').querySelector('.btn-inplace-cancel')
            cancel.classList.remove("d-none");
            cancel['data-owner'] = owner

            let saveButton = link.closest('.inplace-container').querySelector('.btn-inplace-submit')
            if (saveButton) {
              saveButton.classList.remove("d-none")
            }
            link.closest('.inplace-container').querySelectorAll('.inplace-menu').forEach( (menu) => {menu.classList.add("d-none")})

            let input = link.closest('.inplace-container').querySelector('.inplace-input')
            input.readOnly = false
            input.focus()

        }
    }

    addCancelButtonHandlers = (buttons) => {
        if (buttons.length === 0) return;
        // Loop over each link on the page. A page could have multiple nested forms.
        buttons.forEach((button)=>{
            button.addEventListener('click', (e) => {
                this.handleCancelClick(button, e)
            })
        })
    }

    handleCancelClick = (button, e) => {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!button || !e || e.ctrlKey) return

        if(e.button === 0 && !(e.ctrlKey || e.metaKey) ) {
            inplaceEditForm.hideActionButton(button)
        }
    }

    static hideActionButton(button) {
        const inplaceContainer = button.closest('.inplace-container')
        if (!inplaceContainer) return
        let cancel = inplaceContainer.querySelector('.btn-inplace-cancel')
        cancel.classList.add("d-none");
        cancel['data-owner'] = ''

        let saveButton = inplaceContainer.querySelector('.btn-inplace-submit')
        if (saveButton) {
          saveButton.classList.add("d-none")
        }
        inplaceContainer.querySelectorAll('.inplace-menu').forEach( (menu) => {menu.classList.remove("d-none")});

        let input = inplaceContainer.querySelector('.inplace-input')
        input.readOnly = true
    }

    addInplaceControllsEventHandlers = (inputs) => {
        if (inputs.length === 0) return;
        // Loop over each link on the page. A page could have multiple nested forms.
        inputs.forEach((input)=>{
          input.addEventListener('blur', (e) => {
                this.handleInplaceControlsOnBlur(input, e)
            })
        })
    }

    handleInplaceControlsOnBlur = (element, e) => {
      // Stop the function from executing if a link or event were not passed into the function.
        if (!element || !e || e.ctrlKey) return
        let container = element.closest('.inplace-container')
        let input = container.querySelector('.inplace-input')
        let cancel = container.querySelector('.btn-inplace-cancel')
        let submit = container.querySelector('.btn-inplace-submit')

        // If there's no submit, save will be handled by the page
        if (submit) {
          setTimeout(() => {
              //only do something when input is still active
              if (input.readOnly === false){
                  let selected = document.activeElement;
                  if(selected && !(selected.isSameNode(input) || selected.isSameNode(cancel) || selected.isSameNode(submit) ) ){
                      // none of the controls are active -> submit
                      submit.click()
                  }
              }
          }, 200)
        }
        else {
          inplaceEditForm.hideActionButton(cancel)
        }
    }

    addInputsEventHandlers = (inputs) => {
        if (inputs.length === 0) return;
        // Loop over each link on the page. A page could have multiple nested forms.
        inputs.forEach((input)=>{
            input.addEventListener('keydown', (e) => {
                this.handleInputOnKeyPress(input, e)
            })
        })
    }

    handleInputOnKeyPress = (input, e) => {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!input || !e || e.ctrlKey) return
        if (e.key == 'Escape') { //escape is pressed
            input.closest('.inplace-container').querySelector('.btn-inplace-cancel').click()
        }
    }


}
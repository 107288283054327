// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as bootstrap from 'bootstrap';

// Turbo Streaming available, but disabled by default
// To enable on a given form:
// - Define a turbo-stream tag around some partial you want to replace
// - include data-turbo=true on that tag
// - add data-turbo=true to any buttons that expect a turbo-stream response (like Save)
// - Implement format.turbo-stream in the controller action with necessary replacement renderings
// - Other javascript actions happening on the page that don't listen for a turbo:load event may have 
//   to be refactored to react to that event and act accordingly when the DOM is replaced
//   Putting that javascript in a stimulus controller will acheive that 'for free'... see toasts_controller.js
// - Example to follow on firm.edit views and action
import "@hotwired/turbo-rails"
Turbo.session.drive = false

import './src/global'
import './src/rails_confirmation'

require("@rails/ujs").start()

// Begin - Admin LTE dependencies
// import 'admin-lte/build/js/AdminLTE';
import Layout from 'admin-lte/build/js/Layout'
import PushMenu from 'admin-lte/build/js/PushMenu'
import Treeview from 'admin-lte/build/js/Treeview'
import CardWidget from 'admin-lte/build/js/CardWidget'
import Chartkick from 'chartkick'
import Chart from 'chart.js'
import BootstrapSelect from 'bootstrap-select'
import ControlSidebar from 'admin-lte/build/js/ControlSidebar'
import ExpandableTable from 'admin-lte/build/js/ExpandableTable'

// End - Admin LTE dependencies

// import fileUpload from './src/fileUpload'
import modalSubmit from './src/modalSubmit'
import dependentDropdown from './src/dependentDropdown'
import disableOnChange from './src/disableOnChange'
import inplaceEditForm from './src/inplaceEditForm'
import modifyLinkHrefOnSelect from './src/modifyLinkHrefOnSelect'
import masks from './src/masks'
import multiFields from './src/multiFields'
import retainTabId from './src/retainTabId'
import clipboard from './src/clipboard'
import iFrameResizer from 'iframe-resizer/js/iframeResizer'

// const fileUploadInstance = new fileUpload()

$(() => {
  // fileUploadInstance.init()
  new modalSubmit().init()
  new dependentDropdown().init()
  new disableOnChange().init()
  new inplaceEditForm().init()
  new modifyLinkHrefOnSelect().init()
  new multiFields().init()
  new masks()
  new retainTabId().init()
  new clipboard().init() // !!!!!!!Obsolete use stimulus controller instead
  $('.control-sidebar.control-sidebar-open').ControlSidebar('show')
  $('.modal.auto-display').modal('show')
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    let url = new URL(location)
    const tab = e.currentTarget.id.replace('-tab', '')
    url.searchParams.set('tab', tab)
    window.history.pushState('data', tab, url)
  })

  // In order for this to work properly, this javascript needs to be added to the dashboards 'Script'
  // Click ellipsis on dashboard, and select Edit Script and paste this minified code into the script:
  // https://raw.githubusercontent.com/davidjbradshaw/iframe-resizer/master/js/iframeResizer.min.js

  $("iframe.dynamic-sizing").each((el) => {
    iFrameResizer({
      log: true,
      autoResize: false,
      heightCalculationMethod: 'lowestElement'
    }, el.id)
  })
})

window.handleLoadError = (e) => {
  const detail = JSON.parse(e.detail);
  console.log(detail.errorCode);
  console.log(detail.message);
}


// Setup Stimulus controllers
import "@hotwired/stimulus"
import "./controllers"

// document.addEventListener('DOMContentLoaded', function() {
//   const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
//   document.cookie = `system_dark_mode=${isDarkMode}; path=/`;
//   if(isDarkMode){
//     document.body.classList.add('dark-mode');
//     document.body.classList.remove('light-mode');
//   } else {
//     document.body.classList.add('light-mode');
//     document.body.classList.remove('dark-mode');
//   }
//
// });

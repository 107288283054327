import { Controller } from "@hotwired/stimulus"
import PSPDFKit from "pspdfkit";
import CrosslinkDictionary from "../src/crosslinkDictionary";
import {debounce} from 'lodash';

// Connects to data-controller="crosslink-editor"
export default class extends Controller {
  connect() {
    //this.changedCrosslinkDataFields = debounce(this.changedCrosslinkDataFields, 300);

    PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS = true;
    this.canvasContainer = this.element.querySelector('#pdf-canvas-container')
    //this.canvas = this.element.querySelector('#pdf-canvas')
    this.readonly = false
    this.licenseKey = this.canvasContainer.dataset.pspdfLicenseKey

    //theoretically this could have multiple selected crosslinks, but practivally we only have one at the moment
    //when the time comes to handle multiple selected crosslinks we would have to handle work product fields differently
    //when more than one will be selected in the future consider disabling the work product fields

    this.setSelectedAnnotation(null, false, false, false)

    this.listOfDocumentsPanel = this.element.querySelector('#documents-panel')
    this.pdfPanel = this.element.querySelector('#pdf-panel')
    this.documentsPanelLegend = this.element.querySelector('legend .dynamic_form_section_label')


    this.imagePanel = this.element.querySelector('#image-panel')
    this.titleSpinner = this.element.querySelector('#doc-spinner')
    this.search_ui = this.element.querySelector('#document_search')

    this.pdf = null
    this.fillColor = { r: 195, g: 224, b: 244 }
    this.borderColor = { r: 20, g: 155, b: 252 }
    this.fillUnselectedColor = 20

    this.document_id = null
    this.document_type = null

    this.page = 1

    this.download = this.element.querySelector('#download')
    this.imageDownload = this.element.querySelector('#image-download')

    // whenever forms gets activated it will tell us that it is active
    // at this point we will consider either claim or claim task to be a default active form
    // so we will be looking for that first, assuming we land on that form first
    // it may change later so we could change this default active form logic later
    this.crosslinkDictionary = new CrosslinkDictionary()
    this.activeCrosslinkForm = document.querySelector("form.documents-form");
    if(this.activeCrosslinkForm) {
      this.crosslinkDictionary.populate(this.activeCrosslinkForm.querySelectorAll('.crosslink'))
    }
    this.configureButtonHandlers()
    // dispatch status of the panel so whoever listens will do what they must
    this.dispatchDocumentPanelStatusEvent()
  }

  // BEGIN - Button Handlers
  configureButtonHandlers = () => {
    this.registerCloseButtonListener()
    this.registerShowCrosslinkEventListener()
    this.registerAddCrosslinkEventListener()
    this.registerCrosslinkFormActiveEventListener()
    this.registerRequestDocumentPanelStatusEventListener()
  }

  //BEGIN registering event listeners

  // need this event listener for the forms to request status of the panel
  registerRequestDocumentPanelStatusEventListener = () => {
    document.addEventListener('requestDocumentPanelStatus', (event) => {
      this.resetActiveCrosslinkForm(event.detail.crosslinkForm, this.document_id)
      //this.dispatchDocumentPanelStatusEvent()
    });
  }

  registerShowCrosslinkEventListener = () => {
    document.addEventListener('showCrosslink', (event) => {
      //here we need to make sure that our active crosslink form is the same as what is coming from the event
      //if not then we need to reinitialize the document panel and reregister crosslinks dictionary
      //after reinitialization we should be able to show the crosslink
      this.showCrosslinkEventHandler(event)
    });
  }

  registerAddCrosslinkEventListener = () => {
    document.addEventListener('addCrosslink', async (event) => {
      //stay on the same document if one is open
      await this.resetActiveCrosslinkForm(event.detail.crosslinkForm, this.document_id)
      this.addCrosslinkEventHandler(event)
    });
  }

  //when any form becomes active without selecting any particular crosslink it will just dispatch this even
  //and we will expect all crosslinks to be repopulated based on new active form
  registerCrosslinkFormActiveEventListener = () => {
    document.addEventListener('crosslinkFormActive', (event) => {
      //reset form in case we are trying to add crosslink from a different form
      //stay on the same document if one is open, may be we do need to close even the current document, not sure yet
      this.resetActiveCrosslinkForm(event.detail.crosslinkForm, this.document_id)
    });
  }

  //TODO: this needs to be refactored into proper stimulus method
  registerCloseButtonListener = () => {
    const close = this.element.querySelector('#close')
    if (close) {
      close.addEventListener('click', () => {
        this.unloadPDF()
        this.pdfPanel.classList.add("d-none")
        this.listOfDocumentsPanel.classList.remove("d-none")
        this.documentsPanelLegend.innerText =  this.documentsPanelLegend.dataset.title

        const reEvent = new CustomEvent("releaseRealEstate", {
          detail: {
            selector: '#right-sidebar-panel',
            target: this.element}
        })

        document.dispatchEvent(reEvent)

        //we have closed the document so we need to reset dictionary
        this.resetActiveCrosslinkForm(this.activeCrosslinkForm , null)
      })
    }
    const imageClose = this.element.querySelector('#image-close')
    if (imageClose) {
      imageClose.addEventListener('click', () => {

        this.listOfDocumentsPanel.classList.remove("d-none")
        this.imagePanel.classList.add("d-none")
        this.documentsPanelLegend.innerText =  this.documentsPanelLegend.dataset.title

      })
    }
  }

  // END - Listener Registration

  showCrosslinkEventHandler = async (event) => {
    const crosslink = JSON.parse(event.detail.input.value)
    const document_id = parseInt(crosslink.document_highlight.document_id);
    //here I want to remember current focused element
    const currentFocusedElement = document.activeElement

    await this.resetActiveCrosslinkForm(event.detail.crosslinkForm, document_id)

    const document_element =  this.element.querySelector('#' + this.getDocumentDomId(crosslink.document_highlight))
    const crosslink_store = this.crosslinkDictionary.get_crosslink_store_by_crosslink_id(crosslink.crosslink_id)

    //only set selected once document's page is open
    this.openDocumentPage(document_element, crosslink.document_highlight.page).then(async () =>{
      //is used when drawing crosslinks
      await this.setSelectedAnnotation(crosslink_store, true, event.detail.scroll_into_view, true)
      if(currentFocusedElement){
        currentFocusedElement.focus()
      }
    })
  }

  addCrosslinkEventHandler = (event) => {


    let crosslink = event.detail.crosslink
    crosslink.document_highlight.document_id = this.document_id
    crosslink.document_highlight.document_type = this.document_type
    crosslink.document_highlight.page = this.currentPage()+1

    //overwrite whatever is in the input with enriched crosslink
    //we kind of already have a crosslink container here at our fingertips so we could just serialize the crosslink right here
    //but we could have just dispatched the event crosslinkChanged and it would have done the same thing
    let input = event.detail.input
    input.value = JSON.stringify(crosslink)

    //batch id is used for orphan management
    const batchId = Date.now().toString();

    let crosslink_store = {
      batch_id: batchId,
      crosslink: crosslink,
      inout_id: input.id,
      input: input
    }

    this.crosslinkDictionary.add(crosslink_store)
    const currentFocusedElement = document.activeElement

    this.drawCrosslink(crosslink_store).then(async () => {
      await this.setSelectedAnnotation(crosslink_store, true, event.detail.scroll_into_view, true)
      if(currentFocusedElement){
        currentFocusedElement.focus()
      }
    });

  }

  resetActiveCrosslinkForm = async (crosslinkForm, document_id) => {
    // Reset a selection since we are resetting the active crosslink form
    await this.setSelectedAnnotation(null, false, false, false);

    if (crosslinkForm === null || this.activeCrosslinkForm !== crosslinkForm) {
      // Reset id to int just in case
      document_id = parseInt(document_id);

      this.activeCrosslinkForm = null;
      // When we are dealing with the same document, we do not need to reload it, just clean it up
      // Otherwise, just close the document and open a new one

      if (this.pdf && document_id && this.document_id === document_id) {
        // Copy to old dictionary old crosslinks for safe async pdf cleanup
        const oldDictionary = this.crosslinkDictionary;
        this.crosslinkDictionary = new CrosslinkDictionary();

        for (let page_number = 0; page_number < this.pdf.totalPageCount; page_number++) {
          const annotations = await this.pdf.getAnnotations(page_number);
          for (const annotation of annotations) {

            if(!annotation.customData){
              await this.pdf.delete(annotation);
              continue;
            }

            const crosslink_store = oldDictionary.get_crosslink_store(annotation.customData.document_id, annotation.customData.page, annotation.customData.crosslink_id);
            // Using batchId to differentiate orphaned crosslinks from the new ones
            // Need to do this due to the async nature of these PSPDFKit calls
            if (crosslink_store && annotation.customData.batch_id === crosslink_store.batch_id) {
              await this.pdf.delete(annotation);
            }
          }
        }
      } else {
        // Reset document information so it will be forced to reload
        this.crosslinkDictionary = new CrosslinkDictionary();
        await this.unloadPDF(); // Ensure this is an async function if it performs asynchronous operations
        this.pdf = null;

        if (this.documentsPanelLegend) {
          this.documentsPanelLegend.innerText = this.documentsPanelLegend.dataset.title;
        }
      }

      // Now set the new active crosslink form
      this.activeCrosslinkForm = crosslinkForm;

      // Go through all existing crosslinks within the active form and add them to the dictionary
      // but only when form is not empty
      if (this.activeCrosslinkForm) {
        const crosslink_inputs = this.activeCrosslinkForm.querySelectorAll('.crosslink');
        this.crosslinkDictionary = new CrosslinkDictionary();
        this.crosslinkDictionary.populate(crosslink_inputs);

        // This will make duplicate boxes painted
        // If planning to uncomment, make sure duplicate boxes are taken care of
        // if(this.document_id){
        //   this.finishPageLoad(this.currentPage() + 1)
        // }
      }

      // Need to dispatch this event so whoever is listening will know that the document panel is getting its document changed
      this.dispatchDocumentPanelStatusEvent();
    } else if (crosslinkForm && this.activeCrosslinkForm === crosslinkForm && (!document_id || this.document_id !== document_id)) {
      this.crosslinkDictionary = new CrosslinkDictionary();
      const crosslink_inputs = this.activeCrosslinkForm.querySelectorAll('.crosslink');
      this.crosslinkDictionary = new CrosslinkDictionary();
      this.crosslinkDictionary.populate(crosslink_inputs);
      this.dispatchDocumentPanelStatusEvent();
    }
  }

  //jump is used to jump to the annotation when we send an event from outside of the pdf
  setSelectedAnnotation = async (crosslink_store, jump, scroll_into_view, select_annotation_in_pdf) => {
    //clean up selection in pdf
    if(this.pdf) {

      for (let page_number = 0; page_number < this.pdf.totalPageCount; page_number++) {
        const annotations = await this.pdf.getAnnotations(page_number);

        for (const annotation of annotations) {
          if (annotation.customData && annotation.customData.crosslink_id) {
            if (crosslink_store &&
                crosslink_store.crosslink.crosslink_id === annotation.customData.crosslink_id &&
                annotation.customData.batch_id === crosslink_store.batch_id) {

              let fillColor = new PSPDFKit.Color(annotation.customData.fill_color);
              const updated = annotation.set("fillColor", fillColor);
              await this.pdf.update(updated);

              if (select_annotation_in_pdf) {
                await this.pdf.setSelectedAnnotation(annotation);
              }

              if (annotation.customData.disabled) {
                // This is the trick to focus on the annotation overlay
                const annotation_element = this.pdf.contentDocument.querySelector(`.PSPDFKit-Annotation[data-annotation-id="${annotation.id}"]`);
                if (annotation_element) {
                  annotation_element.setAttribute("data-focusvisible-polyfill", true);
                  annotation_element.focus();
                }
              }

              if (jump) {
                await this.pdf.jumpToRect(annotation.pageIndex, annotation.boundingBox);
              }

            } else {
              const updated = annotation.set("fillColor", new PSPDFKit.Color(annotation.customData.fill_color).lighter(this.fillUnselectedColor));
              await this.pdf.update(updated);
            }
          }
        }
      }

      // Dispatch custom event after all annotations are processed
      let eventPayload = {
        crosslinkForm: this.activeCrosslinkForm,
        input_id: null,
        input: null,
        crosslink: null,
        scroll_into_view: scroll_into_view,
        focus: jump
      };

      if (crosslink_store  ) {
        eventPayload.input_id = crosslink_store.input_id;
        eventPayload.input = crosslink_store.input;
        eventPayload.crosslink = crosslink_store.crosslink;
      }

      let crosslinkSelectionChanged = new CustomEvent("crosslinkSelectionChanged", { detail: eventPayload });
      document.dispatchEvent(crosslinkSelectionChanged);
    }


  }


  // we should dispatch this status every time the document panel opens or closes a new document
  dispatchDocumentPanelStatusEvent = () => {
    //may be we will add more things in here
    const event = new CustomEvent("documentPanelStatus", {
      detail:{
        crosslinkForm: this.activeCrosslinkForm, // tell em our active form and let em decide what to do with it
        documentIsOpen: this.pdf ? true : false
      }
    })
    document.dispatchEvent(event)
  }


  setDownloadButton = (url, title) => {
    this.download.href = url
    this.download.download = title
    this.download.title = title
  }

  setImageDownloadButton = (url, title) => {
    this.imageDownload.href = url
    this.imageDownload.download = title
    this.imageDownload.title = title
  }

  currentPage = () => {
    if (this.pdf) {
      return this.pdf.viewState.currentPageIndex
    }
  }

  pdfDocumentShow = async (e) => {
    // Stop the function from executing if a link or event were not passed into the function.
    let document_element = e.currentTarget
    // Prevent the browser from following the URL.
    e.preventDefault()

    //reset active crosslink form
    await this.resetActiveCrosslinkForm(this.activeCrosslinkForm, document_element.dataset.documentId)

    //just let the document open async
    this.openDocumentPage(document_element,1)

  }

  imageDocumentShow = (e) => {
    // Stop the function from executing if a link or event were not passed into the function.
    let button = e.currentTarget
    // Prevent the browser from following the URL.
    e.preventDefault()

    const url = button.dataset.url
    const title = button.dataset.title
    this.imagePanel.querySelector('img').src = url
    this.setImageDownloadButton(url, title)
    this.imagePanel.classList.remove("d-none")
    this.listOfDocumentsPanel.classList.add("d-none")
    this.documentsPanelLegend.innerText =  button.dataset.title
  }

  registerAnnotationEventListeners = () => {
    //addEventListener
    this.pdf.addEventListener("annotations.transform", this.annotationTransformEventListener);
    this.pdf.addEventListener("annotationSelection.change", this.annotationSelectionChangeEventListener);
    this.pdf.addEventListener("annotations.delete", this.annotationDeleteEventListener);
    this.pdf.addEventListener("annotations.create", this.annotationCreateEventListener);

    this.pdf.contentDocument.addEventListener("click", async (event) => {
      await this.annotationReadOnlyFocusListener(event);
    });
  }

  annotationReadOnlyFocusListener = async (event) => {
    if (event.target.dataset.annotationId != null) {

      const annotationId = event.target.dataset.annotationId;
      const annotations = await this.pdf.getAnnotations(this.pdf.viewState.currentPageIndex);
      // Find the annotation with the matching id
      const annotation = annotations.find(a => a.id === annotationId);

      if (annotation && annotation.customData && annotation.customData.crosslink_id) {
        const crosslink_store = this.crosslinkDictionary.get_crosslink_store(annotation.customData.document_id, annotation.customData.page, annotation.customData.crosslink_id)
        if(crosslink_store && annotation.customData.batch_id === crosslink_store.batch_id ){
          // we only want to do this if we have live and readonly crosslink
            if(annotation.customData.disabled){
              this.setSelectedAnnotation(crosslink_store, false, true, true)
            }
        }
      }
    }
  }
  annotationTransformEventListener = (event) => {
    let annotation = event.annotation
    if (annotation.customData) {
      const pageInfo = this.pdf.pageInfoForIndex(annotation.pageIndex)

      let crosslink_store = this.crosslinkDictionary.get_crosslink_store(annotation.customData.document_id, annotation.customData.page, annotation.customData.crosslink_id)

      // if we found orphan just quit let the orphan do whatever it needs
      if(crosslink_store && annotation.customData.batch_id === crosslink_store.batch_id) {
        crosslink_store.crosslink.document_highlight.left = annotation.boundingBox.left / pageInfo.width
        crosslink_store.crosslink.document_highlight.top = annotation.boundingBox.top / pageInfo.height
        crosslink_store.crosslink.document_highlight.width = annotation.boundingBox.width / pageInfo.width
        crosslink_store.crosslink.document_highlight.height = annotation.boundingBox.height / pageInfo.height

        let eventPayload = {
          crosslinkForm: this.activeCrosslinkForm,
          input_id: crosslink_store.input_id,
          input: crosslink_store.input,
          crosslink: crosslink_store.crosslink
        }

        let crosslinkChanged = new CustomEvent("crosslinkChanged", {detail: eventPayload})
        document.dispatchEvent(crosslinkChanged)
      }
    }
  }
  annotationSelectionChangeEventListener = (annotation) => {
    //TODO: need to trigger a handler even when there is no annotation so whoever is listening may redraw halo
    if (annotation && annotation.customData && annotation.customData.crosslink_id) {
      const crosslink_store = this.crosslinkDictionary.get_crosslink_store(annotation.customData.document_id, annotation.customData.page, annotation.customData.crosslink_id)
      // if we found orphan just quit let the orphan do whatever it needs
      if(crosslink_store && annotation.customData.batch_id === crosslink_store.batch_id) {
        // if we already have same annotation selected, there is no need to trigger duplicate event and do duplicate selection
        // this happens when crosslink is selected via setSelectedAnnotation that comes from other forms
        // so under those conditions we do not want a second round trip
        if(!(this.selectedCrosslinkStore && crosslink_store && this.selectedCrosslinkStore === crosslink_store)){
          this.setSelectedAnnotation(crosslink_store, false, true, false)
        }
      }
    }
  }
  annotationDeleteEventListener = (annotations) => {
    annotations.forEach( (annotation) => {
      if (annotation.customData) {
        const crosslink_store = this.crosslinkDictionary.get_crosslink_store(annotation.customData.document_id, annotation.customData.page, annotation.customData.crosslink_id)

        // if we found orphan just quit let the orphan do whatever it needs
        if(crosslink_store && annotation.customData.batch_id === crosslink_store.batch_id ) {
          let eventPayload = {
            crosslinkForm: this.activeCrosslinkForm,
            input_id: crosslink_store.input_id,
            input: crosslink_store.input,
            crosslink: crosslink_store.crosslink
          }
          this.crosslinkDictionary.remove(crosslink_store)

          this.setSelectedAnnotation(null, false, false, false)

          let crosslinkDeleted = new CustomEvent("crosslinkDeleted", {detail: eventPayload})
          document.dispatchEvent(crosslinkDeleted)
        }
      }
    });
  }
  annotationCreateEventListener = (annotations) => {
    annotations.forEach(annotation => {
      if (annotation.customData) {
        let crosslink_store = this.crosslinkDictionary.get_crosslink_store(annotation.customData.document_id, annotation.customData.page, annotation.customData.crosslink_id)

        // if we found orphan just quit let the orphan do whatever it needs
        if(crosslink_store && annotation.customData.batch_id === crosslink_store.batch_id) {
          crosslink_store.annotation_created = true
        }
      }

    });
  }

  showSpinner = () => {
    this.titleSpinner.classList.remove("d-none")
  }

  hideSpinner = () => {
    this.titleSpinner.classList.add("d-none")
  }

  unloadPDF = () => {
    if (this.pdf) {
      PSPDFKit.unload("#pdf-canvas-container")
      this.pdf = null
    }
    this.document_id = null
    this.document_type = null
  }

  openDocumentPage = async (document_element, page) => {
    this.showSpinner()

    this.pdfPanel.classList.remove("d-none")
    this.listOfDocumentsPanel.classList.add("d-none")
    this.documentsPanelLegend.innerText = document_element.dataset.title

    const reEvent = new CustomEvent("requestRealEstate", {
      detail: {
        selector: '#right-sidebar-panel',
        target: this.element}
    })
    document.dispatchEvent(reEvent)

    const url = document_element.dataset.url
    const title = document_element.dataset.title

    //reset id to int just in case
    let document_id = parseInt(document_element.dataset.documentId)
    page = page ? page : 1

    if (this.document_id !== document_id) { // Opening a different document
      this.unloadPDF()

      this.setDownloadButton(url, title)

      this.document_id = document_id
      this.document_type = document_element.dataset.documentType

      const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`
      const cnt = this.element.querySelector('#pdf-canvas-container')
      const instance = await PSPDFKit.load({
        toolbarItems: this.getMainToolbarItems(),
        isEditableAnnotation: (annotation) => {
          if (annotation.customData) {
            return !annotation.customData.disabled
          }
          return false
        },
        annotationToolbarItems: this.getAnnotationToolbarItems, // function evaluates depending on type of annotation (currently Rectangle only)
        disableWebAssemblyStreaming: true, // Fix mime-type for wasm to enable this
        baseUrl,
        initialViewState: this.getInitialViewState(),
        container: "#pdf-canvas-container",
        document: url,
        licenseKey: this.licenseKey,
        customRenderers: {
              Annotation({annotation}) {
                if(annotation.customData && annotation.customData.disabled){
                  // this is an overlay over the annotation where we hookup click event
                  // it will then be handled by annotationReadOnlyFocusListener
                  const node = document.createElement("div");
                  node.dataset.annotationId = annotation.id;
                  node.dataset.crosslinkId = annotation.customData.crosslink_id; // need this for testing
                  node.classList.add("PSPDFKit-Annotation-ReadOnly-Overlay"); // this is out own name to be used in testing
                  node.style.cssText = `
                    width: 100%;
                    height: 100%;
                    pointer-events: all;
                  `;
                  return {
                    node,
                    append: true
                  };
                }
              }

        }
      })

      this.pdf = instance

      await this.pdf.setToolbarItems((items) =>
          items.map((item) => {
            if (item.type === "sidebar-annotations") {
              item.title = 'Crosslinks';
            }
            return item;
          })
      );

      this.registerAnnotationEventListeners()
      await this.finishPageLoad(page)

      // let everyone know that status of a panel changed
      this.dispatchDocumentPanelStatusEvent();

    } else { // Set selected crosslink in the current document
      await this.finishPageLoad(page)
    }
  }

  finishPageLoad = async (page) => {
    if(this.pdf) {
      await this.pdf.setViewState(state => state.set("currentPageIndex", page-1));
      await this.drawCrosslinks()
    }
    this.hideSpinner()
  }

  getInitialViewState = () => {
    return new PSPDFKit.ViewState({
      readOnly: this.readonly,
    })
  }

  getMainToolbarItems = () => {
    const allowedToolbarItems = ['sidebar-thumbnails', 'pager', 'pan', 'zoom-out', 'zoom-in', 'zoom-mode', 'spacer', 'search']
    if (this.canvasContainer.dataset.allowAnnotatedDocumentExport === 'true') {
      allowedToolbarItems.push('export-pdf')
    }
    var filteredItems = PSPDFKit.defaultToolbarItems.filter( (item) => allowedToolbarItems.includes(item.type) )
    filteredItems.push({ type: "layout-config", mediaQueries: ["all"] });
    return filteredItems
  }

  getAnnotationToolbarItems = (annotation, {defaultAnnotationToolbarItems}) => {
    const allowedAnnotationToolbarItems = ['delete']
    if (annotation instanceof PSPDFKit.Annotations.RectangleAnnotation) {
      return defaultAnnotationToolbarItems.filter((item) => allowedAnnotationToolbarItems.includes(item.type))
    }
    return defaultAnnotationToolbarItems
  }

  drawCrosslink = (crosslink_store) => {
    //get page info
    const pageInfo = this.pdf.pageInfoForIndex(crosslink_store.crosslink.document_highlight.page-1)
    const fillColor = crosslink_store.crosslink.document_highlight.fill_color ? crosslink_store.crosslink.document_highlight.fill_color : this.fillColor
    const borderColor =  crosslink_store.crosslink.document_highlight.border_color ? crosslink_store.crosslink.document_highlight.border_color : this.borderColor
    const annotation = new PSPDFKit.Annotations.RectangleAnnotation({
      pageIndex: crosslink_store.crosslink.document_highlight.page-1,
      strokeWidth: 3,
      opacity: 0.35,
      strokeColor:  new PSPDFKit.Color(borderColor),
      fillColor: new PSPDFKit.Color(fillColor).lighter(this.fillUnselectedColor), // Example: semi-transparent yellow
      boundingBox: new PSPDFKit.Geometry.Rect({
        left: pageInfo.width * crosslink_store.crosslink.document_highlight.left,
        top: pageInfo.height * crosslink_store.crosslink.document_highlight.top,
        width: pageInfo.width * crosslink_store.crosslink.document_highlight.width,
        height: pageInfo.height * crosslink_store.crosslink.document_highlight.height
      }),
      customData: {
        batch_id: crosslink_store.batch_id, // need to store a batch_id so we can handle orphaned crosslinks and annotations when we cleanup pdf
        document_id: crosslink_store.crosslink.document_highlight.document_id,
        page: crosslink_store.crosslink.document_highlight.page,
        crosslink_id: crosslink_store.crosslink.crosslink_id,
        disabled: crosslink_store.input.dataset.disabled === 'true',
        fill_color: fillColor
      }
    });

    return this.pdf.create(annotation);

  }

  //making this method async so we can handle promise async
  drawCrosslinks = async () => {
    const totalPages = this.pdf.totalPageCount
    for (let page=0; page<totalPages; page++) {
      //get all stores for this page
      let crosslink_stores = this.crosslinkDictionary.get_crosslink_stores_for(this.document_id, page+1)
      if (crosslink_stores) {
        for (let crosslink_id in crosslink_stores) {
          let crosslink_store = crosslink_stores[crosslink_id]
          // no need to create since its already been created
          if (crosslink_store.annotation_created) {
            continue
          }
          //we can only set selected crosslink after it was drawn so we handle promise async
          await this.drawCrosslink(crosslink_store)
        }
      }
    }
  }

  getDocumentDomId = (document_highlight) => {
    return document_highlight.document_type.replace(/\.?([A-Z]+)/g, function (x, y){return "_" + y.toLowerCase()}).replace(/^_/, "") + '_' + document_highlight.document_id
  }


}

import { Controller } from "@hotwired/stimulus"

import { createPopper } from '@popperjs/core';

export default class extends Controller {
  connect = () => {
    this.setupTooltips()
  }
  setupTooltips = () => {
    const tooltips = document.querySelectorAll('.customTooltip')
    const showEvents = ['mouseenter', 'focus']
    const hideEvents = ['mouseleave', 'blur']

    tooltips.forEach((tooltipTrigger) => {
      const tooltipTarget = document.getElementById(tooltipTrigger.dataset.target)
      const popperInstance = createPopper(tooltipTrigger, tooltipTarget, {
        placement: 'right'
      })
      showEvents.forEach((event) => {
        tooltipTrigger.addEventListener(event, () => {this.show(popperInstance, tooltipTarget)})
      })
      hideEvents.forEach((event) => {
        tooltipTrigger.addEventListener(event, () => {this.hide(popperInstance, tooltipTarget)})
      })
    })
  }
  show = (popperInstance, tooltip) => {
    tooltip.setAttribute('data-show', '');
    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    popperInstance.update();
  }
  
  hide = (popperInstance, tooltip) =>{
    tooltip.removeAttribute('data-show');
  }

}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import Inputmask from "inputmask";
import he from 'he'

// Connects to data-controller="bulk-set-attributes"
export default class extends Controller {
  static targets = [ "attributeField", "valueField", "attributeSet" ]

  connect() {
    this.path = this.element.dataset.getFieldPath
  }

  setup(event) {
    const valueField = this.valueFieldTarget
    valueField.querySelector('input,select,textarea,checkbox').disabled = true
    const attribute = event.target.value
    this.getField(attribute)
  }

  getField(attribute) {
    Rails.ajax({
      type: "get",
      url: this.path,
      data: `bulk_update_operation[attribute]=${attribute}`,
      success: (data) => {
        this.valueFieldTarget.innerHTML = data.field_html
        const ig = this.valueFieldTarget.querySelector('.input-group')
        const fg = this.valueFieldTarget.querySelector('.form-group')
        const input = this.valueFieldTarget.querySelector('input,select,textarea,checkbox')
        fg.classList.remove(...fg.classList)
        fg.classList.add('form-group')
        ig.classList.remove(...ig.classList)
        ig.classList.add('input-group')
        input.classList.remove('form-control-sm')
        input.name = 'attribute_value'
        input.id = 'attribute_value'
        fg.insertAdjacentHTML('afterbegin', `<label for='bulk_update_operation_attribute_value'>Attribute Value</label>`)
        Inputmask().mask(fg.querySelectorAll(".date_mask"));
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  addAttribute(event) {
    const attribute = this.attributeFieldTarget.value
    const attributeDisplay = this.attributeFieldTarget.selectedOptions[0].text
    const valueInput = this.valueFieldTarget.querySelector('input,select,textarea,checkbox')
    const value = valueInput.value
    let valueDisplay = value
    if (this.valueInput instanceof HTMLSelectElement) {
      valueDisplay = valueInput.selectedOptions[0].text
    }
    const none = this.attributeSetTarget.querySelector('#none')
    if (none) {
      none.classList.add('d-none')
    }
    this.attributeSetTarget.insertAdjacentHTML('beforeend', `<div class='bg-white border p-1 mb-1 border-primary'><b>${attributeDisplay}</b>: ${valueDisplay}</div>`)
    let attrJSON = {attribute: attribute, value: value, label: attributeDisplay, value_display: valueDisplay}
    this.attributeSetTarget.insertAdjacentHTML('beforeend', `<input type='hidden' id='bulk_update_operation_attributes' name='bulk_update_operation[attributes][]' value='${he.encode(JSON.stringify(attrJSON))}'>`)

  }
}

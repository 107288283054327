import { Controller } from "@hotwired/stimulus"

import _ from 'lodash';

export default class extends Controller {
  alignAll = function() {
    this.comparePairs().forEach((className) => {
      const toAlign = this.element.querySelectorAll(className)
      if (toAlign.length == 2) {
        const left = $(toAlign[0])
        const right = $(toAlign[1])
        if (left.outerHeight() > right.outerHeight()) {
          right.css('height', `${left.outerHeight()}px`)
        }
        else {
          left.css('height', `${right.outerHeight()}px`)
        }
      }
    })
  }
  comparePairs () {
    let pairs = []
    const comparables = this.element.querySelectorAll('.compare')
    comparables.forEach((comparable) => {
      const classes = comparable.classList
      const compareClass = _.filter(classes, function(c){ return _.startsWith(c, "compare_")})[0]
      if (compareClass && !_.has(pairs, `.${compareClass}`)) {
        pairs.push(`.${compareClass}`)
      }
    })
    return pairs
  }
  connect () {
    this.alignAll()
  }
}

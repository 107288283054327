import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {debounce} from 'lodash';
import scrollIntoView from "scroll-into-view-if-needed";

export default class AiSuggestionsController extends Controller {

  connect() {
    this.performSearch = debounce(this.performSearch, 100);

    //here we need to find closest element with data-controller="tabs"
    this.tabsContainer = this.element.closest('[data-controller="tabs"]')

    // bind it here to this instance, but we will use it in lazy loading
    this.activeSuggestionCardUnlockEventHandler = this.activeSuggestionCardUnlockEventHandler.bind(this);

    this.aiPanel = this.element.querySelector("#data-ai-attribute-suggestions-panel")
    if(this.aiPanel){
      this.aiPanel.addEventListener('activeSuggestionCardUnlock', this.activeSuggestionCardUnlockEventHandler);
    }


    // !!!!!!!!!!!!!!!!!!!!
    // we need to bind this listeners with an instance of a controller so we can disconnect it later
    // and garbage collector will be able to cleanup if the form element gets removed from html
    // !!!!!!!!!!!!!!!!!!!!
    this.searchInDocumentsEventHandler = this.searchInDocumentsEventHandler.bind(this);
    document.addEventListener('searchInDocumentsEvent', this.searchInDocumentsEventHandler);

    this.filterCardsEventHandler = this.filterCardsEventHandler.bind(this);
    document.addEventListener('filterCardsEvent', this.filterCardsEventHandler);

    this.estimateCardsEventHandler = this.estimateCardsEventHandler.bind(this);
    document.addEventListener('estimateCardsEvent', this.estimateCardsEventHandler);

    this.tabDeactivatedEventHandler = this.tabDeactivatedEventHandler.bind(this);
    this.tabsContainer.addEventListener(this.element.dataset.tabName +  'TabDeactivatedEvent', this.tabDeactivatedEventHandler);

    this.tabActivatedEventHandler = this.tabActivatedEventHandler.bind(this);
    this.tabsContainer.addEventListener(this.element.dataset.tabName +  'TabActivatedEvent', this.tabActivatedEventHandler);

    this.element.classList.add('initialized')
  }

  //!!!!!!!!!!!!!!
  // we need to cleanup event listeners when we remove this form from html, otherwise we will have memory leaks
  //!!!!!!!!!!!!!!
  disconnect() {
    document.removeEventListener('searchInDocumentsEvent', this.searchInDocumentsEventHandler);
    document.removeEventListener('filterCardsEvent', this.filterCardsEventHandler);
    document.removeEventListener('estimateCardsEvent', this.estimateCardsEventHandler);

    this.tabsContainer.removeEventListener(this.element.dataset.tabName +  'TabDeactivatedEvent', this.tabDeactivatedEventHandler);
    this.tabsContainer.removeEventListener(this.element.dataset.tabName +  'TabActivatedEvent', this.tabActivatedEventHandler);

    this.disconnect_panel_events()
  }

  disconnect_panel_events(){
    if(this.activeSuggestionCardUnlockEventHandler && this.aiPanel) {
      this.aiPanel.removeEventListener('activeSuggestionCardUnlock', this.activeSuggestionCardUnlockEventHandler);
    }
  }

  activeSuggestionCardUnlockEventHandler(event) {
    if( event.detail.card.dataset.crosslinked === "true" && event.detail.saved === true){

      let attributesList = this.element.querySelector("#attribute_id")
      const url = attributesList.dataset.refreshUrl;
      Rails.ajax({
        type: "get",
        url: url,
        success: (data) => {

          const selectedOption = attributesList.options[attributesList.selectedIndex];
          let selectedValue = null;
          if(selectedOption){
            selectedValue = selectedOption.value;
          }

          const attributesListContainer = this.element.querySelector('#attribute-list-container')
          attributesListContainer.innerHTML = data.html_data

          //here we want to reset the object to the new element
          attributesList = attributesListContainer.querySelector("#attribute_id")
          if(selectedValue){
              const newSelectedOption = attributesList.querySelector(`option[value="${selectedValue}"]`);
              if(newSelectedOption){
                  newSelectedOption.selected = true;
              }else{
                attributesList.selectedIndex = 0;
                  this.suggestionAttributeChanged({target: attributesList});
              }
          }

        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }

  searchInDocumentsEventHandler(event) {
    this.tabsContainer.dispatchEvent(new CustomEvent('tabActivateEvent', { detail: { tabName: this.element.dataset.tabName } }))
    this.lazyLoadContent(event)
  }

  filterCardsEventHandler(event) {
    let attributesList = this.element.querySelector("#attribute_id")
    const selectedOption = attributesList.options[attributesList.selectedIndex];
    const baseUrl = selectedOption.dataset.indexUrl;

    if(!event.detail.keep_ai_search_input){
      const searchInput = this.element.querySelector('#ai_search_input');
      searchInput.value = '';
    }

    let url = this.calculateFilterUrlParams(event, baseUrl)
    this.updateSuggestionCardList(url, event.detail.focus_element_selector)
    // here perhaps we should let the first card to be focused?
  }

  estimateCardsEventHandler(event) {
    let attributesList = this.element.querySelector("#attribute_id")
    const selectedOption = attributesList.options[attributesList.selectedIndex];
    const baseUrl = selectedOption.dataset.estimateUrl;

    //enrich the event with next_page if it is not there
    if(!event.detail.next_page){
      const filterContainer = this.element.querySelector('#sort-and-filters-row');
      const next_page = filterContainer.dataset.nextPage;
      event.detail.next_page = next_page;
    }

    let url = this.calculateFilterUrlParams(event, baseUrl)

    Rails.ajax({
      type: "get",
      url: url,
      contentType: "application/json",
      success: (data) => {
        const estimatedResultEvent = new CustomEvent('estimatedCardsResultEvent', { detail: {available_filters: data} });
        document.dispatchEvent(estimatedResultEvent);
      },
      error: (error) => {
        console.log(error)
      }
    });
  }

  calculateFilterUrlParams(event, baseUrl) {
    let params = {}

    if(event.detail.filters){
      Object.assign(params, event.detail.filters)
    }

    if(event.detail.sort) {
      params['sort'] = event.detail.sort
    } else {
      const sortRow = this.element.querySelector('#sort-and-filters-row');
      if(sortRow) {
        // here I want to check if sortRow.dataset.currentSort has anything
        if(sortRow.dataset.currentSort){
          params['sort'] = [{[sortRow.dataset.currentSort]: sortRow.dataset.currentDirection}];
        }
      }
    }

    if(event.detail.next_page){
      params['next_page'] = event.detail.next_page
    }

    if(event.detail.is_manual_search){
      params['is_manual_search'] = true
    }

    let queryString = this.serializeForRails(params);
    let url = `${baseUrl}?${queryString}`;

    return url;
  }

  clearFilter(event) {
    const filterContainer = this.element.querySelector('#sort-and-filters-row');
    const next_page = filterContainer.dataset.nextPage;

    const collection = event.currentTarget.dataset.collection;
    const type = event.currentTarget.dataset.type;

    let filters = {}
    filters[collection] = {}
    filters[collection][type] = [event.currentTarget.dataset.clearFilter]

    const filterEvent = new CustomEvent('filterCardsEvent', {
      detail: {
        filters: filters,
        next_page: next_page,
        focus_element_selector: '#ai-card-list-clear-filters-container .btn-clear-filter'
      }
    });
    this.filterCardsEventHandler(filterEvent)
  }

  sort(event) {
    //TODO: perhaps we need to have next page in more generic location
    const filterContainer = this.element.querySelector('#sort-and-filters-row');
    const next_page = filterContainer.dataset.nextPage;

    // make an array to reserve for possibility of sorting by multiple fields
    const sort_list = [{[event.currentTarget.dataset.sort]: event.currentTarget.dataset.direction}];
    const filterEvent = new CustomEvent('filterCardsEvent', {
      detail: {
        sort: sort_list,
        next_page: next_page,
        focus_element_selector: '#cards-sort-menu-btn'
      }
    });
    this.filterCardsEventHandler(filterEvent)
  }

  serializeForRails(obj, prefix) {
    let str = [], p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
        str.push((v !== null && typeof v === "object") ?
            this.serializeForRails(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  tabDeactivatedEventHandler(event) {
    console.log("suggestionsTabDeactivatedEventHandler")
    //TODO: here we need to cleanup the suggestion list perhaps
    // or perhaps consider saving suggestion card???

    //for sure we need to let everyone know that we are done with this tab and the form is no longer active
    const crosslinkFormActiveEvent = new CustomEvent("crosslinkFormActive", {
      detail:{crosslinkForm: null}
    })
    document.dispatchEvent(crosslinkFormActiveEvent)
  }

  tabActivatedEventHandler(event) {
    // we may be lazy loading on the back ground but when we activate this tab
    // we want to let everyone who was managing crosslinks that there is potentialy new corslink forms are comming

    const crosslinkFormActiveEvent = new CustomEvent("crosslinkFormActive", {
      detail:{crosslinkForm: null}
    })
    document.dispatchEvent(crosslinkFormActiveEvent)

    this.lazyLoadContent(event)
  }

  lazyLoadContent(event) {
    const url = this.element.dataset.tabUrl
    this.element.classList.add('loading')
    Rails.ajax({
      type: "get",
      url: url,
      success: (data) => {
        if(this.aiPanel){
          this.disconnect_panel_events();
          this.aiPanel = null;
        }
        this.element.innerHTML = data.tab_html;

        let url = new URL(location)
        url.searchParams.set('tab', this.element.dataset.tabName)
        window.history.pushState('data', '', url)

        this.element.classList.remove('loading')
        this.element.classList.add('loaded')

        // since we just loaded the panel we need to connect to it
        // expect for panel to be there
        this.aiPanel = this.element.querySelector("#data-ai-attribute-suggestions-panel")
        this.aiPanel.addEventListener('activeSuggestionCardUnlock', this.activeSuggestionCardUnlockEventHandler);

        document.body.dispatchEvent(new CustomEvent('tooltips:refresh'))
        //update spinner on the tab
        this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadedEvent', { detail: { tab: this.element } }))

        // now lets handle attributes list
        let attributesList = this.element.querySelector("#attribute_id")

        // we just loaded the whole page, so perhaps we should focus on the drop down first
        // perhaps later we will do better focus management
        attributesList.focus()

        if(event.detail && event.detail.hasOwnProperty('attributeId')) {
          let attributesList = this.element.querySelector("#attribute_id")
          attributesList.value = event.detail.attributeId;
          // even if we dispatch an event on this element now, handler has not yet been hooked up to stimulus
          //so instead we just call the function directly

          let attributeChangedEvent = {target: attributesList}
          if(event.detail.hasOwnProperty('search_string') && event.detail.search_string && event.detail.search_string.length > 0){
            attributeChangedEvent.detail = {
              filters: { 'entities': { 'include': [event.detail.search_string] } },
              is_manual_search: true, // perhaps here we should have a logic that will be smart about manual vs not manual search
              keep_ai_search_input: true
            }
          }

          this.suggestionAttributeChanged(attributeChangedEvent);
        }


      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  searchChanged(event) {
    if(event.target.value.length > 0) {
      let attributesList = this.element.querySelector("#attribute_id")
      const selectedOption = attributesList.options[attributesList.selectedIndex];
      const baseUrl = selectedOption.dataset.termsUrl;
      if (baseUrl) {
        let queryString = "query=" + event.target.value;
        let url = `${baseUrl}?${queryString}`;
        this.performSearch(url);
      }
    }else{
        const termsContainer = this.element.querySelector('#ai-search-input-terms-container');
        termsContainer.innerHTML = null
        termsContainer.classList.remove('d-block')
        termsContainer.classList.add('d-none')
        this.searchTermsCurrentIndex = -1;
    }
    // here we need to check if event.target is already in focus and if its not focus
    if(document.activeElement !== event.target){
      event.target.focus();
    }

  }

  navigateSearchTerms(event) {
    const key = event.key;
    let indexChange = 0;
    if (key === "ArrowDown") {
      indexChange = 1;
    } else if (key === "ArrowUp") {
      indexChange = -1;
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      this.submitSearchInput(event.target)
      return;
    } else if (key === 'Escape' || event.keyCode === 27) {
      this.hideSearchTerms(event)
    } else {
      return; // Ignore other keys
    }

    event.preventDefault(); // Prevent default to avoid moving the cursor in the input

    const termsContainer = this.element.querySelector('#ai-search-input-terms-container');
    const termButtons = termsContainer.querySelectorAll('.suggestion-button');

    let newIndex = (this.searchTermsCurrentIndex + indexChange) % termButtons.length;

    if (newIndex < 0) {
      newIndex = termButtons.length - 1; // Wrap around to the last button
    }
    this.searchTermsCurrentIndex = newIndex;

    termButtons.forEach((btn, idx) => {
      if (idx === this.searchTermsCurrentIndex) {
        btn.classList.add("highlight"); // Add a highlight class for visual feedback
        event.target.value = btn.dataset.searchValue
        //btn.focus(); // Optionally focus the button
      } else {
        btn.classList.remove("highlight");
      }
    });

  }

  hideSearchTerms(event) {
    setTimeout(() => { // Use a timeout to delay the check
      // Check if the newly focused element is not one of the suggestion buttons
      const termsContainer = this.element.querySelector('#ai-search-input-terms-container');
      if (!termsContainer.contains(document.activeElement)) {
        termsContainer.classList.remove('d-block')
        termsContainer.classList.add('d-none'); // Hide the container
        this.searchTermsCurrentIndex = -1;
      }
    }, 150); // Delay just enough for clicks on suggestions to be processed
  }


  submitSearchInput(searchInput) {
    const termsContainer = this.element.querySelector('#ai-search-input-terms-container');

    if(searchInput.value.length > 0) {
      let entity_filter = {
        'include_type': 'partial',
        'include': [searchInput.value.toLowerCase()], // keep it consistent 'lower case' for all
        'exclude_type': 'none',
        'exclude': [],
      }

      let filters = {
        'entities': entity_filter
      };

      // Using case insensitive querySelector modifier "i" to capture any user typed search term that is already on the list
      const termButton = termsContainer.querySelector(`.suggestion-button[data-search-value="${searchInput.value.toLowerCase()}" i]`);
      let is_manual_search = true;

      if(termButton){
        is_manual_search = false;
      }

      const filterEvent = new CustomEvent('filterCardsEvent', {
                                                                detail: {
                                                                  filters: filters,
                                                                  is_manual_search: is_manual_search,
                                                                  keep_ai_search_input: true
                                                                }
                                                              });
      this.filterCardsEventHandler(filterEvent)

      termsContainer.innerHTML = null
      termsContainer.classList.remove('d-block')
      termsContainer.classList.add('d-none')

    }else{

      //cleanup all filters
      let entity_filter = {
        'include': [],
        'exclude': []
      }

      let filters = {
        'entities': entity_filter
      };

      const filterEvent = new CustomEvent('filterCardsEvent', {
        detail: {
          filters: filters
        }
      });
      this.filterCardsEventHandler(filterEvent)

      termsContainer.innerHTML = null
      termsContainer.classList.remove('d-block')
      termsContainer.classList.add('d-none')
      this.searchTermsCurrentIndex = -1;
    }
  }

  searchTermClick(event) {
    const searchInput = this.element.querySelector('#ai_search_input');
    searchInput.value = event.currentTarget.dataset.searchValue;
    this.submitSearchInput(searchInput)
  }

  // need this as a separate method due to debounce
  performSearch(url) {
    const termsContainer = this.element.querySelector('#ai-search-input-terms-container');
    termsContainer.classList.add('loading')
    termsContainer.classList.remove('loaded')

    Rails.ajax({
      type: "get",
      url: url,
      contentType: "application/json",
      success: (data) => {
        const termsContainer = this.element.querySelector('#ai-search-input-terms-container');
        termsContainer.innerHTML = data.terms_html_data
        termsContainer.classList.remove('d-none')
        termsContainer.classList.add('d-block')
        termsContainer.classList.add('loaded')
        termsContainer.classList.remove('loading')

        this.searchTermsCurrentIndex = -1;
      },
      error: (error) => {
        console.log(error)
      }
    })

  }

  // this method is hooked up with suggestionList target panel erb
  suggestionAttributeChanged(event) {
    // Get the selected option
    const selectedOption = event.target.options[event.target.selectedIndex];

    const searchInputContainer = this.element.querySelector('#ai-search-input-container');
    const searchInput = searchInputContainer.querySelector('#ai_search_input');

    this.searchTermsCurrentIndex = -1;
    //this still feels hackish, so better refactor it at some point
    if(event.detail && event.detail.filters && event.detail.filters.entities && event.detail.filters.entities.include){
        searchInput.value = event.detail.filters.entities.include[0];
    }else{
      searchInput.value = '';
    }


    // Retrieve the data-attribute value
    const baseUrl = selectedOption.dataset.indexUrl;
    if(baseUrl){
      searchInputContainer.classList.remove('d-none')
      let url = baseUrl
      if(event.detail){
        url = this.calculateFilterUrlParams(event, baseUrl)
      }

      this.updateSuggestionCardList(url, '#ai_search_input');
    }else {
      searchInputContainer.classList.add('d-none')
    }

  }

  updateSuggestionCardList(url, focus_element_selector) {
    if(!focus_element_selector){
      focus_element_selector = '#ai_search_input';
    }

    const suggestionListDom = document.querySelector('#data-ai-attribute-suggestions-panel #ai-attribute-suggestion-list');
    if(suggestionListDom) {
      suggestionListDom.innerHTML = null
      suggestionListDom.classList.add('loading')
      suggestionListDom.classList.remove('loaded')
      //this will trigger reset of the document panel
      const crosslinkFormActiveEvent = new CustomEvent("crosslinkFormActive", {
        detail:{crosslinkForm: null}
      })
      document.dispatchEvent(crosslinkFormActiveEvent)

      this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadingEvent', { detail: { tab: this.element } }))

      Rails.ajax({
        type: "get",
        url: url,
        contentType: "application/json",
        success: (data) => {
          // all card come inactive
          suggestionListDom.innerHTML = data.list_html_data
          suggestionListDom.classList.remove('loading')
          suggestionListDom.classList.add('loaded')

          //TODO: here we need to consider updating document.body url so if the user refreshes the page it will keep all the query string parameters from original new url

          document.body.dispatchEvent(new CustomEvent('tooltips:refresh'));
          //update spinner on the tab
          this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadedEvent', { detail: { tab: this.element } }))

          let focusElement = this.element.querySelector(focus_element_selector);
          if(!focusElement && focus_element_selector !== '#ai_search_input'){
             focusElement = this.element.querySelector('#ai_search_input');
          }

          if(focusElement) {
            focusElement.focus();
            scrollIntoView(focusElement, {
              behavior: 'smooth',
              scrollMode: 'always',
              block: 'center',
              inline: 'center',
            });
          }

          //make first card active
          const firstSuggestion = suggestionListDom.querySelector(".suggestion-card-container")
          if (firstSuggestion) {
            // stimulus may not be initialized yet, so we need to check if it is initialized

            if (firstSuggestion.classList.contains('initialized')) {
              // If initialized, dispatch the event immediately
              const aiPanel = document.querySelector("#data-ai-attribute-suggestions-panel")
              aiPanel.dispatchEvent(new CustomEvent("activeSuggestionCardSelected", {
                detail: { card: firstSuggestion, focus: false}
              }));
            } else {
              // If not initialized, set an attribute to indicate a pending event
              firstSuggestion.setAttribute('data-pending-event', 'activeSuggestionCardSelected');
              firstSuggestion.setAttribute('data-focus-on-activate', 'false');
            }
          }

        },
        error: (error) => {
          console.log(error)
          //update spinner on the tab
          this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadedEvent', { detail: { tab: this.element } }))
          suggestionListDom.innerHTML = '<div class="alert alert-danger mr-3">Running into issues, unable to retrieve suggestions.</div>'
        }
      });

    }

  }

  loadMore(event) {
    event.preventDefault()
    // Get the selected option
    const cardList = this.element.querySelector('#ai-card-list');
    const buttonContainer = this.element.querySelector('#ai-load-more-suggestions-btn-container');

    const url = event.currentTarget.dataset.url;
    this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadingEvent', { detail: { tab: this.element } }))
    this.element.classList.add('loading')
    buttonContainer.innerHTML = '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>'
    Rails.ajax({
      type: "get",
      url: url,
      contentType: "application/json",
      success: (data) => {
         cardList.insertAdjacentHTML('beforeend', data.page_data)
         buttonContainer.innerHTML = data.btn_data
         this.element.classList.remove('loading')
         this.element.classList.add('loaded')

         // buttonContainer.scrollIntoView();
         document.body.dispatchEvent(new CustomEvent('tooltips:refresh'));

         //update spinner on the tab
         this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadedEvent', { detail: { tab: this.element } }))
      },
      error: (error) => {
        console.log(error)
        //update spinner on the tab
        this.tabsContainer.dispatchEvent(new CustomEvent('tabContentLoadedEvent', { detail: { tab: this.element } }))
        cardList.insertAdjacentHTML('beforeend', '<div class="alert alert-danger mr-3">Running into issues, unable to retrieve suggestions.</div>')
      }
    })

  }

}

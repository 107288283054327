import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class extends Controller {
  connect = () => {
    this.showToasts()
  }
  showToasts = () => {
    const toasts = document.querySelectorAll('.toastr')
    toasts.forEach((toast) => {
      const toastType = toast.dataset.toastType
      const toastMessage = toast.dataset.toastMessage
      let icon = ''
      switch(toastType) {
        case 'warning':
          icon = 'warning'
          break
        case 'error':
        case 'alert':
          icon = 'error'
          break
        default:
          icon = 'success'
          break
        }
      Swal.fire({
        text: toastMessage, 
        icon: icon, 
        toast: true, 
        position: 'top',
        showConfirmButton: false,
        timer: 3500,
        customClass: {
          icon: 'toast-icon'
        }})
      toast.classList.remove('toastr')
    })
  }
}

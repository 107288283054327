import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { easing } from "jquery";

export default class extends Controller {
  static targets = ['info', 'importMapId', 'importType']

  connect() {
    this.url = this.element.dataset.importMapUrl
  }

  load_map_info(_element) {
    const importMapId = this.importMapIdTarget.value
    const importType = this.importTypeTarget.value
    Rails.ajax({
      type: "get",
      url: this.url,
      data: `settlement_import_map_id=${importMapId}&import_type=${importType}`,
      success: (data) => {
        this.applyResult(data)
      },
      error: (error) => {
        console.log(error)
      }
    })  

  }

  applyResult(data) {
    const el = this.infoTarget
    data.import_options.forEach(opt => {
      let option = this.importMapIdTarget.querySelector(`option[value="${opt.id}"]`)
      if (option) { option.innerText = opt.name }
    });
    $(el).fadeOut(100)
    el.innerHTML = data.html
    $(el).fadeIn(200)
  }
  
}
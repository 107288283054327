import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "account", "token" ]
  configs = (linkToken) => {
    const controller = this
    return {
      token: linkToken,
      onLoad: function() {
        // The Link module finished loading.
      },
      onSuccess: (public_token, metadata) => {
        // The onSuccess function is called when the user has
        // successfully authenticated and selected an account to
        // use.
        //
        // When called, you will send the public_token
        // and the selected account ID, metadata.accounts,
        // to your backend app server.
        //
        // sendDataToBackendServer({
        //   public_token: public_token,
        //   account_id: metadata.accounts[0].id
        // });
        // console.log('Public Token: ' + public_token)
        controller.tokenTarget.value = public_token
        switch (metadata.accounts.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            const acctId = metadata.accounts[0].id
            // console.log('PaymentMethod-selected account ID: ' + acctId)
            controller.accountTarget.value = acctId
            controller.submit()
            break;
          default:
            // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
        }
      },
      onExit: async (err, metadata) => {
        // The user exited the Link flow.
        if (err != null) {
            // The user encountered a Plaid API error
            // prior to exiting.
            console.log(err)
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
      }
    }
  }
  onboard = () => {
    Rails.ajax({
      type: "get",
      url: this.data.get('linkTokenPath'),
      success: (data) => {
        const configs = this.configs(data.linkToken)
        var linkHandler = Plaid.create(configs)
        linkHandler.open();
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  submit = () => {
    this.element.submit()
  }
}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import confirmationDialog from "../src/confirmationDialog"

export default class extends Controller {
  subscribe = (event) => {
    Rails.ajax({
      type: "post",
      url: this.subscribeUrl(),
      data: ``,
      success: (data) => {
        this.reload(data)
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  unsubscribe = (event) => {
    Rails.ajax({
      type: "post",
      url: this.unsubscribeUrl(),
      data: ``,
      success: (data) => {
        this.reload(data)
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  reload = (data) => {
    document.getElementById(this.container()).outerHTML = data.subscription_html
    document.body.dispatchEvent(new CustomEvent('tooltips:refresh'))
  }
  subscribeUrl = () => {
    return this.data.get('subscribeUrl')
  }
  unsubscribeUrl = () => {
    return this.data.get('unsubscribeUrl')
  }
  container = () => {
    return this.data.get('container')
  }
}

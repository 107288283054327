import _ from 'lodash';

export default function(message, element, ok_callback) {
  const $dialog = $('#confirmation-modal')
  const $content = $dialog.find('#modal-content')
  const $ok = $dialog.find('#confirm-ok-button')
  const $icon = $ok.children('i')
  const $btnText = $ok.children('.btnText')
  const btnTypes = [['btn', 'btn-outline'], ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'status']]
  const btnStyles = btnTypes.reduce((a,b)=>a.flatMap(x=>b.map(y=>`${x}-${y}`)))
  const largeDialog = element.getAttribute && element.getAttribute('data-large-dialog') == 'true'

  if (largeDialog) {
    $dialog.find('.modal-dialog').addClass('modal-lg')
  }
  $content.html(message)
  if (element.className) {
    let btnStyle = _.intersection(element.className.split(" "), btnStyles)
    const btnIcon = element.querySelector("i")
    const btnText = element.innerText
    // Outline style looks odd in modal, clear it
    btnStyle = _.replace(btnStyle, "-outline", "")
    if (btnStyle) {
      const btnSize = $ok.hasClass('btn-sm') ? 'btn-sm' : $ok.hasClass('btn-lg') ? 'btn-lg' : ''
      $ok.removeClass()
      $ok.addClass(`btn ${btnSize} ${btnStyle}`)
    }
    if (btnIcon) {
      $icon.removeClass()
      $icon.addClass(btnIcon.className)
    }
    if (btnText) {
      $btnText.text(btnText)
    }
  }
  $ok.on('click', function(e) {
    e.preventDefault()
    $dialog.modal("hide")
    ok_callback()
  })
  $dialog.on('shown.bs.modal', function (e) {
    $ok.trigger('focus')
  })
  $dialog.on('hide.bs.modal', function (e) {
    $dialog.find('.modal-dialog').removeClass('modal-lg')
    $ok.off('click')
  })
  $dialog.modal("show")
  return false;
}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["hide"]

  hideSplash = () => {
    const url = this.data.get('hideUrl')
    if (this.hideTarget.checked) {
      Rails.ajax({
        type: "post",
        url: url,
        success: (data) => {
        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }
}
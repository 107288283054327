import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class extends Controller {
  notify = (msg, type) => {
    Swal.fire({
      text: msg, 
      icon: type, 
      toast: true, 
      position: 'top',
      showConfirmButton: false,
      timer: 3500,
      customClass: {
        icon: 'toast-icon'
      }
    })
  }

  handleFeedbackDropdownChange = function(event) {
    const AttributeSelectionElement = document.getElementById("attribute_id")
    const attribute = AttributeSelectionElement ? AttributeSelectionElement.value : ""

    const optionsToCollectAdditionalInfo = event.target.dataset.feedbackOptionsToCollectAdditionalInfo.split(",")
    const feedback_code = event.target.value
    
    let requestBody = {
      "feedback_code": feedback_code,
      "attribute_scope": event.target.dataset.scope,
      "scope_id": event.target.dataset.scopeId,
      "attribute": attribute,
      "suggestion_id": event.target.dataset.suggestionId,
      "document_id": event.target.dataset.documentId,
      "feedback_action_range": event.target.dataset.feedbackActionRange,
    }

    if(!optionsToCollectAdditionalInfo.includes(feedback_code)) {
      Rails.ajax({
        type: "post",
        url: "/user_feedbacks/0/add_user_feedback_for_suggestion",
        data: JSON.stringify(requestBody),
        contentType: "application/json",
        success: (data) => {
          this.notify(data.message, 'success')
        },
        error: (error) => {
          console.log(error)
          this.notify('An error occurred, please try again.', 'error')
        }
      })
    } else {
      const selectedAttributeOption = AttributeSelectionElement.options[AttributeSelectionElement.selectedIndex];
      let attributeName = selectedAttributeOption ? selectedAttributeOption.text : ""
      let newAttributeName = attributeName.replace("🔍", "");

      const feedbackDropdownId = event.target.dataset.dropdownId
      const feedbackDropdown = document.getElementById(feedbackDropdownId)
      const selectedFeedbackOption = feedbackDropdown.options[feedbackDropdown.selectedIndex];
      let feedbackDropdownOptionCode = selectedFeedbackOption ? selectedFeedbackOption.value : ""
      let feedbackDropdownOptionTitle = selectedFeedbackOption ? selectedFeedbackOption.text : ""

      requestBody["attribute_name"] = newAttributeName
      requestBody["feedback_code"] = feedbackDropdownOptionCode
      requestBody["feedback_title"] = feedbackDropdownOptionTitle
      requestBody["feedback_dropdown_id"] = feedbackDropdownId

      this.openFeedbackModal(requestBody)
    }
  }

  openFeedbackModal = function(requestBody) {
    let modal = document.getElementById("feedback_modal");
    modal.style.display = 'block';  
    
    const modalContainer = document.getElementById("feedback_modal_container")

    Rails.ajax({
      type: "post",
      url: "/user_feedbacks/0/update_feedback_modal_body",
      data: JSON.stringify(requestBody),
      contentType: "application/json",
      success: (data) => {
        modalContainer.innerHTML = data.html_data
      },
      error: (error) => {
        console.log(error)
        modalContainer.innerHTML = '<div class="alert alert-danger mr-3">Operation failed. Please try again later.</div>'
      }
    })
  }

  closeFeedbackModal = function() {
    const previousFeedbackElement = document.getElementById("feedback-modal-most-recent-feedback")
    let previousFeedback = previousFeedbackElement ? previousFeedbackElement.innerHTML : ""

    const feedbackDropdownIdElement = document.getElementById("feedback-modal-feedback-dropdown")
    const feedbackDropdownId = feedbackDropdownIdElement ? feedbackDropdownIdElement.innerHTML : ""

    const feedbackDropdownElement = document.getElementById(feedbackDropdownId)
    if (feedbackDropdownElement) {
      feedbackDropdownElement.value = previousFeedback
    }

    const feedbackDetailsElement = document.getElementById("feedback-modal-feedback-details")
    if (feedbackDetailsElement) {
      feedbackDetailsElement.value = ""
    }

    let modal = document.getElementById("feedback_modal");
    modal.style.display = 'none';  
  }

  saveFeedbackDetails = function() {
    const feedbackModalFeedbackCodeElement = document.getElementById("feedback-modal-feedback-code")
    const feedback_code = feedbackModalFeedbackCodeElement ? feedbackModalFeedbackCodeElement.innerHTML : ""

    const feedbackModalAttributeScopeElement = document.getElementById("feedback-modal-attribute-scope")
    const attribute_scope = feedbackModalAttributeScopeElement ? feedbackModalAttributeScopeElement.innerHTML : ""

    const feedbackModalAttributeScopeIdElement = document.getElementById("feedback-modal-attribute-scope-id")
    const scope_id = feedbackModalAttributeScopeIdElement ? feedbackModalAttributeScopeIdElement.innerHTML : ""

    const feedbackModalAttributeElement = document.getElementById("feedback-modal-attribute")
    const attribute = feedbackModalAttributeElement ? feedbackModalAttributeElement.innerHTML : ""

    const feedbackModalSuggestionIdElement = document.getElementById("feedback-modal-suggestion-id")
    const suggestion_id = feedbackModalSuggestionIdElement ? feedbackModalSuggestionIdElement.innerHTML : ""

    const feedbackModalDocumentIdElement = document.getElementById("feedback-modal-document-id")
    const document_id = feedbackModalDocumentIdElement ? feedbackModalDocumentIdElement.innerHTML : ""

    const feedbackModalFeedbackActionRangeElement = document.getElementById("feedback-modal-feedback-action-range")
    const feedback_action_range = feedbackModalFeedbackActionRangeElement ? feedbackModalFeedbackActionRangeElement.innerHTML : ""
    
    const feedbackModalFeedbackDetailsElement = document.getElementById("feedback-modal-feedback-details")
    let feedback_comment = feedbackModalFeedbackDetailsElement ? feedbackModalFeedbackDetailsElement.value : ""

    let requestBody = {
      "feedback_code": feedback_code,
      "attribute_scope": attribute_scope,
      "scope_id": scope_id,
      "attribute": attribute,
      "suggestion_id": suggestion_id,
      "document_id": document_id,
      "feedback_action_range": feedback_action_range,
      "feedback_comment": feedback_comment
    }

    Rails.ajax({
      type: "post",
      url: "/user_feedbacks/0/add_user_feedback_for_suggestion",
      data: JSON.stringify(requestBody),
      contentType: "application/json",
      success: (data) => {
        let modal = document.getElementById("feedback_modal");
        modal.style.display = 'none';  

        this.notify(data.message, 'success')
      },
      error: (error) => {
        console.log(error)
        this.notify('An error occurred, please try again.', 'error')
      }
    })
  }
}
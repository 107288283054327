import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import confirmationDialog from "../src/confirmationDialog"

export default class extends Controller {
  static targets = [ "newTag", "tagToRemove" ]
  add = () => {
    this.addTag()
  }
  remove = (event) => {
    this.tagToRemoveTarget.value = event.currentTarget.dataset.tag
    confirmationDialog(`Remove Tag '${this.tagToRemoveTarget.value}'?`, self, this.removeTag)
  }
  addTag = (event) => {
    const newTag = this.newTagTarget.value.trim()
    if (newTag) {
      Rails.ajax({
        type: "post",
        url: this.addUrl(),
        data: `tag=${newTag}`,
        success: (data) => {
          this.reload(data)
        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }
  removeTag = () => {
    Rails.ajax({
      type: "post",
      url: this.removeUrl(),
      data: `tag=${this.tagToRemoveTarget.value}`,
      success: (data) => {
        this.reload(data)
      },
      error: (error) => {
        console.log(error)
      }
    })  
  }
  reload = (data) => {
    document.getElementById(this.container()).outerHTML = data.tags_html
  }
  addUrl = () => {
    return this.data.get('addUrl')
  }
  removeUrl = () => {
    return this.data.get('removeUrl')
  }
  container = () => {
    return this.data.get('container')
  }
}

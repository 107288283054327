import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "comment"]

  add = () => {
    this.addComment()
  }

  addComment = () => {

    Rails.ajax({
      type: "post",
      url: this.addUrl(),
      data: `claim_task[comment]=${this.commentTarget.value}`,
      success: (data) => {
        if (data.html) {
          document.getElementById(this.container()).innerHTML = data.html
        }
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  addUrl = () => {
    return this.data.get('addUrl')
  }

  container = () => {
    return this.data.get('container')
  }
}

import { add, Controller } from "@hotwired/stimulus"
import {debounce} from 'lodash';
const SmartySDK = require("smartystreets-javascript-sdk");
const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;

export default class extends Controller {
  static targets = [ "searchAttribute" ]
  connect() {
    const addressAutocompleteJsKey = this.element.dataset.addressAutocompleteJsKey
    const credentials = new SmartyCore.SharedCredentials(addressAutocompleteJsKey)
    const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(["us-autocomplete-pro-cloud"])
    this.client = clientBuilder.buildUsAutocompleteProClient()
    this.search = debounce(this.search, 500).bind(this)
  }

  search = function(event) {
    this.handleRequest(event.target);
  }

  close = function(event) {
    const element = event.target
    const dropdown = document.getElementById(`${element.id}_address_results`)
    if (dropdown) {
      dropdown.classList.add("d-none")
    }
  }

  fill = function(searchElement, result) {
    const address1Target = searchElement.dataset.addressAutocompleteAddress1
    const address2Target = searchElement.dataset.addressAutocompleteAddress2
    const cityTarget = searchElement.dataset.addressAutocompleteCity
    const stateTarget = searchElement.dataset.addressAutocompleteState
    const zipcodeTarget = searchElement.dataset.addressAutocompleteZipCode
    const searchName = searchElement.name
    const form = searchElement.closest('form')
    let field_map = []
    field_map.push([form.querySelector(`[name='${searchName}']`),  result.streetLine])
    field_map.push([form.querySelector(`[name='${searchName.replace(address1Target, address2Target)}']`),  result.secondary])
    field_map.push([form.querySelector(`[name='${searchName.replace(address1Target, cityTarget)}']`),  result.city])
    field_map.push([form.querySelector(`[name='${searchName.replace(address1Target, stateTarget)}']`),  result.state])
    field_map.push([form.querySelector(`[name='${searchName.replace(address1Target, zipcodeTarget)}']`),  result.zipcode])
    field_map.forEach((fm) => {
      fm[0].value = fm[1]
      fm[0].classList.add('change-highlight')
      setTimeout(() => {fm[0].classList.remove('change-highlight')}, 1000)
    })
  }

  processResult = (response, element) => {
    const dropdown_id = `${element.id}_address_results`
    let dropdown = document.getElementById(dropdown_id)
    const parent = element.closest('.form-group')
    if (!dropdown) {
      const dropdown_html = `<div class='address_dropdown bg-white border border-input rounded-bottom pt-0 mt-0' id='${element.id}_address_results'></div>`
      parent.insertAdjacentHTML('beforeend', dropdown_html)
      dropdown = document.getElementById(dropdown_id)
    }
    dropdown.innerHTML = ""
    dropdown.classList.remove("d-none")
    response.result.forEach((result)=>{
      const prefix = "data-address-autocomplete"
      const display = `${result.streetLine} ${result.city}, ${result.state}, ${result.zipcode}`
      const result_html = `<div class='address_entry p-2'>${display}</div>`
      dropdown.insertAdjacentHTML('beforeend', result_html)
      const addressEntry = dropdown.lastChild
      addressEntry.addEventListener("mousedown", (event) => {
        dropdown.classList.add("d-none")
        this.fill(element, result)
      })
    })
  }

  handleRequest = async function(element) {
    try {
      let searchText = element.value
      let source = element.dataset.addressAutocompleteSource
      if (!source) { source = 'all'}
      let lookup = new Lookup(searchText)
      lookup.source = source
      lookup.preferGeolocation = 'none'
      lookup.maxResults = 10
      const response = await this.client.send(lookup);
      this.processResult(response, element)
    } catch(err) {
      console.log(err)
      const errMsg = `<pre>${JSON.stringify(err)}</pre>`
      document.querySelector('.content-wrapper').insertAdjacentHTML('beforeend', `<div class="js-error d-none">${errMsg}</div>`)
    }
  }

}

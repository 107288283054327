import Rails from "@rails/ujs";
export default class dependentDropdown {
  constructor(selector = 'select[data-depends-on]') {
    this.selector = selector
  }
  init = () => { 
    const inputs = document.querySelectorAll(this.selector)
    this.setupDependentDropdowns(inputs)
  }

  setupDependentDropdowns = (inputs) => {
    if (inputs.length === 0) return
    inputs.forEach((input)=>{
      const dependsOn = document.getElementById(input.dataset.dependsOn)
      const dependsOnUrl = input.dataset.dependsOnUrl
      const dependsOnReplace = input.dataset.dependsOnReplace
      const loadOnInit = (input.dataset.dependsOnLoadOnInit || '').toLowerCase() == 'true'
      const dependsOnSelectedOption = input.dataset.dependsOnSelectedOption
      dependsOn.addEventListener('change', (e) => {
        this.handleChange(dependsOn, input, dependsOnUrl, dependsOnReplace)
      })
      if (loadOnInit && dependsOn.value) {
        this.handleChange(dependsOn, input, dependsOnUrl, dependsOnReplace, dependsOnSelectedOption)
      }
    })
  }
  
  showResourceOptions = (input, options) => {
    for (var o of options){
      input.options.add(new Option(o.name, o.id, null, o.selected))
    }
    if (input.options.length == 0) {
      input.disabled = true
      input.classList.add("disabled")
    }
    else {
      input.disabled = false
      input.classList.remove("disabled")
    }
    if (input.classList.contains('selectpicker')) {
      $(input).selectpicker('refresh');
    }
  }

  loadOptions = (url, dependsOnSelectedOption, input) => {
    Rails.ajax({
      type: "get",
      url: url,
      data: `selected_option=${dependsOnSelectedOption}`,
      success: (options) => {
        this.showResourceOptions(input, options)
      },
      error: (error) => {
        console.log(error)
      }
    })

  }

  handleChange = (dependsOn, input, dependsOnUrl, dependsOnReplace, dependsOnSelectedOption) => {
    input.disabled = 'true'
    input.classList.add("disabled")
    input.options.length = 0
    const selectedValue = dependsOn.selectedOptions[0].value
    if (selectedValue.length > 0) {
      let regexp = new RegExp(dependsOnReplace, 'g')
      let url = dependsOnUrl.replace(regexp, selectedValue)
      this.loadOptions(url, dependsOnSelectedOption, input)
    }
  } 
}



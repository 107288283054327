import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import confirmationDialog from "../src/confirmationDialog"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import emitFormChangedEvent from "../src/emitFormChangedEvent";
import {debounce} from 'lodash';

export default class extends Controller {
  //README: this controller should be use one per one document only

  connect() {
    this.titleChanged = debounce(this.titleChanged, 500);
  }

  delete = () => {
    confirmationDialog(`Delete Document?`, self, this.deleteDocument)
  }

  ocr = () => {
    confirmationDialog(`OCR Document?`, self, this.ocrDocument)
  }

  stamp = () => {
    confirmationDialog(`Stamp Document?`, self, this.stampDocument)
  }

  ocr_and_stamp = () => {
    confirmationDialog(`OCR And Stamp Document?`, self, this.ocrAndStampDocument)
  }

  ai_process = () => {
    confirmationDialog(`Run all configured AI models for this Document?`, self, this.aiProcessDocument)
  }

  edit() {
    //here we should find closest document-title and make it enabled
    const title = this.element.querySelector ('.document-title')
    title.readOnly = false
    title.focus()
  }

  titleChanged(event) {
    const title = event.target.value
    const url = this.element.dataset.documentUpdateTitleUrl

    Rails.ajax({
      url: url,
      type: 'post',
      data: `title=${title}`,
      success: (data) => {
        this.notify(data.notice, 'success')
      },
      error: (data) => {
        console.log(data)
        this.notify('There was an error saving document', 'error')
      }
    });

  }

  titleFocusChanged(event) {
    event.target.readOnly = true
  }

  //
  // callbacks
  //

  deleteDocument = () => {
    const deleteUrl = this.data.get('deleteUrl')
    const deleteMethod = this.data.get('deleteMethod') || 'post'
    Rails.ajax({
      type: deleteMethod,
      url: deleteUrl,
      success: (response) => {
        this.element.remove()
        this.notify(response.notice, 'success')
        this.showButton()
      },
      error: (error) => {
        this.notify('An error occurred', 'error')
      }
    })
  }

  ocrDocument = () => {
    this.processStampDocument(this.data.get('ocrUrl'))
  }

  stampDocument = () => {
    this.processStampDocument(this.data.get('stampUrl'))
  }

  ocrAndStampDocument = () => {
    this.processStampDocument(this.data.get('ocrAndStampUrl'))
  }

  aiProcessDocument = () =>{
    this.processAiProcessDocument(this.data.get('aiProcessUrl'))
  }

  //
  // methods
  //

  //used in inline_document_container, not sure if useful
  delete_on_page = (event) => {
    const entry = event.target.closest('.inplace-container')
    //set _destroy=1 for entry about to be deleted
    const delete_inputs = event.target.closest('.document-delete').querySelectorAll("input[type='hidden']")
    delete_inputs.forEach( (delete_input) => {
      delete_input.value = 1
    })
    entry.classList.add('d-none');
    const form = this.element.closest('form')
    emitFormChangedEvent(form, event)
  }

  processStampDocument = (stampUrl) => {
    Rails.ajax({
      type: "post",
      url: stampUrl,
      success: (response) => {
        const btnStamp = this.element.querySelector('.btn-stamp')
        btnStamp.disabled = true

        const btnOcrAndStamp = this.element.querySelector('.btn-ocr-and-stamp')
        btnOcrAndStamp.disabled = true

        const btnOcr = this.element.querySelector('.btn-ocr')
        btnOcr.disabled = true

        this.notify(response.notice, 'success')
        this.showButton()
      },
      error: (error) => {
        this.notify('An error occurred', 'error')
      }
    })
  }

  showButton = () => {
    const uploadBtn = this.data.get('uploadBtn')
    if (uploadBtn) {
      const btn = document.querySelector(uploadBtn)
      if (btn) {
        btn.classList.remove("d-none")
      }
    }
  }

  notify = (msg, type) => {
    Swal.fire({
      text: msg, 
      icon: type, 
      toast: true, 
      position: 'top',
      showConfirmButton: false,
      timer: 3500,
      customClass: {
        icon: 'toast-icon'
      }})
  }

  processAiProcessDocument = (aiProcessUrl) => {
    Rails.ajax({
      type: "post",
      url: aiProcessUrl,
      success: (response) => {
        const btnAiProcess = this.element.querySelector('.btn-ai-process')
        btnAiProcess.disabled = true

        this.notify(response.notice, 'success')
        this.showButton()
      },
      error: (error) => {
        this.notify('An error occurred while launching AI processing for this document.', 'error')
      }
    })
  }

}

export default class modalSubmit {
  init = () => {

    const buttons = document.querySelectorAll('button[data-modal-dismiss]')
    this.addButtonHandlers(buttons)
  }

  addButtonHandlers = (buttons) => {
    if (buttons.length === 0) return;
    // Loop over each link on the page. A page could have multiple nested forms.
    buttons.forEach((button)=>{
      button.addEventListener('click', (e) => {
        this.handleClick(button, e);
      });

      // hookup autofocus in modal
      let modal = $(button).closest(".modal")
      if (modal) {
        let focused = modal.find(".auto-focus").first()
        if (focused) {
          modal.on('shown.bs.modal', function () {
            focused.focus();
          })
        }
      }
    });
  }

  handleClick = (button, e) => {
    // Stop the function from executing if a link or event were not passed into the function. 
    if (!button || !e || button.form) return;
    // Prevent the browser from following the URL.
    e.preventDefault();
    const form = button.closest("form")
    form.submit()
  }
}

import Rails from "@rails/ujs";
import confirmationDialog from "./confirmationDialog"

// Overrides default rails confirmation with bootstrap 4 confirm dialog
Rails.confirm = function(message, element) {
  function click(event) {
    let old = Rails.confirm
    Rails.confirm = function() { return true }
    $(element).get(0).click()
    Rails.confirm = old
  }
  confirmationDialog(message, element, click)
}

import { Controller } from "@hotwired/stimulus"
import fileUpload from '../src/fileUpload'

export default class extends Controller {
  connect = () => {
    const fileInput = this.element.querySelector('input[type=file]:not(.uppy-Dashboard-input)')
    const autosave = this.element.dataset.autosave // this is a temporary hack until we get the better management for documents right side nav
    if(fileInput) {
      const fileUploadInstance = new fileUpload()
      fileUploadInstance.init(fileInput, autosave === "true")
    }else{
      console.log("No file input found " + this.element.id)
    }
  }
}

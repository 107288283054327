// !!!!!!!Obsolete use stimulus controller instead
export default class clipboard {
  init = () => {
    const buttons = document.querySelectorAll('.btn-clipboard')
    buttons.forEach((button)=> {
      $(button).tooltip({title: 'Copied!', trigger: 'manual'})
      button.addEventListener('click', (event) => {
        const targetId = button.dataset.clipboardTarget
        const target = document.querySelector(targetId)
        const content = target.textContent
        button.blur()
        this.copy(content, () => {this.showCopiedTooltip(button)})
      })
    })
  }

  copy = (content, successMethod) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content).then(() => {successMethod()}).catch(err => {})
    }
  }

  showCopiedTooltip = (button) => {
    $(button).tooltip('show')
    setTimeout(() => {$(button).tooltip('hide')}, 1500)
  }
}

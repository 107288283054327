import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "attribute", "conditional" ]
  evaluate = function(event) {
    const self = this

    const name_elements = event.currentTarget.name.replace(/\[/g, '.').replace(/\]/g, '').split('.')
    const current_key = name_elements[name_elements.length - 1]
    const current_context = event.currentTarget.name.replace(`[${current_key}]`, '')

    // find conditional targets only withing the context of current level
    self.conditionalTargets.forEach((conditional) => {

      if(conditional.dataset.context != current_context){
        return
      }

      // TODO: need to implement all kinds of new conditions that were introduced
      //       or we need to send the whole thing to the server and evaluate there
      let required_answers = JSON.parse(conditional.dataset.conditions).has_answers
      const has_n_or_more_answers = JSON.parse(conditional.dataset.conditions).has_n_or_more_answers

      let required_count = 0
      if (required_answers) {
        required_count = Object.keys(required_answers).length
      }
      else if (has_n_or_more_answers) {
        required_count = has_n_or_more_answers['count']
        required_answers = has_n_or_more_answers
        delete required_answers['count']
      }
      let met_count = 0

      if (required_answers) {
        Object.keys(required_answers).forEach((key) => {
          let expected_value = required_answers[key]

          const attr = self.attributeTargets.find(x => ( x.name == `${current_context}[${key}]` ))

          //Ignore inapplicable fields
          if (!self.attributeVisible(attr)) {
            return
          }

          if (attr.type === 'checkbox') {
            if ((expected_value === 1 || expected_value == attr.value) && attr.checked) {
              met_count += 1
            }
            else if (expected_value === 0 && !attr.checked) {
              met_count += 1
            }
          }
          else {
            if (!Array.isArray(expected_value)) expected_value = [expected_value]
            if (attr && expected_value.includes(attr.value)) {
              met_count += 1
            }
          }
        })
      }
      const visible = met_count >= required_count
      if (visible && conditional.classList.contains('d-none')) {
        conditional.classList.remove('d-none')
      }
      else if(!visible && !conditional.classList.contains('d-none')) {
        conditional.classList.add('d-none')
      }
    })
  }

  attributeVisible = function(attr) {
    if(attr) {
      return !attr.closest(".form-group").classList.contains("d-none")
    } 
  }

}

import { Controller } from "@hotwired/stimulus"

import _ from 'lodash';

export default class extends Controller {
  static targets = [ "taglist", "tagvalues" ]


  add = function(event) {
    const input = event.target.closest('.input-group').querySelector('input,select')
    const value = input.value
    let currentTags = _.split(this.tagvaluesTarget.value, ",")
    if (currentTags.indexOf(value) === -1) {
      currentTags.push(value)
      this.tagvaluesTarget.value = _.join(currentTags, ",")
      let tagHTML = '<div class="badge badge-secondary p-2 mb-1 mr-1">'
      tagHTML += value
      tagHTML += '<a href="#" class="tag-delete-btn" data-tag="<%= tag %>" data-action="tag-input#remove"><i class="fa fa-sm fa-trash-alt text-light ml-2"></i></a>'
      tagHTML += '</div>'
      this.taglistTarget.insertAdjacentHTML('beforeend', tagHTML)  
    }
    input.value = ''
  }

  keypress = function(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.add(event)
    }
  }

  remove = function(event) {
    const tag = event.target.closest('.badge')
    const value = tag.textContent.trim()
    let currentTags = _.split(this.tagvaluesTarget.value, ",")
    currentTags = currentTags.filter(e => e !== value)
    this.tagvaluesTarget.value = _.join(currentTags, ",")
    tag.classList.add("text-decoration-line-through")
    event.target.closest('a').remove()
  }

}

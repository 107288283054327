import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import masks from '../src/masks'


export default class UserAccessTokenController extends Controller {

    showModal(event) {
        event.preventDefault();
        event.stopPropagation();
        const grant_access_modal = document.querySelector('#grant_access_modal');
        if(grant_access_modal) {
            const title = grant_access_modal.querySelector('.modal-title');
            title.innerHTML = 'Grant Access to ' + event.currentTarget.dataset.title;

            const form = grant_access_modal.querySelector('form');
            form.action = event.currentTarget.dataset.url;

            const access_type_write = grant_access_modal.querySelector('#access_type_write');
            access_type_write.checked = true;

            const expire_in_days = grant_access_modal.querySelector('#expire_in_days');
            expire_in_days.value = 7;

            const to_first_name = grant_access_modal.querySelector('#to_first_name');
            to_first_name.value = event.currentTarget.dataset.firstName;

            const to_last_name = document.querySelector('#grant_access_modal #to_last_name');
            to_last_name.value = event.currentTarget.dataset.lastName;

            const to_email = grant_access_modal.querySelector('#to_email');
            to_email.value = event.currentTarget.dataset.email;

            $('#grant_access_modal').modal('show');
        }
    }

}
import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    let controller = this;
    this.openCount = 0;

    // The listener for the Bootstrap event via jQuery
    $(this.element).on('show.bs.modal', (event) => {
      controller.loadModalContent();
    })
  }

  loadModalContent = () => {
    // TODO: show spinner while the content is being loaded
    Rails.ajax({
      type: "get",
      url: this.getUrl(),
      success: (data) => {
        this.applyResult(data)
      },
      error: (error) => {
        console.log(error)
        //TODO: show error messame if it failed to load the content
      }
    })
  }

  applyResult = (data) => {
    if (data.modal_html) {
      document.getElementById(this.container()).innerHTML = data.modal_html
    }
  }

  getUrl = () => {
    return this.data.get('getUrl')
  }

  container = () => {
    return this.data.get('container')
  }

}

import { get, unset } from 'lodash'

export default class CrosslinkDictionary {
  constructor() {
    this._crosslink_stores = {}
  }

  crosslink_stores = () => {
    return this._crosslink_stores
  }
  populate = (inputs) => {
    if (inputs.length === 0) return;

    //here we need to generate unique random batch id so we can properly delete orphans stores when we flip between forms
    //it does not have to be unique per store, but it has to be unique between active form flips
    const batchId = Date.now().toString();

    inputs.forEach((input)=> {
      if (input.value !== '') {
        let crosslink = JSON.parse(input.value)
        let crosslink_store = {
          batch_id: batchId,
          crosslink: crosslink,
          inout_id: input.id,
          input: input
        }
        this.add(crosslink_store)
      }
    })
  }

  get_crosslink_stores_for = (document_id, page) => {
    return get(this._crosslink_stores, [document_id, page])
  }

  get_crosslink_stores_for_document = (document_id) => {
    return get(this._crosslink_stores, [document_id])
  }
  get_crosslink_store = (document_id, page, crosslink_id) => {
    return get(this._crosslink_stores, [document_id, page, crosslink_id])
  }

  get_crosslink_store_by_crosslink_id = (crosslink_id) => {
      let crosslink_store = null
      Object.keys(this._crosslink_stores).forEach((document_id) => {
        Object.keys(this._crosslink_stores[document_id]).forEach((page) => {
            Object.keys(this._crosslink_stores[document_id][page]).forEach((x_id) => {
              if (this._crosslink_stores[document_id][page][x_id].crosslink.crosslink_id.toString() === crosslink_id.toString()) {
                  crosslink_store = this._crosslink_stores[document_id][page][x_id]
                  // here I want to break the loop and exist function and return crosslink_store
                  return crosslink_store
              }
            })
        })
      })
      return crosslink_store
  }

  //this may be obsolete
  update = (crosslink) => {
    const crosslink_store = this.get_crosslink_store(crosslink.document_highlight.document_id, crosslink.document_highlight.page, crosslink.crosslink_id)
    Object.assign(crosslink_store.crosslink, crosslink)
  }

  add = (crosslink_store) => {
    if (this._crosslink_stores[crosslink_store.crosslink.document_highlight.document_id] == null) {
      this._crosslink_stores[crosslink_store.crosslink.document_highlight.document_id] = {}
    }
    if (this._crosslink_stores[crosslink_store.crosslink.document_highlight.document_id][crosslink_store.crosslink.document_highlight.page] == null) {
      this._crosslink_stores[crosslink_store.crosslink.document_highlight.document_id][crosslink_store.crosslink.document_highlight.page] = {}
    }
    this._crosslink_stores[crosslink_store.crosslink.document_highlight.document_id][crosslink_store.crosslink.document_highlight.page][crosslink_store.crosslink.crosslink_id] = crosslink_store
  }

  remove = (crosslink_store) => {
    let obj = this.get_crosslink_store(crosslink_store.crosslink.document_highlight.document_id, crosslink_store.crosslink.document_highlight.page, crosslink_store.crosslink.crosslink_id)
    if (obj) {
      unset(this._crosslink_stores[crosslink_store.crosslink.document_highlight.document_id][crosslink_store.crosslink.document_highlight.page], crosslink_store.crosslink.crosslink_id)
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class extends Controller {
  static targets = [ "name", "generate", "importMap" ]
  export = () => {
    var url = this.exportUrl()
    const urlSearchParams = new URLSearchParams(window.location.search)
    let params = window.location.search.replace(/^\?/, '')
    params += `&name=${this.nameTarget.value}`
    if (this.hasGenerateTarget && this.generateTarget.checked) {
      params += '&generate=1'
    }
    if (this.hasImportMapTarget) {
      params += `&settlement_import_map_id=${this.importMapTarget.value}`
    }
    //console.log(params)
    Rails.ajax({
      type: "post",
      url: url,
      dataType: 'json',
      accept: 'json',
      data: params,
      success: (rsp) => {
        if (rsp.redirect_to) {
          window.location.href = rsp.redirect_to
        }
      },
      error: (rsp) => {
        console.log(rsp)
        this.notify('Unable to create export', 'error')
      }
    })  

  }
  groupParamsByKey = (params) => [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if(acc.hasOwnProperty(key)) {
        // if the current key is already an array, we'll add the value to it
        if(Array.isArray(acc[key])) {
          acc[key] = [...acc[key], val]
        } else {
          // if it's not an array, but contains a value, we'll convert it into an array
          // and add the current value to it
          acc[key] = [acc[key], val];
        }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }
    
    return acc;
    }, {})
  
  exportUrl = () => {
    var u = this.data.get('exportUrl')
    return u
  }
  notify = (msg, type) => {
    Swal.fire({
      text: msg, 
      icon: type, 
      toast: true, 
      position: 'top',
      showConfirmButton: false,
      timer: 3500,
      customClass: {
        icon: 'toast-icon'
      }})
  }
}

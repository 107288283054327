import { Controller } from "@hotwired/stimulus"
import _ from 'lodash'

export default class extends Controller {

  connect() {
    this.filterableOptions = JSON.parse(this.element.dataset.selectFilterOptions)
    this.filterAttributes = this.element.dataset.selectFilterAttributes.split(',')
    this.watchedFields = []
    const targetElementName = this.element.name
    const fieldName = targetElementName.match(/.*\[.*\]\[.*\]\[(.*)\]/)[1]
    this.filterAttributes.forEach((attribute) => {
      const fieldNameToWatch = targetElementName.replace(fieldName, attribute)
      const input_field = document.querySelector(`[name="${fieldNameToWatch}"]`)
      this.watchedFields.push({attribute: attribute, input_field: input_field})
    })
    this.watchedFields.forEach((watched) => {
      watched.input_field.addEventListener('change', () => this.filter_select())
    })
    this.filter_select()
  }

  filter_select = () => {
    const selectElement = this.element
    selectElement.disabled = true
    const attribute_values = {}
    this.watchedFields.forEach((watched) => {
      if (watched.input_field.value) {
        attribute_values[watched.attribute] = watched.input_field.value
      }
    })
    const options = this.filterableOptions.filter((option) => {
      let matched = true
      const filterAttributes = option.filter_attributes
      if (!filterAttributes) return true;
      for (const [key, value] of Object.entries(attribute_values)) {
        if (Array.isArray(filterAttributes[key]) && !filterAttributes[key].includes(value)) {
          matched = false
          break
        }
        else if (!Array.isArray(filterAttributes[key]) && filterAttributes[key] !== value) {
          matched = false
          break
        }
      }
      return matched
    })
    const currentSelectedValue = selectElement.value
    selectElement.innerHTML = ''
    options.unshift({id: '', name: 'No Response'})
    options.forEach((option) => {
      const optionElement = document.createElement('option')
      optionElement.value = option.id
      optionElement.text = option.name
      selectElement.add(optionElement)
    })
    selectElement.value = currentSelectedValue
    if (selectElement.value !== currentSelectedValue) {
      selectElement.value = options[0].id
    }
    selectElement.disabled = false
  }

}

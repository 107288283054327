import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["card", "select"]

  connect() {
    this.toggleCard(null);
  }

  toggleCard(event) {
    if (this.selectTarget.value === '0') {
      this.cardTarget.classList.add('d-none');
    } else {
      this.cardTarget.classList.remove('d-none');
    }
  }


}

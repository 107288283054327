import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  connect() {
  }

  reference_add = (event) => {
    const multiId = `multi_${event.params.attribute}`
    const multiElement = document.getElementById(multiId)
    const multiController = this.application.getControllerForElementAndIdentifier(multiElement, "form-multi")
    event.params = {liveEdit: true, referenceAdd: true}
    multiController.add(event)
  }

}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import emitFormChangedEvent from '../src/emitFormChangedEvent'
import emitMultiAddedEvent from '../src/emitMultiAddedEvent'
import masks from '../src/masks'
import confirmationDialog from "../src/confirmationDialog"
import scrollIntoView from 'scroll-into-view-if-needed'
import { selectpicker } from 'bootstrap-select'
import { event } from "jquery"
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class extends Controller {
  static targets = ["count", "toggle"]

  connect() {
    //find and hookup to the form-state-manager if it exists
    this.entryWrapper = this.element.querySelector('.multi-entries')
    this.toggleBtn = this.element.querySelector('.multi-toggle')
    this.savePath = this.data.get('savePath')
    this.multiFieldAttribute = this.data.get('attribute')

    this.formStateManager = this.element.closest('[data-controller="form-state-manager"]')
    if(this.formStateManager) {
      this.setMultiStateEventHandler = this.setMultiStateEventHandler.bind(this)
      this.formStateManager.addEventListener('setMultiState', this.setMultiStateEventHandler)
      this.formStateManager.dispatchEvent(new CustomEvent('requestMultiState', {detail: {multi: this.element}}))
    }
    this.element.classList.add('initialized');

  }

  //!!!!! we need to cleanup event listeners when we remove this form from html, otherwise we will have memory leaks !!!!!
  disconnect() {
    if(this.formStateManager) {
      this.formStateManager.removeEventListener('setMultiState', this.setMultiStateEventHandler)
    }
  }

  add = (event) => {
    const fields = this.data.get('fields')
    const replaceId = this.data.get('replaceId')
    const label = this.data.get('label')
    const liveEdit = event.params.liveEdit
    const referenceAdd = event.params.referenceAdd
    // Add the new markup to the form if there are fields to add.
    if (this.entryWrapper && this.entryWrapper.classList.contains('collapsed')) {
      this._toggle({ after_toggle: () => { this._add( fields, replaceId, liveEdit, referenceAdd, this.multiFieldAttribute, label) } })
    }
    else {
      this._add( fields, replaceId, liveEdit, referenceAdd, this.multiFieldAttribute, label)
    }
  }

  delete = (el) => {
    confirmationDialog(`Delete this entry?`, el.target, () => {this.deleteEntry(el)})
  }

  deleteEntry = (event) => {
    const liveEdit = event.params.liveEdit
    const entry = event.target.closest('.multi-entry')
    const form = this.element.closest('form')

    if (liveEdit) {
      const id = event.params.id
      const wrapper = event.target.closest('.multi_wrapper')
      const multiAttribute = wrapper.id
      let data = {attribute: multiAttribute, entry_id: id, _destroy: 1}
      let form_data = new FormData()
      for ( var key in data ) {
        form_data.append(key, data[key]);
      }
      this._save_multi_entry(form_data,
        (successResponse) => {
          this._apply_multi_entry_changes(successResponse)
          this.setCount(-1)
          entry.remove()
          this._renumber_multi_entries()
          emitFormChangedEvent(form, event)
        },
        (errorResponse) => {
          console.log(errorResponse)
          Swal.fire({
            text: 'An error occurred trying to delete entry',
            icon: 'error', 
            toast: true, 
            position: 'top',
            showConfirmButton: false,
            timer: 3500,
            customClass: {
              icon: 'toast-icon'
            }
          });
        }
      )
    }
    else { // non-live delete

      const delete_inputs = event.target.closest('.multi-delete').querySelectorAll("input[type='hidden']")

      delete_inputs.forEach( (delete_input) => {
        delete_input.value = 1
      })

      entry.classList.add('d-none');

      this.setCount(-1)
      this._renumber_multi_entries()
      emitFormChangedEvent(form, event)

      entry.querySelectorAll(':scope > :not(.multi-actions-container)').forEach((field) => { field.remove() })
      entry.querySelector('.multi-actions-container')(':scope > :not(.multi-delete)').forEach((field) => { field.remove() })
    }
  }

  apply_reference = (event) => {
    const select = event.target
    const attributeName = event.params.attributeName
    const selected_option = select.options[select.selectedIndex]
    const referenceAttribute = event.params.referenceAttribute
    const idName = select.name.replace(`[${attributeName}]`, `[id]`)
    const entryId = document.querySelector(`input[name="${idName}"]`).value
    let data = new FormData()
    data.append(attributeName, selected_option.value)
    data.append('entry_id', entryId)
    data.append('skip_validation', true)
    data[attributeName] = selected_option.value
    this._save_multi_entry(data,
      (success_response) => {
        this._apply_multi_entry_changes(success_response)
      },
      (error_response) => {
        console.log(error_response)
      }
    )
  }

  save_multi_entry = (event) => {
    const params = event.params
    const multiAttribute = params.attribute
    const referenceAdd = params.referenceAdd
    const dialog = document.getElementById(`multi-edit-modal-${multiAttribute}`)
    const content = dialog.querySelector('#multi-entry-modal-content')
    const saveBtn = dialog.querySelector('#save-button')
    saveBtn.disabled = true
    let data = this._getFormData(content)
    content.querySelectorAll('.is-invalid').forEach((input) => {
      input.classList.remove('is-invalid')
    })
    content.querySelectorAll('.invalid-feedback').forEach((feedback) => {
      feedback.remove()
    })

    this._save_multi_entry(data,
      (success_response) => {
        this._apply_multi_entry_changes(success_response, referenceAdd)
        this._clear_multi_modal(dialog)
        emitFormChangedEvent(event.target.closest('form'), event)
      },
      (error_response) => {
        if (error_response.validation_messages) {
          this._apply_validation_messages(content, error_response)
          saveBtn.disabled = false
        }
        else {
          this._clear_multi_modal(dialog)
          console.log(error_response)
          Swal.fire({
            text: 'An error occurred trying to save entry',
            icon: 'error', 
            toast: true, 
            position: 'top',
            showConfirmButton: false,
            timer: 3500,
            customClass: {
              icon: 'toast-icon'
            }
          });
        }
      }
    )
  }

  _renumber_multi_entries = () => {
    if (this.entryWrapper) {
      const entries = this.entryWrapper.querySelectorAll('.multi-entry:not(.d-none)')
      entries.forEach((entry, index) => {
        const entryNumber = index + 1
        const entryLabels = entry.querySelectorAll('label')
        entryLabels.forEach((entryLabel) => {
          entryLabel.innerText = entryLabel.innerText.replace(/\#\d+/, `#${entryNumber}`)
        })
      })
    }
  }

  _clear_multi_modal = (dialog) => {
    const content = dialog.querySelector('#multi-entry-modal-content')
    const saveBtn = dialog.querySelector('#save-button')
    $(content).html('')
    $(dialog).modal('hide')
    saveBtn.disabled = false
  }

  _save_multi_entry = (data, successCallback, errorCallback) => {
    Rails.ajax({
      type: "post",
      url: this.savePath,
      data: data,
      success: (response) => {
        successCallback(response)
      },
      error: (error) => {
        errorCallback(error)
      }
    })
  }

  _apply_validation_messages = (content, error) => {
    if (error.validation_messages) {
      for (let attribute in error.validation_messages) {
        const input = content.querySelector(`[name*='[${attribute}]'`)
        if (input) {
          input.classList.add('is-invalid')
          input.closest('.form-group').insertAdjacentHTML('beforeend', `<div class='invalid-feedback order-last'>${error.validation_messages[attribute]}</div>`)
        }
      }
    }
  }

  _apply_multi_entry_changes = (response, referenceAdd) => {
    if (response.new_entry_html) {
      this.setCount(+1)
      const new_entry_html = response.new_entry_html
      if (this.entryWrapper) {
        const entryNumber = this.entryWrapper.querySelectorAll('.multi-entry').length + 1
        this.entryWrapper.insertAdjacentHTML('beforeend', new_entry_html)
        const newEntry = this.entryWrapper.lastChild
        this._renumber_multi_entries()
        new masks(newEntry)
        $(newEntry.querySelectorAll('.selectpicker')).selectpicker()
        const form = this.element.closest('form')
        emitFormChangedEvent(form, event)
        emitMultiAddedEvent(newEntry)
        const firstElement = newEntry.querySelector('input, select, textarea, checkbox')
        if (!referenceAdd) {
          scrollIntoView(newEntry, {
            behavior: 'smooth',
            scrollMode: 'if-needed',
            block: 'nearest',
            inline: 'end',
          })
          firstElement.focus()
        }
      }
    }
    if (response.referenced_entries) {
      for (let multiAttribute in response.referenced_entries) {
        const referenced_entry_ids = response.referenced_entries[multiAttribute]
        const deleteButtons = document.querySelectorAll(`div.multi_wrapper#multi_${multiAttribute} .multi-delete-btn`)
        if (deleteButtons.length === 0) continue
        deleteButtons.forEach((deleteBtn) => {
          const entry_id = deleteBtn.dataset.formMultiIdParam
          if (referenced_entry_ids.includes(entry_id)) {
            deleteBtn.disabled = true
            deleteBtn.title = 'This entry cannot be deleted because it is referenced by another entry'
          }
          else {
            deleteBtn.disabled = false
            deleteBtn.title = ''
          }
        })
      }
    }
    if (response.referencing_options) {
      response.referencing_options.forEach((ref) => {
        const id_starts_with = `${response.model}_${ref.multi_attribute}_attributes_`
        const id_ends_with = ref.field_attribute
        const matching_fields = document.querySelectorAll(`select[id^="${id_starts_with}"][id*="${id_ends_with}"]`)
        matching_fields.forEach((field) => {
          const currently_selected_value = field.value
          field.innerHTML = ref.options_html
          let x = [...field.options].findIndex(option => option.value === currently_selected_value)
          field.selectedIndex = x
        })
        const multi_wrapper = document.getElementById(`multi_${ref.multi_attribute}`)
        if (multi_wrapper) {
          multi_wrapper.dataset.formMultiReplaceId = ref.replace_id
          multi_wrapper.dataset.formMultiFields = ref.fields
        }
      })
    }
  }

  _getFormData(element) {
    var kvpairs = {};
    const elements = element.querySelectorAll('input,select,textarea,checkbox')
    elements.forEach((e) => {
      let nameMatch = e.name.match(/(.*)\[.*\]\[.*\]\[(.*)\]/)
      if (nameMatch[1] == 'crosslink') return // skip
      const name = nameMatch[2]
      switch (e.type) {
        case 'text':
        case 'textarea':
        case 'password':
        case 'hidden':
          kvpairs[name] = e.value
          break
        case 'radio':
        case 'checkbox':
          kvpairs[name] = e.value
          break
        case 'select-one':
          kvpairs[name] = e.options[e.selectedIndex].value
          break
        case 'select-multiple':
          let values = Array.from(e.selectedOptions).map(({ value }) => value)
          kvpairs[name] = values
          break
      }
    })
    let form_data = new FormData()
    for ( var key in kvpairs ) {
      const form_key = (key === 'id') ? 'entry_id' : key
      form_data.append(form_key, kvpairs[key]);
    }
    return form_data
  }

  _add = (fields, replaceId, liveEdit, referenceAdd, multiFieldAttribute, label) => {
    let newId = this.getUniqueId()
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    let regexp = replaceId ? new RegExp(replaceId, 'g') : null
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    let newFields = regexp ? fields.replace(regexp, newId) : null


    if (liveEdit) {
      let entryRegexp = new RegExp('#:entry_number', 'g')
      newFields = newFields.replace(entryRegexp, '')
      this._showEditDialog(multiFieldAttribute, newFields, label, referenceAdd)
    }
    else {
      let entryRegexp = new RegExp(':entry_number', 'g')
      const newCount = this.setCount(+1)
      newFields = newFields.replace(entryRegexp, newCount)
      this.entryWrapper.insertAdjacentHTML('beforeend', newFields)
      const newEntry = this.entryWrapper.lastChild
      this._renumber_multi_entries()
      new masks(newEntry)
      $(newEntry.querySelectorAll('.selectpicker')).selectpicker()
      const form = this.element.closest('form')
      emitFormChangedEvent(form, event)
      emitMultiAddedEvent(newEntry)
      const firstElement = newEntry.querySelector('input, select, textarea')
      scrollIntoView(newEntry, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'end',
      })
      firstElement.focus()
    }
  }

  _showEditDialog = (multiFieldAttribute, newFields, label, referenceAdd) => {
    const dialog = document.querySelector(`#multi-edit-modal-${multiFieldAttribute}`)
    const content = dialog.querySelector('#multi-entry-modal-content')
    const headerContent = dialog.querySelector('#multi-entry-modal-header-content')
    const saveBtn = dialog.querySelector('#save-button')
    if (referenceAdd) {
      saveBtn.dataset.formMultiReferenceAddParam = 'true'
    }
    saveBtn.disabled = false
    content.innerHTML = newFields
    content.querySelectorAll('input, select, textarea, checkbox').forEach((input) => {
      input.dataset.action = 'change->dynamic-forms#evaluate' // add stimulus action for conditionals
    })
    Inputmask().mask(content.querySelectorAll("[data-inputmask-inputformat]"));
    headerContent.innerHTML = `Add entry to ${label}`
    content.querySelector('.multi-delete-btn').remove()
    content.querySelector('.multi-entry').classList.remove('border')
    const modal = $(dialog).modal('show')
    modal.on('hidden.bs.modal', (event) => {
      this._clear_multi_modal(dialog)
    })
  }

  toggle = (event) => {
    event.preventDefault()
    this._toggle()
  }

  _toggle = ({shown, after_toggle, disable_animation} = {}) => {

    const show = (shown === null || shown === undefined) ? this.entryWrapper.classList.contains('collapsed') : shown
    if (show) {
      if(disable_animation) {
        $(this.entryWrapper).show();
        this.expand(after_toggle)
      } else {
        $(this.entryWrapper).slideDown(() => {
          this.expand(after_toggle)
        })
      }
    }
    else {
      if(disable_animation) {
        $(this.entryWrapper).hide();
        this.collapse(after_toggle)
      }else{
        $(this.entryWrapper).slideUp(() => {
          this.collapse(after_toggle)
        })
      }
    }

  }

  expand = (after_toggle) => {
    this.entryWrapper.classList.remove('collapsed')
    this.entryWrapper.classList.add('expanded')
    this.toggleBtn.querySelector('i').classList.remove('fa-chevron-right')
    this.toggleBtn.querySelector('i').classList.add('fa-chevron-down')
    if (after_toggle) {
      after_toggle()
    }

    if(this.formStateManager) {
      this.formStateManager.dispatchEvent(new CustomEvent('multiStateChanged', {detail: {multiId: this.element.id, state: 'expanded'}}))
    }
  }

  collapse = (after_toggle) => {
    this.entryWrapper.classList.remove('expanded')
    this.entryWrapper.classList.add('collapsed')
    this.toggleBtn.querySelector('i').classList.remove('fa-chevron-down')
    this.toggleBtn.querySelector('i').classList.add('fa-chevron-right')
    if (after_toggle) {
      after_toggle()
    }

    if(this.formStateManager) {
      this.formStateManager.dispatchEvent(new CustomEvent('multiStateChanged', {detail: {multiId: this.element.id, state: 'collapsed'}}))
    }
  }


  getCount = (amount, update) => {
    let currentCount = parseInt(this.countTarget.innerText)
    const newCount = currentCount + amount
    return newCount
  }

  setCount = (amount) => {
    if (!this.hasCountTarget) { return }
    let currentCount = parseInt(this.countTarget.innerText)
    const newCount = currentCount + amount
    this.countTarget.innerText = newCount
    return newCount
  }

  getUniqueId = () => {
    return new Date().getTime()
  }

  setMultiStateEventHandler(event) {
    if(event.detail.multiId === this.element.id) {
      if(event.detail.state === 'collapsed') {
        this._toggle({shown: false, disable_animation: true})
      }
      else if(event.detail.state === 'expanded') {
        this._toggle({shown: true, disable_animation: true})
      }
      //do nothing when the state is unknown
    }
  }

}

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import clipboard from '../src/clipboard'
import dependentDropdown from "../src/dependentDropdown";

export default class extends Controller {

  // When we load page for the first time we want it load quickly
  // to aid with load timing we would not load content for tabs
  // until the user clicks on the tab

  //TODO: this tab controller should load in several situations
  // 1. when user clicks on the tab and nothing was loaded yet into a tab
  // 2. when action cable message comes, need to refresh if this tab is active
  //    or we need to cleanup a tab and make it not loaded so when user clicks it loads again
  // 3. when user clicks on the tab and it is subscribed to action cable, we do NOT need reload unless nothing was loaded yet
  // 4. when user clicks on the tab and it is not subscribed to action cable, we DO need reload every time

  //TODO: for right sidebar we need to create a separate controller
  // that listens to events that happen on the page and shows or hides right sidebar on the needed basis

  connect() {
    const currentTabContent = this.element.querySelector('.tab-pane.active');
    if (currentTabContent) {
      this.showElementsForTabContent(currentTabContent)
    }
    this.spinner = this.element.querySelector("#tab-spinner")

    this.tabContentLoadedEventHandler = this.tabContentLoadedEventHandler.bind(this);
    this.element.addEventListener('tabContentLoadedEvent', this.tabContentLoadedEventHandler);

    this.tabContentLoadingEventHandler = this.tabContentLoadingEventHandler.bind(this);
    this.element.addEventListener('tabContentLoadingEvent', this.tabContentLoadingEventHandler);

    this.tabActivateEventHandler = this.tabActivateEventHandler.bind(this);
    this.element.addEventListener('tabActivateEvent', this.tabActivateEventHandler);

    this.tabsDataChanged = this.tabsDataChanged.bind(this);
    document.addEventListener('tabsDataChanged', this.tabsDataChanged);

    this.tabsScrollPosition = {}

  }

  disconnect() {
    this.element.removeEventListener('tabContentLoadedEvent', this.tabContentLoadedEventHandler);
    this.element.removeEventListener('tabContentLoadingEvent', this.tabContentLoadingEventHandler);
    this.element.removeEventListener('tabActivateEvent', this.tabActivateEventHandler);
    document.removeEventListener('tabsDataChanged', this.tabsDataChanged);
  }

  tabsDataChanged(event) {
    const data = event.detail.data
    const tabsContainer = this.element.querySelector('.nav-tabs')

    if(data) {
      Object.entries(data).forEach(([key, value]) => {
        const tabLink = tabsContainer.querySelector(`[data-tab-name="${key}"]`);
        if (tabLink) {
          tabLink.innerHTML = value['name'];
        }
      });
    }

  }

  tabContentLoadedEventHandler(event) {
    // here we may need to pop tab that have already loaded from stack in case if we expect multiple things to load
    // so the stack manages who is loading and who is not so we can show hide the spinner
    // for now we will keep it simple
    if (this.spinner) {
      this.spinner.classList.add("d-none")
    }

    //restore scroll position if needed
    if(event.detail && event.detail.tab && event.detail.keep_scroll_position){
      const tabName = this.element.dataset.tabName

      if (this.tabsScrollPosition[tabName]){
        const scrollableParent = tabContent.closest('.overflow-auto')
        if(scrollableParent){
          scrollableParent.scrollTop = this.tabsScrollPosition[tabName]
        }
      }
    }

  }

  tabContentLoadingEventHandler(event) {
    //TODO: here we may need to push tab that is loading into some kind of stack in case if we expect multiple things to load
    // so the stack manages who is loading and who is not so we can show hide the spinner
    // for now we will keep it simple
    if (this.spinner) {
      this.spinner.classList.remove("d-none")
    }
  }

  tabActivateEventHandler(event) {
    const currentNavLink = this.element.querySelector('.nav-tabs .nav-item .nav-link.active')
    if (currentNavLink.dataset.tabName !== event.detail.tabName) {
      this.deactivateAndUnloadTab(currentNavLink)
      //do not load here since we might have already loaded it
      const targetNavLink = this.element.querySelector('#' + event.detail.tabName + '-tab a')
      this.activateTab(targetNavLink)
    }
  }

  load(event) {
    //here we want to prevent default events
    event.preventDefault()
    const currentNavLink = this.element.querySelector('.nav-tabs .nav-item .nav-link.active')

    if (currentNavLink.dataset.tabName !== event.target.dataset.tabName) {
      this.deactivateAndUnloadTab(currentNavLink)
    }

    //always reactivate to perhaps refresh components
    this.activateAndLoadTab(event.target)

  }

  deactivateAndUnloadTab(tabNavLink) {
    const tabName = tabNavLink.dataset.tabName
    const tabContentId = `#${tabName}-tab-content`
    const tabNavItemId = `#${tabName}-tab`
    const tabNavItem = this.element.querySelector(tabNavItemId)
    const tabContent = this.element.querySelector(tabContentId)

    //here we should make them invisible and or inactive
    if(tabNavItem) {
      tabNavItem.classList.remove('active')
      tabNavLink.classList.remove('active')
    }

    // this may need to be refactored if we do not have scrollable element like this
    let scrollTop = 0;
    const scrollableParent = tabContent.closest('.overflow-auto')
    if(scrollableParent){
      scrollTop = scrollableParent.scrollTop
    }
    this.tabsScrollPosition[tabName] = scrollTop;

    tabContent.classList.remove('active')
    tabContent.classList.remove(...['loading', 'loaded']);
    tabContent.classList.add('d-none')

    // now we need to decide if we cleanup content now for lazy loading later
    // or raise an event to have specific tab controller handle it

    if (tabContent.dataset.controller) {
      this.element.dispatchEvent(new CustomEvent(tabName + 'TabDeactivatedEvent', {detail: {scrollTop: scrollTop}}))
    }else{
      tabContent.innerHTML = ""
    }
  }

  activateAndLoadTab(tabNavLink) {
    const tabName = tabNavLink.dataset.tabName
    const tabContentId = `#${tabName}-tab-content`
    const tabContent = this.element.querySelector(tabContentId)

    this.activateTab(tabNavLink)

    //TODO: here we may need to push tab that is loading into some kind of stack in case if we expect multiple things to load
    // so the stack manages who is loading and who is not so we can show hide the spinner
    // for now we will keep it simple
    if (this.spinner) {
      this.spinner.classList.remove("d-none")
    }

    const scrollTop = this.tabsScrollPosition[tabName];

    if (tabContent.dataset.controller) {
      const newEvent = new CustomEvent(tabName + 'TabActivatedEvent', {detail: {scrollTop: scrollTop}})
      this.element.dispatchEvent(newEvent)
    }else {
      let url = tabNavLink.dataset.tabUrl || tabContent.dataset.tabUrl;

      tabContent.classList.add('loading')
      Rails.ajax({
        type: "get",
        url: url,
        success: (data) => {
          if (this.spinner) {
            this.spinner.classList.add("d-none")
          }
          tabContent.innerHTML = data.tab_html
          if(data.name){
            tabNavLink.innerHTML = data.name;
          }

          let url = new URL(location)

          //TODO: here we need to have an element for tab link
          // check that we have data.name
          // and then replance the inner html for that link

          url.searchParams.set('tab', tabName)
          window.history.pushState('data', tabNavLink, url)

          tabContent.classList.remove('loading')
          tabContent.classList.add('loaded')
          document.body.dispatchEvent(new CustomEvent('tooltips:refresh'))

          tabContent.scrollTop = scrollTop;

          //TODO: these methods may overinitialize elements that have already been initialized
          // we need to refactor those into stimulus and let the stimulus deal with initialization
          // until we refactor to stimulus we need to pass only the element where content just loaded

          new clipboard().init()
          new dependentDropdown().init()

          $('.selectpicker').selectpicker();
        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }

  activateTab(tabNavLink) {
    const tabName = tabNavLink.dataset.tabName
    const tabContentId = `#${tabName}-tab-content`
    const tabNavItemId = `#${tabName}-tab`
    const tabNavItem = this.element.querySelector(tabNavItemId)
    const tabContent = this.element.querySelector(tabContentId)

    tabContent.classList.remove(...['loading', 'loaded']);
    tabContent.classList.add('active')
    tabContent.classList.remove('d-none')

    // put the scroller back where it was but after we make it visible
    if (this.tabsScrollPosition[tabName]){
      const scrollableParent = tabContent.closest('.overflow-auto')
      if(scrollableParent){
        scrollableParent.scrollTop = this.tabsScrollPosition[tabName]
      }
    }

    if(tabNavItem){
      tabNavItem.classList.add('active')
      tabNavLink.classList.add('active')
    }

    this.showElementsForTabContent(tabContent)
  }

  // Displays or hide all tab related elements
  // by default it will hide all tab related elements
  // and only will show the ones that are explicitly defined as to show
  // it will iterate through all related elements and hide or show them as requested
  showElementsForTabContent(tabContent) {
    // we want to get an array of ids of elements that we want to show, we want to trim any whitesace
    const elementsToShow = tabContent.dataset.elementsToShow?.split(',').map(item => item.trim()).filter(item => item) ?? [];
    const elements = document.querySelectorAll(`[data-for-tabs-container="${this.element.id}"]`)
    //now we want to iterate through found element and hide or show them based on the list of elements to show
    elements.forEach(element => {
      if(elementsToShow.includes(element.id)) {
       if(element.classList.contains("d-none")){
         element.classList.remove('d-none')
       }
      }else if (!element.classList.contains("d-none")){
        element.classList.add('d-none')
      }
    })
  }

  //not sure it it belongs here or in the split controller
  applySplitSizes(event) {
    const sizes = event.currentTarget.dataset.sizes.split(',').map(size => parseInt(size))
    const tabContent = this.element.querySelector('.tab-content')
    const columns = tabContent.querySelectorAll('[data-split-view-target="column"]')

    //this will trigger split view to refresh
    columns.forEach((column, index) => {
      column.dataset.size = sizes[index] || ""
    })

  }
}

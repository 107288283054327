
import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect () {
    this.setupForm()
  }

  setupForm = function() {
    const controller = this
    const publicKeyPath = this.data.get('stripePublicKeyPath')
    Rails.ajax({
      type: "get",
      url: publicKeyPath,
      success: (response) => {
        controller.getSetupIntent(response.publicKey)
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  getSetupIntent = (publicKey) => {
    const controller = this
    const setupPath = controller.data.get('setupPaymentMethodIntentPath')
    Rails.ajax({
      type: "get",
      url: setupPath,
      success: (response) => {
        controller.stripeElements(publicKey, response.setupIntent)
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  changeLoadingState = (isLoading) => {
    if (isLoading) {
      document.querySelector("button").disabled = true
      document.querySelector("#btn-icon").classList.remove("fa-link")
      document.querySelector("#btn-icon").classList.add("fa-circle-notch", "fa-spin")
    } else {
      document.querySelector("button").disabled = false
      document.querySelector("#btn-icon").classList.remove("fa-circle-notch", "fa-spin")
      document.querySelector("#btn-icon").classList.add("fa-link")
    }
  }

  orderComplete = (stripe, clientSecret) => {
    const controller = this
    stripe.retrieveSetupIntent(clientSecret).then(function(result) {
      controller.setPaymentAsDefault(result.setupIntent.payment_method)
    })
  }

  /* Shows a success / error message when the payment is complete */
  setPaymentAsDefault = (payment_method) => {
    const controller = this
    const setDefaultPath = controller.data.get('setDefaultPaymentMethodPath')
    Rails.ajax({
      type: "post",
      url: setDefaultPath,
      data: `payment_method=${payment_method}`,
      success: (response) => {
        controller.complete()
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  complete = () => {
    document.querySelector(".sr-payment-form").classList.add("hidden")
    document.querySelector(".sr-result").classList.remove("hidden")
    setTimeout(function() {
      document.querySelector(".sr-result").classList.add("expand")
    }, 200)
    this.changeLoadingState(false)
  }

  clearInvalid = () => {
    const invalids = document.querySelectorAll('.is-invalid')
    invalids.forEach((invalid)=>{
      invalid.classList.remove('is-invalid')
    })
  }

  showError = (message, field) => {
    this.changeLoadingState(false)
    if (field) {
      field.classList.add('is-invalid')
    }
    const displayError = document.getElementById("card-errors")
    displayError.textContent = message
  }

  stripeElements = (publicKey, setupIntent) => {
    const controller = this
    var stripe = Stripe(publicKey)
    var elements = stripe.elements()
  
    // Element styles
    var options = {
      iconStyle: 'solid',
      style: {
        base: {
          fontSize: "16px",
          color: "#32325d",
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "rgba(0,0,0,0.4)"
          }
        }
      }
    }
  
    var card = elements.create("card", options)
  
    card.mount("#card-element")
  
    // Element focus ring
    card.on("focus", () => {
      var el = document.getElementById("card-element")
      el.classList.add("focused")
    })
  
    card.on("blur", () => {
      var el = document.getElementById("card-element")
      el.classList.remove("focused")
    })
  
    // Handle payment submission when user clicks the pay button.
    var button = document.getElementById("submit")
    button.addEventListener("click", (event) => {
      event.preventDefault()
      controller.clearInvalid()
      controller.changeLoadingState(true)
      const name_field = document.getElementById("name")
      const name = name_field.value.trim()
      if (!name) {
        controller.showError("Please provide a value for Name on Card.", name_field)
        return
      }
      stripe
        .confirmCardSetup(setupIntent.client_secret, {
          payment_method: {
            card: card,
            billing_details: { name: name }
          }
        })
        .then( (result) => {
          if (result.error) {
            controller.showError(result.error.message)
          } else {
            controller.orderComplete(stripe, setupIntent.client_secret)
          }
        })
    })
    var form = document.getElementById("payment-form")
    form.classList.add("show")
  }

  submit = () => {
    this.element.submit()
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "note" ]
  export = () => {
    var u = this.exportUrl()
    var href = new URL(window.location.origin + u)
    href.searchParams.append('note', this.noteTarget.value)
    window.location.href = href
  }
  exportUrl = () => {
    var u = this.data.get('exportUrl')
    return u
  }
}
import emitFormChangedEvent from './emitFormChangedEvent'	
export default class multiFields {	
  constructor(selector = '.add_fields', idFunction = this.getUniqueId) {	
    this.selector = selector	
    this.idFunction = idFunction	
  }	
  init = () => { 	
    const links = document.querySelectorAll(this.selector)	
    this.addLinkHandlers(links)	
  }	

  addLinkHandlers = (links) => {	
    // If there are no links on the page, stop the function from executing.	
    if (links.length === 0) return	
    // Loop over each link on the page. A page could have multiple nested forms.	
    links.forEach((link)=>{	
      link.addEventListener('click', (e) => {	
        this.handleClick(link, e)	
      })	
    })	
  }	

  getUniqueId() {	
    return new Date().getTime()	
  }	

  handleClick = (link, e) => {	
    // Stop the function from executing if a link or event were not passed into the function. 	
    if (!link || !e) return	
    // Prevent the browser from following the URL.	
    e.preventDefault()	
    // Save a unique timestamp to ensure the key of the associated array is unique.	
    let newId = this.idFunction()	
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.	
    let linkId = link.dataset.id	
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.	
    let regexp = linkId ? new RegExp(linkId, 'g') : null	
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.	
    const existingCount = parseInt(link.dataset.existingCount)	
    const container = document.getElementById(link.dataset.container)
    const newCount = existingCount + 1	
    link.dataset.existingCount = newCount	
    let newFields = regexp ? link.dataset.fields.replace(regexp, newId) : null	
    let entryRegexp = new RegExp(':entry_number', 'g')	
    newFields = newFields.replace(entryRegexp, newCount)	
    container.insertAdjacentHTML('beforeend', newFields)
    const form = link.closest('form')	

    emitFormChangedEvent(form, e)	
  } 	
}